import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useCustomerFieldsForm } from "./useCustomerFieldsForm";
import { useCustomerFieldsCreate } from "@/api/useCustomerFieldsCreate";
import { PulsoFormProvider } from "@/components/specific/Form";

export function CustomerFieldCreateDialog({ facilityId }: { facilityId: string }) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const form = useCustomerFieldsForm();
  const create = useCustomerFieldsCreate(facilityId);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">{t("customerFields_create_openButton", "Add customer field")}</Button>
      </DialogTrigger>
      <DialogContent hideFooter>
        <DialogHeader>
          <DialogTitle>{t("customerFields_create_title", "Add a new customer field")}</DialogTitle>
          <DialogDescription>
            {t("customerFields_create_subtitle", "Define a field which describes a customer")}
          </DialogDescription>
        </DialogHeader>
        <div className="overflow-y-auto">
          <PulsoFormProvider
            {...form}
            onSubmit={(values) => create.mutate(values, { onSuccess: () => setOpen(false) })}
            isLoading={create.isPending}
            initialValues={{
              name: "",
              type: "TEXT",
              options: [],
              mandatory: false,
              showInFastTrack: true,
              showInStore: true,
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

/* eslint-disable @typescript-eslint/no-explicit-any */
import { Logtail } from "@logtail/browser";
type Message = Error | string;
type Context = Record<string, any>;

let logger: {
  debug<TContext extends Context>(message: Message, context?: TContext): any;
  info<TContext extends Context>(message: Message, context?: TContext): any;
  warn<TContext extends Context>(message: Message, context?: TContext): any;
  error<TContext extends Context>(message: Message, context?: TContext): any;
};

if (process.env.REACT_APP_LOGTAIL_TOKEN) {
  logger = new Logtail(process.env.REACT_APP_LOGTAIL_TOKEN);
} else {
  logger = console;
}

export { logger };

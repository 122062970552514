import { useBookingCancel } from "@/api/useBookingCancel";
import { useBookingDelete } from "@/api/useBookingDelete";
import { ApiObjects } from "@pulso/api-client";
import {
  AlertDialogHeader,
  AlertDialogFooter,
  AlertDialog,
  AlertDialogContent,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogCancel,
  AlertDialogAction,
} from "@/components/ui/alert-dialog";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import { t } from "i18next";
import { EditIcon, EllipsisVertical, History, Trash2Icon } from "lucide-react";
import { useState } from "react";
import {
  DropdownMenuItem,
  DropdownMenuTrigger,
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuSeparator,
} from "@/components/ui/dropdown-menu";
import { useTranslation } from "react-i18next";
import { StatusChangeElement } from "@/components/specific/BookingStatusButton";
import { Link } from "react-router-dom";
import { useBookingStatusAllowedTransitions } from "@/lib/useBookingStatusAllowedTransitions";
import { BookingTraceLogSheet } from "../details/traceLog/BookingTraceLogSheet";
import { useHasRight } from "@/lib/useHasRight";

export function BookingContextMenu({ booking }: { booking: ApiObjects["BookingDto"] }) {
  const { t } = useTranslation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const allowedStatusTransitions = useBookingStatusAllowedTransitions(booking.facilityId, booking.status);
  const isAllowedToDeleteBooking = useHasRight("deleteBooking");

  return (
    <>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <div className="pr-3 lg:pr-6 -my-4 py-4">
            <EllipsisVertical strokeWidth={1.2} />
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem disabled>{t("bookings_contextMenu_changeStatus_label", "Set as:")}</DropdownMenuItem>
          {allowedStatusTransitions.map((toStatus) => (
            <DropdownMenuItem
              key={toStatus}
              className="hover:bg-transparent focus:bg-white"
              onClick={(e) => {
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <StatusChangeElement key={toStatus} booking={booking} toStatus={toStatus} />
            </DropdownMenuItem>
          ))}
          <DropdownMenuSeparator />
          <Link to={`/facility/${booking.facilityId}/bookings/${booking.id}`}>
            <DropdownMenuItem className="gap-1">
              <EditIcon strokeWidth={1.2} size={16} /> {t("bookings_contextMenu_edit_button", "Edit booking")}
            </DropdownMenuItem>
          </Link>
          <DropdownMenuItem className="gap-1">
            <BookingTraceLogSheet booking={booking}>
              <div onClick={(e) => e.stopPropagation()} className="flex gap-1 items-center">
                <History strokeWidth={1.2} size={16} />
                {t("booking_traceLog_title", "Booking history")}
              </div>
            </BookingTraceLogSheet>
          </DropdownMenuItem>
          {isAllowedToDeleteBooking && (
            <DropdownMenuItem onClick={() => setIsDialogOpen(true)} className="gap-1">
              <Trash2Icon strokeWidth={1.2} size={16} /> {t("bookings_contextMenu_delete_button", "Delete booking")}
            </DropdownMenuItem>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
      <DeleteBookingModal booking={booking} open={isDialogOpen} onOpenChange={setIsDialogOpen} />
    </>
  );
}

type DeleteBookingModalProps = { booking: ApiObjects["BookingDto"]; open: boolean; onOpenChange: (o: boolean) => void };

export function DeleteBookingModal({ booking, open, onOpenChange }: DeleteBookingModalProps) {
  const deleteMutation = useBookingDelete();
  const cancelMutation = useBookingCancel(booking.id);
  const [confirmed, setConfirmed] = useState(false);

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>
            {t("bookings_delete_title", "Delete booking for {{customer}}", {
              customer: booking.customer.name,
            })}
          </AlertDialogTitle>
          <AlertDialogDescription>
            {t("bookings_delete_subtitle", "Are you sure you want to delete this booking?")}
          </AlertDialogDescription>
        </AlertDialogHeader>
        <div className="text-sm flex flex-col gap-6 py-3">
          <div className="text-destructive">
            {t(
              "bookings_delete_warning",
              "Deleting this booking will permanently remove it from the system and it won't be recoverable anymore. Would you like to cancel this booking instead?"
            )}
          </div>
          <div className="flex items-center gap-2">
            <Checkbox
              checked={confirmed}
              onCheckedChange={(v) => setConfirmed(v === true)}
              id="delete-booking-checkbox"
            />
            <Label htmlFor="delete-booking-checkbox" className="cursor-pointer">
              {t("bookings_delete_confirmation", "I want to permanently delete this booking")}
            </Label>
          </div>
        </div>
        <AlertDialogFooter>
          <AlertDialogCancel>{t("common_button_close", "Close")}</AlertDialogCancel>
          <AlertDialogAction
            onClick={() =>
              cancelMutation.mutate({
                notifyCustomer: false,
                reason: "",
              })
            }
            className="bg-warn hover:bg-orange-300"
          >
            {t("bookings_delete_button_cancel", "Cancel booking")}
          </AlertDialogAction>
          <AlertDialogAction
            onClick={() => deleteMutation.mutate(booking.id)}
            className="bg-destructive hover:bg-red-300"
            disabled={!confirmed}
          >
            {t("bookings_delete_button_delete", "Delete forever")}
          </AlertDialogAction>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
}

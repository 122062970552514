import { formatDate } from "@pulso/utils";
import { DatePicker } from "./date-picker";
import { DatePreset } from "./date-presets";
import { DateRange, DayPickerRangeProps } from "react-day-picker";
import { useEffect, useState } from "react";

export function DateRangePickerWithPresets(
  props: Omit<DayPickerRangeProps, "mode"> & {
    timezone: string;
    onChange: (range: { from: Date; to: Date }) => void;
  }
) {
  const [rangeInternal, setRangeInternal] = useState<DateRange | undefined>(props.selected);

  useEffect(() => {
    setRangeInternal(props.selected);
  }, [props.selected]);

  return (
    <DatePicker
      mode="range"
      selected={rangeInternal}
      onSelect={(range) => {
        setRangeInternal(range);

        const from = range?.from;
        const to = range?.to;

        if (from && to) {
          props.onChange({ from, to });
        }
      }}
      label={(range) =>
        `${range.from ? formatDate(range.from, null, undefined, "date") : "Select"} - ${range.to ? formatDate(range.to, null, undefined, "date") : "Select"}`
      }
      hideClear
    >
      <div className="p-3">
        <DatePreset timezone={props.timezone} onSelect={(range) => props.onChange(range)} />
      </div>
    </DatePicker>
  );
}

import { ApiObjects } from "@pulso/api-client";
import { NewInventoryFormModal } from "./NewInventoryFormModal";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { StringList } from "@pulso/components/lib/StringList";
import InfiniteScroll from "react-infinite-scroll-component";
import { CentralSpinner } from "@/components/ui/central-spinner";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { useStockItems } from "@/api/useStockItems";
import { useStockItemDelete } from "@/api/useStockItemDelete";
import { cn } from "@/lib/utils";
import { StockItemStatus } from "./StockItemStatus";
import { Link } from "react-router-dom";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { Warning } from "@/components/specific/Warning";
import { Notes } from "@/components/specific/Notes";
import { EditIcon, Trash2Icon } from "lucide-react";
import { ConfirmDialog } from "@/components/ui/confirm-dialog";
import { formatDate } from "@/components/specific/DateFormat";
import { ImportInventoryFormModal } from "./ImportInventoryFormModal";
import { useLocations } from "@/api/useLocations";

export type StockItemsListProps = {
  product: ApiObjects["ProductWithExtraDto"];
  onSelect: (data: ApiObjects["StockItemWithBookingDto"]) => void;
};

export function StockItemsList({ product, onSelect }: StockItemsListProps) {
  const stockItems = useStockItems(product.id);
  const { t } = useTranslation();
  const deleteMutation = useStockItemDelete();
  const { isLocationsEnabled, locationsMap } = useLocations(product.facilityId);

  return (
    <>
      <div>
        <div className={cn("my-3 flex gap-3")}>
          <NewInventoryFormModal product={product}>
            <Button>+ {t("stockItems_add_button", "Add items")}</Button>
          </NewInventoryFormModal>
          <ImportInventoryFormModal product={product}>
            <Button variant="outline">+ {t("stockItems_import_button", "Import items")}</Button>
          </ImportInventoryFormModal>
        </div>
        {stockItems.items.length ? (
          <Card>
            <CardContent className="overflow-clip p-0">
              <InfiniteScroll
                style={{ overflow: "hidden" }}
                loader={<CentralSpinner />}
                scrollableTarget="main"
                {...stockItems}
              >
                <Table>
                  <TableHeader>
                    <TableRow>
                      <TableHead>{t("stockItems_table_header_identifier", "Identifier")}</TableHead>
                      <TableHead>{t("stockItems_table_header_status", "Status")}</TableHead>
                      {isLocationsEnabled && (
                        <>
                          <TableHead>{t("stockItems_table_header_linkedLocations", "Available in")}</TableHead>
                          <TableHead>{t("stockItems_table_header_location", "Location")}</TableHead>
                        </>
                      )}
                      {product.fields.map((field) => (
                        <TableHead key={field.id} className={cn(field.priceable && "font-bold")}>
                          {field.name}
                        </TableHead>
                      ))}
                      <TableHead>{t("stockItems_table_header_notes", "Notes")}</TableHead>
                      <TableHead></TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody id="scrollableDiv">
                    {stockItems.items.map((item) => (
                      <TableRow key={item.id} className="cursor-pointer" onClick={() => onSelect(item)}>
                        <TableCell className="w-32">{item.identifier}</TableCell>
                        <TableCell>
                          <div className="flex">
                            <StockItemStatus
                              status={item.status}
                              from={item.from}
                              isChangeable={true}
                              stockItemId={item.id}
                            />
                            {item.booking && (
                              <div className="text-sm ml-3" onClick={(e) => e.stopPropagation()}>
                                <Link to={`../bookings/${item.booking.id}`}>
                                  {t("stockItems_info_rented", "with {{customer}} until {{endDate}}", {
                                    customer: item.booking.customer.name,
                                    endDate: formatDate(item.booking.endAt),
                                  })}
                                </Link>
                              </div>
                            )}
                          </div>
                        </TableCell>
                        {isLocationsEnabled && (
                          <>
                            <TableCell>
                              <StringList
                                list={item.locations.map((locationId) => locationsMap[locationId].name)}
                                max={5}
                              />
                            </TableCell>
                            <TableCell>
                              {item.currentLocationId ? locationsMap[item.currentLocationId].name : "??"}
                            </TableCell>
                          </>
                        )}
                        {product.fields.map((productField) => (
                          <TableCell key={productField.id}>
                            {item.fields.find((f) => f.fieldId === productField.id)?.value ??
                              (productField.priceable ? (
                                <Tooltip>
                                  <TooltipTrigger>
                                    <Warning />
                                  </TooltipTrigger>
                                  <TooltipContent>
                                    {t(
                                      "stockItems_warning_missingPrice",
                                      "{{field}} can influence the price of a product. Therefore, a value has to be defined",
                                      { field: productField.name }
                                    )}
                                  </TooltipContent>
                                </Tooltip>
                              ) : (
                                ""
                              ))}
                          </TableCell>
                        ))}
                        <TableCell>
                          <Notes notes={item.notes} id={item.id} />
                        </TableCell>
                        <TableCell align="right" onClick={(e) => e.stopPropagation()}>
                          <div className="flex items-center justify-end">
                            <Button variant="ghost" onClick={() => onSelect(item)}>
                              <EditIcon strokeWidth={1.2} />
                            </Button>
                            <ConfirmDialog
                              title={t("stockItems_delete_title", "Delete {{identifier}}", {
                                identifier: item.identifier,
                              })}
                              description={t(
                                "stockItems_delete_subtitle",
                                "Are you sure you want to delete this field?"
                              )}
                              onContinue={() => deleteMutation.mutate(item.id)}
                              okButtonText={t("common_button_delete", "Delete")}
                            >
                              <Trash2Icon strokeWidth={1.2} />
                            </ConfirmDialog>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </InfiniteScroll>
            </CardContent>
          </Card>
        ) : (
          <div className="text-sm text-muted-foreground">
            {t("stockItems_empty_label", "No stock items created yet")}
          </div>
        )}
      </div>
    </>
  );
}

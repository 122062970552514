import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { api } from "@/lib/api-client";
import { PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import { CopyToClipboard } from "react-copy-to-clipboard";

export function SendFastTrackLinkModal(props: PropsWithChildren<{ facilityId: string }>) {
  const { t } = useTranslation();
  const [link, setLink] = useState("");
  const [isCopied, setIsCopied] = useState(false);

  return (
    <Dialog onOpenChange={() => setLink("")}>
      <DialogTrigger asChild>{props.children}</DialogTrigger>
      <DialogContent noFullScreen hideFooter>
        <DialogHeader>
          <DialogTitle>{t("settings_fastTrack_sendLinkModal_title", "Share Fast Track link")}</DialogTitle>
          <DialogDescription>
            {t(
              "settings_fastTrack_sendLinkModal_description",
              "Share a Fast Track link with a customer, so that they can create their booking"
            )}
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-6">
          <div className="text-sm">
            {t(
              "settings_fastTrack_sendLinkModal_createLink_info",
              "Click on the button below to generate  a shareable link. Remember that this link will only be valid for one customer!"
            )}
          </div>
          <Button onClick={() => createLink()}>
            {link
              ? t("settings_fastTrack_sendLinkModal_createNewLink_button", "Create a new link")
              : t("settings_fastTrack_sendLinkModal_createLink_button", "Create link")}
          </Button>
          {link && (
            <div className="space-y-3">
              <div className="p-3 border border-dashed rounded-md bg-secondary/60 break-all text-xs">{link}</div>
              <div className="flex items-center space-x-2">
                <div>
                  <CopyToClipboard
                    text={link}
                    onCopy={() => {
                      setIsCopied(true);
                      setTimeout(() => setIsCopied(false), 2000);
                    }}
                  >
                    <Button variant="outline">{t("common_button_copy", "Copy")}</Button>
                  </CopyToClipboard>
                </div>
                {isCopied && (
                  <div className="text-green-700">
                    {t("settings_fastTrack_sendLinkModal_createLink_linkCopied", "Link copied!")}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );

  async function createLink() {
    const { link: newLink } = await api.getFastTrackLink({ facilityId: props.facilityId });
    setLink(newLink);
  }
}

import { useRentalPeriods } from "@/api/useRentalPeriods";
import { useStoreSettings } from "@/api/useStoreSettings";
import { useStoreSettingsPatch } from "@/api/useStoreSettingsPatch";
import { PulsoFormProvider, createForm } from "@/components/specific/Form";
import { CentralSpinner } from "@/components/ui/central-spinner";
import { useTranslation } from "react-i18next";
import { z } from "zod";

export function StoreSettingsForm(props: { facilityId: string }) {
  const { t } = useTranslation();

  const { settings } = useStoreSettings(props.facilityId);
  const { periods } = useRentalPeriods();
  const patch = useStoreSettingsPatch(props.facilityId);

  if (!settings) {
    return <CentralSpinner />;
  }

  const formSchema = z.object({
    noticeTime: z.number().min(0),
    autoConfirm: z.boolean(),
    downPayment: z.number().min(0).max(100),
    enabledPeriods: z.array(z.string()),
  });

  const form = createForm(
    {
      noticeTime: {
        label: t("settings_store_form_noticeTime_label", "Allow bookings from"),
        type: "period",
        className: "max-w-96",
      },
      autoConfirm: {
        type: "radio",
        label: t("settings_store_form_autoConfirm_label", "Auto confirm"),
        hideIf: () => settings.paymentsEnabled,
        options: [
          {
            value: "true",
            typedValue: true,
            label: t(
              "settings_store_form_autoConfirm_option_enabled",
              "All bookings are confirmed immediately after creation"
            ),
          },
          {
            value: "false",
            typedValue: false,
            label: t(
              "settings_store_form_autoConfirm_option_disabled",
              "Every bookings is reviewed and confirmed by a member of the staff"
            ),
          },
        ],
      },
      downPayment: {
        type: "number",
        prefix: "%",
        hideIf: () => !settings.paymentsEnabled,
        label: t("settings_store_form_downPayment_label", "Down payment"),
        className: "max-w-64",
        info: t(
          "settings_store_form_downPayment_info",
          "Set the down payment that needs to be paid in order to confirm the reservation."
        ),
      },
      enabledPeriods: {
        label: t("settings_store_form_enabledPeriods_label", "Enabled periods"),
        type: "dropdown-multi",
        options: periods.map((p) => ({ label: p.name, value: p.id })),
        className: "w-64",
      },
    },
    formSchema
  );

  return (
    <div>
      <PulsoFormProvider
        {...form}
        isLoading={patch.isPending}
        onSubmit={(values) => patch.mutate(values)}
        initialValues={{
          noticeTime: settings.noticeTime,
          autoConfirm: settings.autoConfirm,
          downPayment: settings.downPayment,
          enabledPeriods: settings.periods,
        }}
      />
    </div>
  );
}

import { PropsWithChildren, useState } from "react";
import {
  Dialog,
  DialogHeader,
  DialogTrigger,
  DialogContent,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "@/components/ui/dialog";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Label } from "@/components/ui/label";
import { Form, useForm } from "react-hook-form";

type ConfirmDialogProps = PropsWithChildren<{
  onContinue: () => void;
  hasDeposit: boolean;
}>;
export function ReturnBookingConfirmationModal(props: ConfirmDialogProps) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [hasAllItems, setHasAllItems] = useState(false);
  const [hasReturnedDeposit, setHasReturnedDeposit] = useState(false);

  const isReadyToReturn = hasAllItems && (!props.hasDeposit || hasReturnedDeposit);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{props.children}</DialogTrigger>
      <DialogContent noFullScreen>
        <DialogHeader>
          <DialogTitle>{t("bookings_details_return_confirmation_title", "Return booking")}</DialogTitle>
          <DialogDescription>
            {t("bookings_details_return_confirmation_description", "Do you have all items of the booking returned?")}
          </DialogDescription>
        </DialogHeader>

        <form className="py-3">
          <div className="flex items-center pt-3 cursor-pointer">
            <Checkbox
              id="allItemsReturnedCheckbox"
              checked={hasAllItems}
              onCheckedChange={(checked) => setHasAllItems(checked === true)}
            />
            <label htmlFor="allItemsReturnedCheckbox" className="ml-2">
              {t("bookings_details_return_confirmation_field_hasAllItems", "Do you have all items returned?")}
            </label>
          </div>

          {props.hasDeposit && (
            <div className="flex items-center pt-3 cursor-pointer">
              <Checkbox
                id="depositReturnedCheckbox"
                checked={hasReturnedDeposit}
                onCheckedChange={(checked) => setHasReturnedDeposit(checked === true)}
              />
              <label htmlFor="depositReturnedCheckbox" className="ml-2">
                {t(
                  "bookings_details_return_confirmation_field_hasReturnedDeposit",
                  "Have you returned the deposit to the customer?"
                )}
              </label>
            </div>
          )}
        </form>
        <DialogFooter>
          <Button variant="secondary" onClick={() => setOpen(false)}>
            {t("common_button_cancel")}
          </Button>
          <Button
            className="mb-3 sm:mb-0"
            disabled={!isReadyToReturn}
            onClick={() => {
              props.onContinue();
              setOpen(false);
            }}
          >
            {t("bookings_details_return_button", "Return")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

import { useState } from "react";

export function usePagination(pageSize = 25) {
  const [page, onChange] = useState(1);

  return {
    page,
    pageSize,
    onChange,
  };
}

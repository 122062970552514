import { useFacilitySettings } from "@/api/useFacilitySettings";
import { useFacilitySettingsPatch } from "@/api/useFacilitySettingsPatch";
import { PulsoFormProvider, createForm } from "@/components/specific/Form";
import { minutesToPeriod } from "@/components/specific/Period";
import { Spinner } from "@/components/ui/spinner";
import { useTranslation } from "react-i18next";
import { z } from "zod";

export function TimeGranularityForm({ facilityId }: { facilityId: string }) {
  const { settings } = useFacilitySettings(facilityId);
  const saveSettings = useFacilitySettingsPatch(facilityId);
  const { t } = useTranslation();

  const schema = z.object({ timeGranularity: z.string() });
  const form = createForm(
    {
      timeGranularity: {
        label: t("settings_timeGranularity_form_granularity", "Granularity"),
        type: "select",
        className: "w-60",
        options: [5, 10, 15, 20, 30, 60].map((mins) => ({ label: minutesToPeriod(mins), value: mins + "" })),
      },
    },
    schema
  );

  if (!settings) {
    return <Spinner />;
  }

  return (
    <PulsoFormProvider
      {...form}
      initialValues={{ timeGranularity: settings.timeGranularity + "" }}
      isLoading={saveSettings.isPending}
      onSubmit={(values) => saveSettings.mutate({ timeGranularity: parseInt(values.timeGranularity) })}
    />
  );
}

import { api } from "@/lib/api-client";
import { useQuery } from "@tanstack/react-query";
import keyBy from "lodash/keyBy";

export function useLocations(facilityId: string) {
  const query = useQuery({
    queryKey: ["locations", facilityId],
    queryFn: async () => await api.getLocations({ facilityId }),
  });

  const locations = query.data || [];
  const isLocationsEnabled = locations.length > 0;
  const locationsMap = keyBy(locations, (l) => l.id);

  return {
    locations,
    isLocationsEnabled,
    locationsMap,
    isLoading: query.isLoading,
  };
}

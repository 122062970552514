import { cn } from "@/lib/utils";
import { Button } from "./button";
import { Calendar } from "@pulso/components/lib/Calendar";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { CalendarIcon } from "lucide-react";
import { useEffect, useState } from "react";
import { SelectContent, SelectItem, SelectValue, Select, SelectTrigger } from "./select";
import { DayPickerSingleProps } from "react-day-picker";
import { t } from "i18next";
import { es } from "date-fns/locale";
import { useTimeSlots } from "@/pages/settings/rental-periods/useTimeSlots";
import { formatDateTnLocalTimezone, minutesToLabel } from "@pulso/utils";

export function DateTimePicker(
  props: Omit<DayPickerSingleProps, "selected" | "onSelect" | "mode" | "disabled"> & {
    selected: string;
    onSelect: (date: string) => void;
    disabled?: boolean;
    disableTime?: boolean;
  }
) {
  const { slots, timeGranularity } = useTimeSlots();
  const dateObj = new Date(props.selected);
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(dateObj);
  const [time, setTime] = useState(getTime(dateObj) + "");

  useEffect(() => {
    const dateObj = new Date(props.selected);
    setDate(dateObj);
    setTime(getTime(dateObj) + "");
  }, [props.selected]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild disabled={props.disabled}>
        <Button
          variant={"outline"}
          className={cn(
            "justify-start text-left font-normal",
            !props.selected && "text-muted-foreground",
            props.className
          )}
        >
          <span>
            <CalendarIcon className="mr-2" size={16} />
          </span>
          {props.selected ? (
            <span>{date.toLocaleDateString() + " " + minutesToLabel(time)}</span>
          ) : (
            <span>{t("common_datepicker_pickDate", "Pick a date")}</span>
          )}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          {...props}
          mode="single"
          selected={date}
          onSelect={(date) => {
            const newDate = date || new Date();
            setDate(newDate);
            updateParent(newDate, time);
          }}
          required={true}
          locale={es}
          {...es.options}
        />
        <div className="p-3">
          <Select
            value={time}
            onValueChange={(newTime) => {
              setTime(newTime);
              updateParent(date, newTime);
            }}
            disabled={props.disableTime}
          >
            <SelectTrigger>
              <SelectValue placeholder="Choose ..." />
            </SelectTrigger>
            <SelectContent viewportClassName={`grid gap-1 grid-template-columns-${Math.min(60 / timeGranularity, 6)}`}>
              {slots.map((time) => (
                <SelectItem
                  key={time.value}
                  value={time.value}
                  className="border rounded-md px-3 py-1 hover:bg-gray-200 hover:cursor-pointer"
                  hideCheckmark={true}
                >
                  {time.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      </PopoverContent>
    </Popover>
  );

  function updateParent(date: Date, time: string) {
    const hours = Math.floor(parseInt(time, 10) / 60);
    const minutes = parseInt(time, 10) % 60;
    const newDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours, minutes, 0);
    props.onSelect?.(formatDateTnLocalTimezone(newDate));
  }

  function getTime(date: Date) {
    return date.getHours() * 60 + date.getMinutes();
  }
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.documentCss = void 0;
exports.documentCss = `

.pdf-document {
  line-height: 1.25em;
}

.pdf-document h1 {
  font-size: 24pt;
  font-weight: bold;
  margin: 0.75rem 0 1.5rem;
  line-height: 1.25em;
}

.pdf-document h2 {
  font-size: 18pt;
  font-weight: bold;
  margin: 0.5rem 0 1rem;
  line-height: 1.25em;
}

.pdf-document h3 {
  font-size: 14pt;
  font-weight: bold;
  margin: 0.25rem 0 0.75rem;
  line-height: 1.25em;
}

.pdf-document p {
  font-size: 12pt;
  padding-bottom: 0.75rem;
  margin: 0;
  line-height: 1.33em;
}

.pdf-document ol,
.pdf-document ul,
.pdf-document pre,
.pdf-document blockquote {
  margin: 0;
  padding: 0;
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
}
.pdf-document ol,
.pdf-document ul {
  padding-left: 1.5em;
}
.pdf-document ol > li,
.pdf-document ul > li {
  list-style-type: none;
}
.pdf-document ul > li::before {
  content: '\\2022';
}
.pdf-document ul[data-checked=true],
.pdf-document ul[data-checked=false] {
  pointer-events: none;
}
.pdf-document ul[data-checked=true] > li *,
.pdf-document ul[data-checked=false] > li * {
  pointer-events: all;
}
.pdf-document ul[data-checked=true] > li::before,
.pdf-document ul[data-checked=false] > li::before {
  color: #777;
  cursor: pointer;
  pointer-events: all;
}
.pdf-document ul[data-checked=true] > li::before {
  content: '\\2611';
}
.pdf-document ul[data-checked=false] > li::before {
  content: '\\2610';
}
.pdf-document li::before {
  display: inline-block;
  white-space: nowrap;
  width: 1.2em;
}
.pdf-document ol li {
  counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
  counter-increment: list-0;
}
.pdf-document ol li:before {
  content: counter(list-0, decimal) '. ';
}
.pdf-document table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
  border-spacing: 0;
}
.pdf-document .products td {
  padding: 0.125em 0.50em;
}
.pdf-document .full-table {
  width: 100%;
  table-layout: fixed;
}
.pdf-document .full-table th,
.pdf-document .full-table td {
  padding: 0.5em 0.75em;
  border: 1px solid #eee;
  margin: 0;
}
.pdf-document .full-table th {
  text-align: left;
}

.table {
  display: table;
  width: 100%;
  border-collapse: collapse;
}
.thead {
  display: table-header-group;
  width: 100%;
}
.tbody {
  display: table-row-group;
  width: 100%;
}
.tr {
  display: table-row;
}
.th {
  font-weight: bold;
}
.tr {
  display: table-row;
}
.td,
.th {
  display: table-cell;
  padding: 0.5em 1em;
  border: 1px solid rgb(230, 230, 230);
  border-collapse: collapse;
}
`;

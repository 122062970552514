import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";

export function useProductDelete() {
  return useMutation({
    mutationFn: async (productId: string) => {
      await api.deleteProduct({ productId });
    },
    invalidateKeys: [["products"]],
    successMessage: "DELETE_SUCCESS",
  });
}

import { useBookingFastTrackLink } from "@/api/useBookingFastTrackLinkUrl";
import { useBookingSendFastTrackLink } from "@/api/useBookingSendFastTrackLink";
import { useFacilityFastTrackSettings } from "@/api/useFastTrackSettings";
import { WhatsAppLink } from "@/components/specific/WhatsAppLink";
import { ButtonLoadable } from "@/components/ui/button-loadable";
import { CentralSpinner } from "@/components/ui/central-spinner";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { useAuth } from "@/lib/useAuth";
import { ApiObjects } from "@pulso/api-client";
import { Button } from "@pulso/components/lib/Button";
import { Code } from "@pulso/components/lib/Code";
import { cn } from "@pulso/components/lib/utils";
import { WhatsAppIcon } from "@pulso/components/lib/WhatsAppIcon";
import { ClipboardCheck, ClipboardCopy, Mail } from "lucide-react";
import { PropsWithChildren, useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";

type BookingFastTrackLinkModal = PropsWithChildren<{ booking: ApiObjects["BookingDto"]; customerName: string }>;

export function BookingFastTrackLinkModal({ booking, customerName, children }: BookingFastTrackLinkModal) {
  const { t, i18n } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const linkQuery = useBookingFastTrackLink(booking.id, open);
  const sendLink = useBookingSendFastTrackLink();
  const { facility } = useAuth();
  const { settings } = useFacilityFastTrackSettings(booking.facilityId);
  const [whatsAppMessage, setWhatsAppMessage] = useState("");

  useEffect(() => {
    if (!open) {
      setIsCopied(false);
    }
  }, [open]);

  useEffect(() => {
    i18n.loadLanguages(booking.customer.language).then(() => {
      if (facility && linkQuery.url) {
        setWhatsAppMessage(
          t(
            "bookings_requestBooking_whatsApp_message",
            "Please, complete your booking at {{facility}} by clicking on the following link {{fastTrackLink}}.",
            {
              facility: facility.organization.name,
              fastTrackLink: linkQuery.url,
              lng: booking.customer.language ?? "en",
            }
          )
        );
      }
    });
  }, [linkQuery.url, facility, booking.customer.language, i18n, t]);

  if (!settings?.enabled) {
    return null;
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent hideFooter>
        <DialogHeader>
          <DialogTitle>{t("bookings_bookingFastTrackLink_title", "Booking Fast Track")}</DialogTitle>
          <DialogDescription>
            {t(
              "bookings_bookingFastTrackLink_subtitle",
              "Share a Fast Track link with {{customerName}} to collect all the relevant data you need.",
              { customerName }
            )}
          </DialogDescription>
        </DialogHeader>
        <div>
          {linkQuery.isLoading ? (
            <CentralSpinner />
          ) : linkQuery.error ? (
            <div className="text-destructive text-sm">{linkQuery.error.message}</div>
          ) : (
            <div className="flex flex-col gap-3">
              {booking.customer.email && (
                <div>
                  <ButtonLoadable
                    variant="outline"
                    onClick={() => sendLink.mutate(booking.id, { onSuccess: () => setOpen(false) })}
                    isLoading={sendLink.isPending}
                    className="gap-2"
                  >
                    <Mail size={16} />
                    {t("bookings_requestBooking_sendFastTrackLinkViaEmail_button", "Share via e-mail")}
                  </ButtonLoadable>
                </div>
              )}
              {booking.customer.phone && whatsAppMessage && (
                <WhatsAppLink phone={booking.customer.phone} message={whatsAppMessage}>
                  <Button variant="outline" className="items-center gap-2" onClick={() => setOpen(false)}>
                    <WhatsAppIcon size={16} />
                    {t("bookings_requestBooking_sendFastTrackLinkViaWhatsApp_button", "Share via WhatsApp")}
                  </Button>
                </WhatsAppLink>
              )}
              <div>
                <CopyToClipboard text={linkQuery.url || ""} onCopy={() => setIsCopied(true)}>
                  <Button variant="outline" className="items-center gap-2">
                    {isCopied ? <ClipboardCheck size={16} /> : <ClipboardCopy size={16} />}
                    {t("bookings_requestBooking_copyLink_button", "Copy Fast Track link")}
                  </Button>
                </CopyToClipboard>

                <div
                  className={cn(
                    "mt-3 flex flex-col text-sm gap-3 h-0 overflow-hidden transition-all",
                    isCopied && "h-40"
                  )}
                >
                  <div className="text-green-700">{t("common_copy_success", "Copied to clipboard!")}</div>
                  <Code className="text-xs opacity-50" text={linkQuery.url || ""} hideButton />
                </div>
              </div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

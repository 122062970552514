import { useState, useEffect } from "react";
import { loadConnectAndInitialize, StripeConnectInstance } from "@stripe/connect-js";
import { api } from "@/lib/api-client";

export const useStripeConnect = (connectedAccountId: string | undefined | null, facilityId: string) => {
  const [stripeConnectInstance, setStripeConnectInstance] = useState<StripeConnectInstance>();

  useEffect(() => {
    if (connectedAccountId) {
      const fetchClientSecret = async () => {
        const response = await api.createStripeAccountSession({ facilityId }, { account: connectedAccountId });
        return response.clientSecret;
      };

      if (!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) {
        throw new Error("Stripe not set up");
      }

      setStripeConnectInstance(
        loadConnectAndInitialize({
          publishableKey: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
          fetchClientSecret,
          appearance: {
            overlays: "dialog",
            variables: {
              colorPrimary: "#635BFF",
            },
          },
        })
      );
    }
  }, [connectedAccountId]);

  return stripeConnectInstance;
};

export default useStripeConnect;

import { useProduct } from "@/api/useProduct";
import { useRentalPeriods } from "@/api/useRentalPeriods";
import { Form } from "@/components/ui/form";
import { Spinner } from "@/components/ui/spinner";
import { useParams } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import * as z from "zod";
import { Button } from "@/components/ui/button";
import { useEffect } from "react";
import { ProductVariants } from "./ProductVariants";
import { useProductPrices } from "@/api/useProductPrices";
import { useProductPricesUpdate } from "@/api/useProductPricesUpdate";
import { t } from "i18next";
import { Separator } from "@/components/ui/separator";
import { ApiObjects } from "@pulso/api-client";
import { withFacility } from "@/lib/withParams";

const formSchema = z
  .object({
    prices: z.any(),
  })
  .superRefine(({ prices }, ctx) => {
    const variantPeriodHash: Record<string, boolean> = {};
    for (const [variantHash, periods] of Object.entries(prices)) {
      for (const periodIdSeasonIdPair in periods as object) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        if (typeof (periods as any)[periodIdSeasonIdPair] !== "undefined") {
          const [periodId, seasonId] = periodIdSeasonIdPair.split("_");
          if (!variantPeriodHash[`${variantHash}_${periodId}`]) {
            variantPeriodHash[`${variantHash}_${periodId}`] = false;
          }
          variantPeriodHash[`${variantHash}_${periodId}`] ||= seasonId === "default";
        }
      }
    }
    const missingDefaults = Object.keys(variantPeriodHash).filter((key) => !variantPeriodHash[key]);
    for (const variantHashPeriodIdPair of missingDefaults) {
      const [variantHash, periodId] = variantHashPeriodIdPair.split("_");
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        path: [`prices.${variantHash}.${periodId}_default`],
        message: t("product_prices_error_missingDefaultValue", "Default value needs to be specified"),
      });
    }
  });

type ProductPricingFormProps = {
  product: ApiObjects["ProductWithExtraDto"];
  rentalPeriods: ApiObjects["RentalPeriodDto"][];
  variantPrices: ApiObjects["ProductPricesGetResponse"]["prices"];
  currency: string;
};

export function ProductPricingForm({ product, rentalPeriods, variantPrices, currency }: ProductPricingFormProps) {
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      prices: getAdaptedVariantPrices(),
    },
  });

  form.watch(); // To rerender on every change

  useEffect(() => {
    form.setValue("prices", getAdaptedVariantPrices());
  }, [product, rentalPeriods, variantPrices]);

  const saveVariantsMutation = useProductPricesUpdate(product.id);

  return (
    <div>
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-6">
          <div className="min-w-[400px]">
            <ProductVariants form={form as never} product={product} rentalPeriods={rentalPeriods} currency={currency} />
          </div>
          <Button type="submit">{t("common_button_save")}</Button>
        </form>
      </Form>
    </div>
  );

  function onSubmit(data: z.infer<typeof formSchema>) {
    const variantPrices: ApiObjects["PricesUpdateBody"]["variantPrices"] = [];

    for (const [variantHash, periods] of Object.entries(data.prices)) {
      const periodPrices = Object.entries(periods as never).map(([periodIdSeasonIdPair, price]) => {
        const [periodId, seasonId] = periodIdSeasonIdPair.split("_");
        return {
          periodId,
          seasonId,
          price: price === "" ? null : parseFloat(price + "") < 0 ? null : parseFloat(price + ""),
        };
      });

      if (periodPrices.length > 0) {
        variantPrices.push({
          hash: variantHash,
          periodPrices,
        });
      }
    }

    saveVariantsMutation.mutate({
      variantPrices,
    });
  }

  function getAdaptedVariantPrices() {
    return variantPrices.reduce((obj, variant) => {
      return {
        ...obj,
        [variant.hash]: variant.periodPrices.reduce(
          (periodsObj, period) => ({ ...periodsObj, [period.periodId + "_" + period.seasonId]: period.price }),
          {}
        ),
      };
    }, {});
  }
}

import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";
import { getDayOfYear } from "date-fns/getDayOfYear";

export function useSeasonCreate(facilityId: string) {
  return useMutation({
    mutationFn: async (data: { name: string; from: Date; to: Date }) => {
      await api.createSeason(
        { facilityId },
        {
          name: data.name,
          from: getDayOfYear(data.from),
          to: getDayOfYear(data.to),
        }
      );
    },
    successMessage: "SAVE_SUCCESS",
    invalidateKeys: [["seasons"]],
  });
}

import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { MainLayout } from "./layouts/MainLayout";
import { ProductsPage } from "./pages/products/ProductsPage";
import { RootPage } from "./pages/RootPage";
import { LoginPage } from "./pages/LoginPage";
import { ProductDetailsPage } from "./pages/products/ProductDetailsPage";
import { SettingsPage } from "./pages/settings/SettingsPage";
import { DocumentsPage } from "./pages/settings/DocumentsPage";
import { InventoryPage } from "./pages/inventory/InventoryPage";
import { BookingsPage } from "./pages/bookings/BookingsPage";
import { BookingDetailsPage } from "./pages/bookings/details/BookingDetailsPage";
import { ErrorPage } from "./pages/ErrorPage";
import { isAdmin, isPulsoAdmin } from "./lib/useAuth";
import { RentalPeriodsPage } from "./pages/settings/rental-periods/RentalPeriodsPage";
import { CustomerFieldsPage } from "./pages/settings/customer-fields/CustomerFieldsPage";
import { OpeningHoursPage } from "./pages/settings/opening-hours/OpeningHoursPage";
import { ExtraChargesPage } from "./pages/settings/extra-charges/ExtraChargesPage";
import { DocumentsSettingsPage } from "./pages/settings/documents/DocumentsSettingsPage";
import { DocumentCreatePage, DocumentUpdatePage } from "./pages/settings/documents/DocumentDetailsPage";
import { FastTrackSettingsPage } from "./pages/settings/fast-track/FastTrackSettingsPage";
import { AgentsSettingsPage } from "./pages/settings/agents/AgentsSettingsPage";
import { StoreSettingsPage } from "./pages/settings/store/StoreSettingsPage";
import { LocationsSettingsPage } from "./pages/settings/locations/LocationSettingsPage";
import { FacilitySettingsPage } from "./pages/settings/facility/FacilitySettingsPage";
import { UsersSettingsPage } from "./pages/settings/users/UserSettingsPage";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootPage />,
    errorElement: <ErrorPage />,
    children: [
      { path: "/login", element: <LoginPage /> },
      {
        path: "/external",
        children: [
          {
            path: "reset-password",
            lazy: () => import("./pages/external/reset-password/ResetPasswordPage"),
          },
          {
            path: "create-password",
            lazy: () => import("./pages/external/create-password/CreatePasswordPage"),
          },
        ],
      },
      {
        path: "/facility",
        children: [
          {
            path: "/facility/:facilityId",
            element: <MainLayout />,
            errorElement: <ErrorPage />,
            children: [
              {
                path: "/facility/:facilityId/bookings",
                element: <BookingsPage />,
              },
              {
                path: "/facility/:facilityId/calendar",
                lazy: () => import("./pages/calendar/CalendarPage"),
              },
              {
                path: "/facility/:facilityId/bookings/:bookingId",
                element: (
                  <MainLayout.Padding>
                    <BookingDetailsPage />
                  </MainLayout.Padding>
                ),
              },
              {
                path: "/facility/:facilityId/products",
                element: (
                  <MainLayout.Padding>
                    <ProductsPage />
                  </MainLayout.Padding>
                ),
              },
              {
                path: "/facility/:facilityId/products/:productId",
                element: (
                  <MainLayout.Padding>
                    <ProductDetailsPage />
                  </MainLayout.Padding>
                ),
              },
              {
                path: "/facility/:facilityId/inventory",
                element: (
                  <MainLayout.Padding>
                    <InventoryPage />
                  </MainLayout.Padding>
                ),
                children: [
                  {
                    index: true,
                    element: <InventoryPage />,
                  },
                  {
                    path: ":productId",
                    element: <InventoryPage />,
                  },
                ],
              },
              {
                path: "/facility/:facilityId/documents",
                element: (
                  <MainLayout.Padding>
                    <DocumentsPage />
                  </MainLayout.Padding>
                ),
              },
              {
                path: "/facility/:facilityId/customers",
                lazy: () => import("./pages/customers/CustomersPage"),
              },
              {
                path: "/facility/:facilityId/reports",
                lazy: () => import("./pages/reports/pages/ReportsHomePage"),
                handle: {
                  showOnlyIconsInSidebar: true,
                },
                loader: isAdminLoader,
                children: [
                  { path: "daily", lazy: () => import("./pages/reports/pages/DailyReports") },
                  { path: "products", lazy: () => import("./pages/reports/pages/ProductReports") },
                  { path: "stock-items", lazy: () => import("./pages/reports/pages/StockItemsReports") },
                  { path: "agents", lazy: () => import("./pages/reports/pages/AgentsReports") },
                  { path: "exports", lazy: () => import("./pages/reports/pages/exports/Exports") },
                ],
              },
              {
                path: "/facility/:facilityId/change-password",
                lazy: () => import("./pages/change-password/ChangePasswordPage"),
              },
              {
                path: "/facility/:facilityId/settings",
                element: <SettingsPage />,
                handle: {
                  showOnlyIconsInSidebar: true,
                },
                loader: isAdminLoader,
                children: [
                  { path: "facility", Component: FacilitySettingsPage },
                  { path: "users", Component: UsersSettingsPage },
                  { path: "rental-periods", Component: RentalPeriodsPage },
                  { path: "customer-fields", Component: CustomerFieldsPage },
                  { path: "opening-hours", Component: OpeningHoursPage },
                  { path: "extra-charges", Component: ExtraChargesPage },
                  { path: "documents", Component: DocumentsSettingsPage },
                  { path: "documents/:documentId", Component: DocumentUpdatePage },
                  { path: "documents/create/:facilityId", Component: DocumentCreatePage },
                  { path: "fast-track", Component: FastTrackSettingsPage },
                  { path: "agents", Component: AgentsSettingsPage },
                  { path: "store", Component: StoreSettingsPage },
                  { path: "locations", Component: LocationsSettingsPage },
                  {
                    path: "languages",
                    lazy: () => import("./pages/settings/languages/LanguagesSettingsPage"),
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "/admin",
        loader: isPulsoAdminLoader,
        lazy: () => import("./pages/admin/AdminPage"),
        children: [
          {
            path: "organization",
            lazy: () => import("./pages/admin/AdminOrganization"),
          },
          {
            path: "facilities",
            lazy: () => import("./pages/admin/AdminFacilities"),
          },
          {
            index: true,
            lazy: () => import("./pages/admin/AdminUsers"),
          },
        ],
      },
    ],
  },
]);

export function AppRouter() {
  return <RouterProvider router={router} />;
}

function isPulsoAdminLoader() {
  if (!isPulsoAdmin()) {
    throw new Response("Forbidden", { status: 403 });
  }

  return {};
}

function isAdminLoader() {
  if (!isAdmin()) {
    throw new Response("Forbidden", { status: 403 });
  }

  return {};
}

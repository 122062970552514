import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";

export function useDashboardViewCreate(facilityId: string) {
  return useMutation({
    mutationFn: async (data: { name: string; state: object }) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return await api.createDashboardView({ facilityId }, data as any);
    },
    successMessage: "SAVE_SUCCESS",
    invalidateKeys: [["dashboard-views"]],
  });
}

import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";
import { ApiObjects } from "@pulso/api-client";

export function useProductUpdatePackageProducts(productId: string) {
  return useMutation({
    mutationFn: async (data: ApiObjects["UpdatePackageProductsBody"]) =>
      await api.updatePackageProducts({ productId }, data),
    invalidateKeys: [["products"]],
    successMessage: "SAVE_SUCCESS",
  });
}

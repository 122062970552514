import { useMe } from "@/api/useMe";
import { useAuth } from "@/lib/useAuth";
import { useTranslation } from "react-i18next";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../ui/dropdown-menu";
import { Button } from "../ui/button";
import { EllipsisVertical } from "lucide-react";
import { Link } from "react-router-dom";
import { useState } from "react";

export function UserAccount() {
  const { t } = useTranslation();
  const { logout, facilityId } = useAuth();
  const { user } = useMe();
  const [open, setOpen] = useState(false);

  if (!user) {
    return null;
  }

  return (
    <DropdownMenu open={open} onOpenChange={setOpen}>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="text-white w-full flex justify-between">
          <span>
            {user.firstname} {user.lastname}
          </span>
          <EllipsisVertical size={16} className="ml-3" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuItem onClick={() => setOpen(false)}>
          <Link to={`/facility/${facilityId}/change-password`}>
            {t("profile_changePassword_title", "Change password")}
          </Link>
        </DropdownMenuItem>
        <DropdownMenuItem
          onClick={() => {
            setOpen(false);
            logout();
          }}
        >
          {t("menu_logout", "Logout")}
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

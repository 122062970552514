import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";
import { ApiObjects } from "@pulso/api-client";

export function useUserUpdate() {
  return useMutation({
    mutationFn: async (data: ApiObjects["CreateUserBody"] & { id: string }) => {
      const { id: userId, ...body } = data;
      await api.updateUser({ userId }, body);
    },
    successMessage: "SAVE_SUCCESS",
    invalidateKeys: [["users"]],
  });
}

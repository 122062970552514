import { api } from "@/lib/api-client";
import { ApiObjects } from "@pulso/api-client";
import { useMutation } from "@/lib/useMutation";

export function useRentalPeriodCreate(facilityId: string) {
  return useMutation({
    mutationFn: async (fieldData: ApiObjects["CreateRentalPeriodBody"]) => {
      await api.createRentalPeriod({ facilityId }, fieldData);
    },
    invalidateKeys: [["rental-period"]],
  });
}

import * as React from "react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./Select";
import { LanguageFlag } from "./LanguageFlag";

export interface SelectProps extends React.ComponentPropsWithoutRef<typeof Select> {
  languages?: string[];
}

const langOpts = [
  { value: "en", label: "English" },
  { value: "es", label: "Español" },
  { value: "de", label: "Deutsch" },
  { value: "fr", label: "Français" },
  { value: "it", label: "Italiano" },
];

const LanguagePicker = React.forwardRef<React.ElementRef<typeof Select>, SelectProps>(
  ({ languages, ...props }, ref) => {
    const langs = languages || ["en", "es"];
    const options = langOpts.filter((l) => langs.includes(l.value));

    return (
      <Select {...props} ref={ref}>
        <SelectTrigger className="bg-white">
          <SelectValue />
        </SelectTrigger>
        <SelectContent className="bg-white">
          {options.map((l) => (
            <SelectItem key={l.value} value={l.value} className="cursor-pointer focus:bg-secondary/75">
              <div className="flex items-center gap-2">
                <LanguageFlag lang={l.value} />
                <span>{l.label}</span>
              </div>
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    );
  }
);
LanguagePicker.displayName = "LanguagePicker";

export { LanguagePicker };

import { useDocuments } from "@/api/useDocuments";
import { t } from "i18next";
import { PageHeader } from "@/components/specific/PageHeader";
import { Card, CardContent } from "@/components/ui/card";
import { useDocumentSignatures } from "@/api/useDocumentSignatures";
import { withFacility, withFacilityIdParam } from "../../lib/withParams";
import { Spinner } from "@/components/ui/spinner";
import { formatDate } from "@/components/specific/DateFormat";
import { ApiObjects } from "@pulso/api-client";
import { groupBy } from "@/lib/utils";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Tabs, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Download } from "lucide-react";
import { createColumnHelper } from "@tanstack/react-table";
import { DataTable } from "@/components/ui/datatable";
import { Link } from "react-router-dom";
import { Price } from "@pulso/components/lib/Price";

export const DocumentsPage = withFacility(function ({ facility }) {
  const { documents, isLoading: isDocumentsLoading } = useDocuments();
  const documentGroups = groupBy(
    documents.filter((p) => p.poNumberFormat),
    (d) => d.poNumberFormat + ""
  );
  const [activeGroup, setActiveGroup] = useState<string>();

  useEffect(() => {
    if (!activeGroup) {
      setActiveGroup(Object.keys(documentGroups)[0]);
    }
  }, [documentGroups, activeGroup, setActiveGroup]);

  if (isDocumentsLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <PageHeader
        title={t("documents_title", "Documents")}
        subtitle={t("documents_subtitle", "Define documents your customers are required to sign.")}
      />
      <Tabs value={activeGroup} onValueChange={setActiveGroup}>
        <TabsList>
          {Object.keys(documentGroups).map((key) => (
            <TabsTrigger key={key} value={key}>
              {documentGroups[key].map((doc) => doc.name).join(" / ")}
            </TabsTrigger>
          ))}
        </TabsList>
      </Tabs>
      <div className="mt-6">
        {activeGroup && (
          <SignaturesList
            facilityId={facility.id}
            documents={documentGroups[activeGroup]}
            currency={facility.currency}
          />
        )}
      </div>
    </div>
  );
});

function SignaturesList({
  facilityId,
  documents,
  currency,
}: {
  facilityId: string;
  documents: ApiObjects["DocumentDto"][];
  currency: string;
}) {
  const { t } = useTranslation();
  const { signatures, pagination } = useDocumentSignatures(facilityId, {
    documentIds: documents.map((doc) => doc.id).join(","),
  });

  const columnHelper = createColumnHelper<ApiObjects["DocumentListItemDto"]>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const columns: any = [
    columnHelper.accessor("poNumber", {
      header: "#",
    }),
    columnHelper.accessor("poNumberCreatedAt", {
      header: t("documents_signatures_header_invoiceDate", "Date"),
      cell: (props) => {
        const val = props.getValue();
        return val ? formatDate(val) : "-";
      },
    }),
    columnHelper.accessor("customer.name", {
      header: t("documents_signatures_header_customer", "Customer / Booking"),
      cell: (props) => <Link to={`../bookings/${props.row.original.bookingId}`}>{props.getValue()}</Link>,
    }),
    columnHelper.accessor("totalDue", {
      header: t("documents_signatures_header_totalDue", "Total due"),
      cell: (props) => {
        const val = props.getValue();
        if (val === null) {
          return "-";
        } else if (val === 0) {
          return <div className="text-green-700">{t("bookings_table_header_paid")}</div>;
        } else {
          return (
            <div className="text-destructive">
              <Price price={val} currency={currency} />
            </div>
          );
        }
      },
    }),
    columnHelper.accessor("totalPrice", {
      header: t("documents_signatures_header_totalPrice", "Total price"),
      cell: (props) =>
        props.row.original.totalPrice && <Price price={props.row.original.totalPrice} currency={currency} />,
    }),
    columnHelper.accessor("url", {
      header: () => <div className="text-center">{t("documents_signatures_header_download", "Download")}</div>,
      cell: (props) => {
        const url = props.getValue();
        return (
          <div className="flex justify-center items-center">
            {url ? (
              <a href={url} target="_blank">
                <Download size={16} />
              </a>
            ) : (
              <Download size={16} className="text-foreground-muted" />
            )}
          </div>
        );
      },
    }),
  ];

  return (
    <Card>
      <CardContent className="pt-6">
        <DataTable
          columns={columns}
          data={signatures}
          emptyText={t("documents_signatures_empty", "No documents found")}
          pagination={pagination}
        />
      </CardContent>
    </Card>
  );
}

import { Button } from "@/components/ui/button";
import { ApiObjects } from "@pulso/api-client";
import { AddBookingItemModal } from "./AddBookingItemModal";
import { BookingItem } from "./BookingItem";
import { Price } from "@pulso/components/lib/Price";
import { t } from "i18next";
import { Separator } from "@/components/ui/separator";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import sumBy from "lodash/sumBy";

type BookingProductsProps = {
  booking: ApiObjects["BookingDto"];
};

export function BookingItems({ booking }: BookingProductsProps) {
  const nonPackageItems = booking.items.filter((item) => item.parentId === null);
  const itemsWithParent = booking.items.filter((item) => item.parentId !== null);
  const itemsByParent = groupBy(itemsWithParent, (i) => i.parentId);
  const groups = nonPackageItems.reduce(
    (groups, item) => {
      const key = item.productVariantId + item.period.id;
      return {
        ...groups,
        [key]: {
          key,
          items: [...(groups[key]?.items || []), item],
          product: item.product,
          price: (groups[key]?.price ?? 0) + (item.price || 0),
          singlePrice: item.price,
          hasMissingPrice: (groups[key]?.hasMissingPrice ?? false) || item.price === null,
          period: item.period,
          fields: item.fields.filter((f) => f.priceable),
          availability: item.availability,
          children: [
            ...(groups[key]?.children || []),
            ...(itemsByParent[item.id]?.length ? [{ itemId: item.id, items: itemsByParent[item.id] }] : []),
          ],
        },
      };
    },
    {} as Record<
      string,
      {
        items: ApiObjects["BookingItemDto"][];
        period: ApiObjects["BookingItemDto"]["period"];
        fields: ApiObjects["BookingItemDto"]["fields"];
        price: number;
        singlePrice: number | null;
        hasMissingPrice: boolean;
        availability: number;
        key: string;
        product: ApiObjects["ProductDto"];
        children: { itemId: string; items: ApiObjects["BookingItemDto"][] }[];
      }
    >
  );
  const variants = Object.entries(groups)
    .map(([key, group]) => ({
      ...group,
      firstUnassignedItem:
        sortBy(group.items, (item) =>
          group.children.length === 0
            ? item.stockItem === null
              ? -1
              : 1
            : sumBy(group.children.find((i) => i.itemId === item.id)?.items ?? [], (childItem) =>
                childItem.stockItem === null ? 0 : 1
              )
        )[0] ?? null,
    }))
    .sort((a, b) => (a.product.name < b.product.name ? -1 : 1));

  const totalPrice = variants.reduce((total, variant) => total + variant.price, 0);

  return (
    <>
      <div className="mb-3">
        {booking.status !== "COMPLETED" && (
          <AddBookingItemModal booking={booking} periodId={booking.items[0]?.period.id}>
            <Button>{t("bookings_items_add_button", "Add products")}</Button>
          </AddBookingItemModal>
        )}
      </div>
      <div className="hidden md:grid gap-3 w-full py-3 font-bold bookingItem__gridCols">
        <div>{t("bookings_items_header_items", "Items")}</div>
        <div>{t("bookings_items_header_quantity", "Quantity")}</div>
        <div>{t("bookings_items_header_availability", "Availability")}</div>
        <div>{t("bookings_items_header_period", "Duration")}</div>
        <div className="text-right">{t("bookings_items_header_singlePrice", "Price")}</div>
        <div className="text-right">{t("bookings_items_header_totalPrice", "Total")}</div>
      </div>
      <Separator />
      {variants.map((variant) => (
        <BookingItem key={variant.key} variant={variant} booking={booking} />
      ))}

      <div className="text-right font-bold py-3">
        <span className="mr-6">{t("bookings_items_totalPrice", "Total price")}</span>{" "}
        <Price price={totalPrice} currency={booking.currency} />
      </div>
    </>
  );
}

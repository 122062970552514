import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";

export function useAgentDelete() {
  return useMutation({
    mutationFn: async (agentId: string) => {
      await api.deleteAgent({ agentId });
    },
    invalidateKeys: [["agents"]],
    successMessage: "SAVE_SUCCESS",
  });
}

import { PulsoFormProvider, createForm } from "@/components/specific/Form";
import { ApiObjects } from "@pulso/api-client";
import { formatDate } from "@pulso/utils";
import { getDayOfYear } from "date-fns/getDayOfYear";
import { useTranslation } from "react-i18next";
import { z } from "zod";

export type FormData = {
  name: string;
  from: Date;
  to: Date;
};

export type SeasonFormProps = {
  seasons: ApiObjects["SeasonDto"][];
  isLoading: boolean;
  initialData: FormData;
  onSubmit: (data: FormData) => Promise<unknown>;
};

const monthFormatter = Intl.DateTimeFormat(undefined, { month: "long" });

export function SeasonForm(props: SeasonFormProps) {
  const { t } = useTranslation();
  const formSchema = z
    .object({
      name: z.string().min(2).max(50),
      from: z.date(),
      to: z.date(),
    })
    .superRefine((data, ctx) => {
      const fromDay = getDayOfYear(data.from);
      const toDay = getDayOfYear(data.to);
      const overlap = props.seasons.find((season) => season.to >= fromDay && toDay >= season.from);
      if (overlap) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["from"],
          message: " ",
        });
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["to"],
          message: t("settings_seasons_error_overlapping_seasons", "Overlapping seasons are not allowed"),
        });
      }
    });

  const form = createForm(
    {
      name: {
        label: t("settings_seasons_form_label_name", "Name"),
        type: "string" as const,
      },
      from: {
        label: t("settings_seasons_form_label_from", "From"),
        type: "date" as const,
        datePickerOptions: {
          showOutsideDays: true,
          hideClear: true,
          fromYear: 2024,
          toYear: 2024,
          label: (date: Date) => formatDate(date, null, undefined, "date-short"),
          components: {
            CaptionLabel: (data) => <div>{monthFormatter.format(data.displayMonth)}</div>,
          },
        },
      },
      to: {
        label: t("settings_seasons_form_label_to", "To"),
        type: "date" as const,
        datePickerOptions: {
          showOutsideDays: true,
          hideClear: true,
          fromYear: 2024,
          toYear: 2024,
          label: (date: Date) => formatDate(date, null, undefined, "date-short"),
          components: {
            CaptionLabel: (data) => <div>{monthFormatter.format(data.displayMonth)}</div>,
          },
        },
      },
    },
    formSchema
  );

  return (
    <PulsoFormProvider
      {...form}
      isLoading={props.isLoading}
      onSubmit={props.onSubmit}
      initialValues={props.initialData}
    />
  );
}

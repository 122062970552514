import { api } from "@/lib/api-client";
import { ApiObjects } from "@pulso/api-client";
import { useMutation } from "@/lib/useMutation";

export function useRentalPeriodUpdate(periodId: string) {
  return useMutation({
    mutationFn: async (fieldData: ApiObjects["UpdateRentalPeriodBody"]) => {
      await api.updateRentalPeriod({ periodId }, fieldData);
    },
    invalidateKeys: [["rental-period"]],
  });
}

import { fieldsToHash } from "@pulso/utils";
import { useProductPrices } from "./useProductPrices";
import { useRentalPeriods } from "./useRentalPeriods";
import keyBy from "lodash/keyBy";

export function usePeriodsWithPrice(productId: string | undefined | null, fieldValues: { value: string }[]) {
  const fieldsHash = fieldsToHash(fieldValues);
  const { prices, enabledPeriods } = useProductPrices(productId ?? undefined);
  const { periods } = useRentalPeriods();
  const variantPrices = prices.find((p) => p.hash === fieldsHash);
  return periods.map((p) => {
    const prices = keyBy(
      (variantPrices?.periodPrices || []).filter((vp) => vp.periodId === p.id && typeof vp.price === "number"),
      (v) => v.seasonId
    );
    const enabled = !!enabledPeriods.find((enabledRp) => enabledRp.id === p.id);

    return {
      ...p,
      enabled,
      price: typeof prices.default === "undefined" ? null : prices.default.price,
      seasons: prices,
    };
  });
}

import { useFacilitySettings } from "@/api/useFacilitySettings";
import { useAuth } from "@/lib/useAuth";
import { minutesToLabel } from "@pulso/utils";
import { useMemo } from "react";

export function useTimeSlots() {
  const { facilityId } = useAuth();
  const { settings } = useFacilitySettings(facilityId || "");
  const slots = useFixedTimeSlots(settings?.timeGranularity);

  return { slots, timeGranularity: settings?.timeGranularity || 15 };
}

export function useFixedTimeSlots(minutes: number = 15) {
  return useMemo(() => {
    const times: { value: string; valueNum: number; label: string }[] = [];
    for (let i = 0; i < 1440; i += minutes) {
      times.push({ value: i + "", valueNum: i, label: minutesToLabel(i) });
    }

    return times;
  }, [minutes]);
}

import { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

export function useWindowResizeVersion() {
  const [resizeVersion, setResizeVersion] = useState(0);
  const debouncedUpdate = useDebouncedCallback(() => {
    setResizeVersion((v) => v + 1);
  }, 300);

  useEffect(() => {
    window.addEventListener("resize", debouncedUpdate);
    return () => window.removeEventListener("resize", debouncedUpdate);
  }, []);

  return resizeVersion;
}

import React, { memo, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAuth } from "@/lib/useAuth";
import { useCustomerFields } from "@/api/useCustomerFields";
import { Spinner } from "../../ui/spinner";
import { type Editor } from "tinymce";

let timer: NodeJS.Timeout | null = null;
let currentValue: string | null = null;

type RichTextEditorProps = { value: string; onChange: (value: string) => void; language: string };

export const RichTextEditor = memo(RichTextEditorRaw, (oldProps, newProps) => newProps.value === currentValue);

export function RichTextEditorRaw(props: RichTextEditorProps) {
  const auth = useAuth();
  const { fields, isLoading } = useCustomerFields(auth.facilityId || "");
  const { t } = useTranslation();
  const elementRef = useRef<HTMLTextAreaElement | null>(null);
  const editorRef = useRef<Editor | null>(null);
  const [isEditorLoading, setIsEditorLoading] = useState(true);

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.setContent(props.value);
    }
    currentValue = props.value;
  }, [props.value]);

  useEffect(() => {
    setIsEditorLoading(true);
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(load, 500);

    return () => {
      if (editorRef.current) {
        editorRef.current.off("Change");
        editorRef.current.getBody().remove();
        editorRef.current.remove();
        editorRef.current = null;
      }
    };
  }, []);

  return (
    <div>
      {(isLoading || isEditorLoading) && (
        <div className="py-3">
          <Spinner />
        </div>
      )}
      <textarea className={"invisible"} ref={elementRef} id="richTextEditor" defaultValue={props.value}></textarea>
    </div>
  );

  async function load() {
    const { tinymce } = await import("./RichTestEditor.bundle");
    const editors = await tinymce.init({
      selector: "#richTextEditor",
      base_url: "/tinymce",
      plugins: "lists advlist table variables",
      height: 700,
      toolbar:
        "undo redo | " +
        "bold italic underline | h1 h2 h3 | fontsize | forecolor | alignleft aligncenter " +
        "alignright alignjustify lineheight | bullist numlist outdent indent | table | variables",
      toolbar_mode: "wrap",
      language: props.language,
      variables_items: getVariables(),
      variables_button: t("richTextEditor_variables_placeholder", "Insert variable"),
      content_css: "/css/rich-text-editor.css",
      format_noneditable_selector: "span",
      font_size_formats: "6pt 8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt",
      line_height_formats: "0.7 0.8 0.9 1 1.1 1.2 1.3 1.4 1.5 1.6 1.7 1.8 1.9 2 3",
    });

    const editor = editors[0];
    setIsEditorLoading(false);

    if (editor) {
      editor.on("Change", () => {
        const newContent = editor.getContent();
        currentValue = newContent;
        props.onChange(newContent);
      });
      editorRef.current = editor;
    }
  }

  function getVariables() {
    return [
      // Customer Data
      {
        value: "Customer_Full_Name",
        label: t("richTextEditor_variables_customer_full_name", "Customer full name"),
        section: t("richTextEditor_variables_section_customerData", "Customer"),
      },
      {
        value: "Customer_First_Name",
        label: t("richTextEditor_variables_customer_first_name", "Customer first name"),
        section: t("richTextEditor_variables_section_customerData", "Customer"),
      },
      {
        value: "Customer_Last_Name",
        label: t("richTextEditor_variables_customer_last_name", "Customer last name"),
        section: t("richTextEditor_variables_section_customerData", "Customer"),
      },
      {
        value: "Customer_Email",
        label: t("richTextEditor_variables_customer_email", "Customer email"),
        section: t("richTextEditor_variables_section_customerData", "Customer"),
      },
      ...fields
        .filter((f) => f.type !== "FILE")
        .map((field) => ({
          value: "Field_" + field.id,
          label: field.name,
          section: t("richTextEditor_variables_section_customerData", "Customer"),
        })),

      // Booking Schedule
      {
        value: "Booking_Delivery_Date_Time",
        label: t("richTextEditor_variables_booking_delivery_date_time", "Booking delivery date"),
        section: t("richTextEditor_variables_section_bookingSchedule", "Schedule"),
      },
      {
        value: "Booking_Return_Date_Time",
        label: t("richTextEditor_variables_booking_return_date_time", "Booking return date"),
        section: t("richTextEditor_variables_section_bookingSchedule", "Schedule"),
      },
      {
        value: "Booking_Delivered_Date_Time",
        label: t("richTextEditor_variables_booking_Delivered_Date_Time", "Actual booking delivery date"),
        section: t("richTextEditor_variables_section_bookingSchedule", "Schedule"),
      },
      {
        value: "Booking_Returned_Date_Time",
        label: t("richTextEditor_variables_booking_returned_date_time", "Actual booking return date"),
        section: t("richTextEditor_variables_section_bookingSchedule", "Schedule"),
      },
      {
        value: "Booking_Period",
        label: t("richTextEditor_variables_booking_period", "Booking period"),
        section: t("richTextEditor_variables_section_bookingSchedule", "Schedule"),
      },

      // Invoicing
      {
        value: "Total_Price",
        label: t("richTextEditor_variables_total_price", "Total price"),
        section: t("richTextEditor_variables_section_invoicing", "Invoicing"),
      },
      {
        value: "Total_Payments",
        label: t("richTextEditor_variables_total_payments", "Total payments"),
        section: t("richTextEditor_variables_section_invoicing", "Invoicing"),
      },
      {
        value: "Total_Due",
        label: t("richTextEditor_variables_total_due", "Total due"),
        section: t("richTextEditor_variables_section_invoicing", "Invoicing"),
      },
      {
        value: "Deposit",
        label: t("richTextEditor_variables_deposit", "Deposit"),
        section: t("richTextEditor_variables_section_invoicing", "Invoicing"),
      },
      {
        value: "Products",
        label: t("richTextEditor_variables_products", "Products"),
        section: t("richTextEditor_variables_section_invoicing", "Invoicing"),
      },
      {
        value: "Products_Table",
        label: t("richTextEditor_variables_productsTable", "Products table"),
        section: t("richTextEditor_variables_section_invoicing", "Invoicing"),
      },
      {
        value: "Products_List",
        label: t("richTextEditor_variables_productsList", "Products list"),
        section: t("richTextEditor_variables_section_invoicing", "Invoicing"),
      },
      {
        value: "Products_Identifiers",
        label: t("richTextEditor_variables_productsIdentifiers", "Products identifiers"),
        section: t("richTextEditor_variables_section_invoicing", "Invoicing"),
      },
      {
        value: "Payments",
        label: t("richTextEditor_variables_payments", "Payments"),
        section: t("richTextEditor_variables_section_invoicing", "Invoicing"),
      },
      {
        value: "PO_Number",
        label: t("richTextEditor_variables_poNumber", "P.O. Number"),
        section: t("richTextEditor_variables_section_invoicing", "Invoicing"),
      },
      {
        value: "PO_Number_Created_Date",
        label: t("richTextEditor_variables_poNumberCreationDate", "P.O. Creation Date"),
        section: t("richTextEditor_variables_section_invoicing", "Invoicing"),
      },

      // Other
      {
        value: "Booking_Reference",
        label: t("richTextEditor_variables_booking_reference", "Booking reference"),
        section: t("richTextEditor_variables_section_other", "Other"),
      },
      {
        value: "Current_Date",
        label: t("richTextEditor_variables_current_date", "Current date"),
        section: t("richTextEditor_variables_section_other", "Other"),
      },
      {
        value: "Signature",
        label: t("richTextEditor_variables_signature", "Signature"),
        section: t("richTextEditor_variables_section_other", "Other"),
      },
    ];
  }
}

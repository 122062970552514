import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";

export function useLocationDelete() {
  return useMutation({
    mutationFn: async (locationId: string) => {
      await api.deleteLocation({ locationId });
    },
    successMessage: "DELETE_SUCCESS",
    invalidateKeys: [["locations"]],
  });
}

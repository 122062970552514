import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";
import { ApiObjects } from "@pulso/api-client";

export function useCustomerFieldsUpdate() {
  return useMutation({
    mutationFn: async (data: ApiObjects["CustomerFieldUpdateBody"] & { id: string }) => {
      const { id: fieldId, ...body } = data;
      return await api.updateCustomerField({ fieldId }, body);
    },
    invalidateKeys: [["customer-fields"]],
    successMessage: "SAVE_SUCCESS",
  });
}

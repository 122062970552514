import { api } from "@/lib/api-client";
import { useQueryClient, useMutation } from "@tanstack/react-query";

export function useStockItemDelete() {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (stockItemId: string) => {
      await api.deleteStockItem({ stockItemId });
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["stock-items"] }),
  });
}

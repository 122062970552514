import { cn } from "@/lib/utils";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationPrevious,
  PaginationLink,
  PaginationNext,
} from "../ui/pagination";
import { Ellipsis } from "lucide-react";
import { Fragment } from "react/jsx-runtime";

export type AutoPaginationProps = {
  page: number;
  pageSize: number;
  total: number;
  onChange: (page: number) => void;
};

export function AutoPagination({ page: currentPage, total, pageSize, onChange }: AutoPaginationProps) {
  const lastPage = Math.ceil(total / pageSize);
  let pages = [...new Array(lastPage)].map((p, i) => i + 1);
  if (pages.length > 15) {
    if (currentPage <= 5) {
      pages = [1, 2, 3, 4, 5, -1, lastPage - 2, lastPage - 1, lastPage];
    } else if (currentPage >= lastPage - 4) {
      pages = [1, 2, 3, -1, lastPage - 4, lastPage - 3, lastPage - 2, lastPage - 1, lastPage];
    } else if (currentPage === lastPage - 3) {
      pages = [1, 2, 3, -1, lastPage - 3, lastPage - 2, lastPage - 1, lastPage];
    } else {
      pages = [1, 2, 3, -1, currentPage - 1, currentPage, currentPage + 1, -1, lastPage - 2, lastPage - 1, lastPage];
    }
  }

  if (pages.length === 1) {
    return null;
  }

  return (
    <Pagination>
      <PaginationContent>
        <PaginationItem className={cn(currentPage <= 1 ? "invisible" : "")}>
          <PaginationPrevious className="cursor-pointer" onClick={() => onChange(currentPage - 1)} />
        </PaginationItem>
        {pages.map((page) => (
          <Fragment key={page}>
            {page === -1 ? (
              <div className="px-2">
                <Ellipsis size={16} />
              </div>
            ) : (
              <PaginationItem>
                <PaginationLink
                  className="cursor-pointer"
                  onClick={() => onChange(page)}
                  isActive={page === currentPage}
                >
                  {page}
                </PaginationLink>
              </PaginationItem>
            )}
          </Fragment>
        ))}
        <PaginationItem className={cn(currentPage >= lastPage ? "invisible" : "")}>
          <PaginationNext className="cursor-pointer" onClick={() => onChange(currentPage + 1)} />
        </PaginationItem>
      </PaginationContent>
    </Pagination>
  );
}

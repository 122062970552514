import * as React from "react";

import { cn } from "@/lib/utils";

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const Input = React.forwardRef<HTMLInputElement, InputProps>(({ className, type, ...props }, ref) => {
  return props.prefix ? withPrefix(props.prefix) : withoutPrefix();

  function withoutPrefix() {
    return (
      <input
        type={type}
        className={cn(
          "flex h-9 w-full rounded-md border border-input bg-white px-3 py-1 text-sm shadow-sm transition-colors file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:border-gray-700 disabled:cursor-not-allowed disabled:opacity-50",
          className
        )}
        ref={ref}
        {...props}
        autoComplete={props.autoComplete || "off"}
      />
    );
  }

  function withPrefix(prefix: string) {
    return (
      <div
        className={cn("relative flex h-9 w-full rounded-md text-sm shadow-sm transition-colors", className)}
        ref={ref}
      >
        <span
          className={cn(
            "bg-gray-100 w-[34px] flex-shrink-0 flex justify-center items-center cursor-pointer absolute left-[1px] top-[1px] bottom-[1px] rounded-tl-sm rounded-bl-sm",
            props.disabled && "text-muted-foreground"
          )}
        >
          {prefix}
        </span>
        <input
          type={type}
          className="w-full h-full rounded-tr-md border border-input rounded-br-md px-3 py-1 no-number-controls pl-[46px] rounded-tl-md rounded-bl-md z-10 bg-transparent placeholder:text-muted-foreground focus-visible:outline-none focus-visible:border-gray-700 disabled:cursor-not-allowed disabled:opacity-50"
          ref={ref}
          {...props}
          autoComplete={props.autoComplete || "off"}
        />
      </div>
    );
  }
});
Input.displayName = "Input";

export { Input };

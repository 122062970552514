import { useAgents } from "@/api/useAgents";
import { useBookingPatch } from "@/api/useBookingPatch";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { ApiObjects } from "@pulso/api-client";
import { formatPrice } from "@pulso/utils";
import { useTranslation } from "react-i18next";

export function BookingDetailsAgent({ booking }: { booking: ApiObjects["BookingDto"] }) {
  const save = useBookingPatch(booking.id);
  const { t } = useTranslation();
  const { agents, isLoading } = useAgents(booking.facilityId);
  const agent = agents.find((a) => a.id === booking.agentId);
  const currentFee = !agent
    ? 0
    : booking.items.reduce((sum, item) => {
        const fee = agent.productFees.find((fee) => fee.productId === item.product.id);

        if (!fee || !item.price) {
          return sum;
        }
        return sum + (fee.feeType === "PERCENTAGE" ? (fee.fee * item.price) / 100 : fee.fee);
      }, 0);

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Select
        value={booking.agentId || "empty"}
        onValueChange={(agentId) => save.mutate({ agentId: agentId === "empty" ? null : agentId })}
      >
        <SelectTrigger className="max-w-64">
          <div className="flex justify-between w-full">
            <SelectValue />{" "}
            {booking.agentId && (
              <div className="text-foreground-muted text-sm text-right pr-3">
                {t("booking_details_agent_currentFee", "{{fee}}", { fee: formatPrice(currentFee, booking.currency) })}
              </div>
            )}
          </div>
        </SelectTrigger>
        <SelectContent>
          <SelectItem value="empty">{t("booking_details_agent_option_noAgent", "No agent")}</SelectItem>
          {agents.map((agent) => (
            <SelectItem key={agent.id} value={agent.id}>
              {agent.name}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </>
  );
}

import { useProducts } from "@/api/useProducts";
import { useStoreSettings } from "@/api/useStoreSettings";
import { useStoreSettingsPatch } from "@/api/useStoreSettingsPatch";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { CentralSpinner } from "@/components/ui/central-spinner";
import { Form, FormField } from "@/components/ui/form";
import { Switch } from "@/components/ui/switch";
import { withFacilityIdParam } from "@/lib/withParams";
import { ApiObjects } from "@pulso/api-client";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { SelectNative } from "@pulso/components/lib/SelectNative";
import { Code } from "@pulso/components/lib/Code";
import { StripeSettings } from "./StripeSettings";
import { StoreSettingsForm } from "./StoreSettingsForm";

export const StoreSettingsPage = withFacilityIdParam((params) => {
  const { t } = useTranslation();
  const { settings, isLoading } = useStoreSettings(params.facilityId);
  const patchSettings = useStoreSettingsPatch(params.facilityId);

  const integrationScripts = {
    script: `<script src="https://app.pulsorent.com/store/store-loader.js" data-facility-id="${params.facilityId}"></script>`,
    button: `<button onclick="pulso.open('${params.facilityId}')">Book now</button>`,
    link: `javascript:pulso.open('${params.facilityId}')`,
  };

  if (!settings || isLoading) {
    return <CentralSpinner />;
  }

  return (
    <div className="space-y-6">
      <Card>
        <CardHeader>
          <CardTitle>{t("settings_store_enable_title", "Online store")}</CardTitle>
          <CardDescription>
            {t(
              "settings_store_enable_description",
              "Enable Online Store to allow your customers to book directly from your website."
            )}
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="mb-1">
            <label className="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
              {t("settings_store_enable_label", "Enable Online store")}
            </label>
          </div>
          <Switch checked={settings.enabled} onCheckedChange={(c) => patchSettings.mutate({ enabled: c })}></Switch>
        </CardContent>
      </Card>
      {settings.enabled && (
        <>
          <Card>
            <CardHeader>
              <CardTitle>{t("settings_store_link_title", "Try out store")}</CardTitle>
              <CardDescription>
                {t("settings_store_link_description", "Open a demo store that simulates the real customer experience.")}
              </CardDescription>
            </CardHeader>
            <CardContent>
              <a
                target="_blank"
                href={
                  (process.env.REACT_APP_STORE_DEMO_URL || "") +
                  "?f=" +
                  params.facilityId +
                  "&apiUrl=" +
                  encodeURIComponent(process.env.REACT_APP_STORE_API_URL || "")
                }
              >
                <Button variant="outline">{t("settings_store_enable_title", "Open store")}</Button>
              </a>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>{t("settings_store_integrate_title", "Integrate in your webiste")}</CardTitle>
              <CardDescription>
                {t(
                  "settings_store_integrate_description",
                  "Follow the instructions to integrate the Pulso Online Store with your website."
                )}
              </CardDescription>
            </CardHeader>
            <CardContent>
              <div className="text-sm space-y-6">
                <div className="space-y-2">
                  <div>
                    1. {t("settings_store_integrate_addScript_title", "Add the loading script to your main page")}
                  </div>
                  <Code text={integrationScripts.script} copyButtonText={t("common_button_copy", "Copy")} />
                </div>
                <div className="space-y-2">
                  <div>2. {t("settings_store_integrate_addButton_title", 'Add or modify a "Book now" button')}</div>
                  <Code text={integrationScripts.button} copyButtonText={t("common_button_copy", "Copy")} />
                  <div>
                    {t(
                      "settings_store_integrate_addButtonHref_title",
                      "or use the following link for a button if you are using a CMS, such as WordPress: "
                    )}
                    <span className="font-mono bg-secondary/50 p-1 border rounded-md">{integrationScripts.link}</span>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>{t("settings_store_settings_title", "Store customization")}</CardTitle>
              <CardDescription>
                {t(
                  "settings_store_settings_description",
                  "Customzie your online store the way that best suites your business."
                )}
              </CardDescription>
            </CardHeader>
            <CardContent>
              <StoreSettingsForm facilityId={params.facilityId} />
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>{t("settings_store_products_title", "Store Products")}</CardTitle>
              <CardDescription>
                {t(
                  "settings_store_products_description",
                  "Choose which products will be bookable in your online store."
                )}
              </CardDescription>
            </CardHeader>
            <CardContent>
              <StoreProductForm facilityId={params.facilityId} settings={settings} />
            </CardContent>
          </Card>
          <Card>
            <CardHeader>
              <CardTitle>{t("settings_store_payments_title", "Payments")}</CardTitle>
              <CardDescription>
                {t("settings_store_payments_description", "Connect a payment provider")}
              </CardDescription>
            </CardHeader>
            <CardContent>
              <Switch
                checked={settings.paymentsEnabled}
                onCheckedChange={(c) => patchSettings.mutate({ paymentsEnabled: c })}
              ></Switch>
              {settings.paymentsEnabled && (
                <div className="pt-6">
                  <StripeSettings facilityId={params.facilityId} />
                </div>
              )}
            </CardContent>
          </Card>
        </>
      )}
    </div>
  );
});

type StoreProductFormItem = { enabled: boolean; complementFor?: string | null };
type StoreProductFormData = Record<string, StoreProductFormItem>;

function StoreProductForm(props: { facilityId: string; settings: ApiObjects["StoreSettingsDto"] }) {
  const { t } = useTranslation();
  const patchSettings = useStoreSettingsPatch(props.facilityId);
  const { data: products } = useProducts();

  const form = useForm<StoreProductFormData>({
    defaultValues:
      props.settings.products.reduce(
        (obj, p) => ({
          ...obj,
          [p.productId]: { enabled: true },
        }),
        {} as StoreProductFormData
      ) || {},
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSave)}>
        <table className="space-y-2 mb-3">
          <tbody>
            {products?.map((product) => (
              <tr key={product.id}>
                <td className="p-2 text-sm">{product.name}</td>
                <td className="p-2">
                  <FormField
                    name={product.id}
                    control={form.control}
                    render={({ field }) => (
                      <div className="flex items-center space-x-3">
                        <Switch
                          checked={field.value?.enabled}
                          onCheckedChange={(enabled) => field.onChange({ ...(field.value || {}), enabled })}
                        />
                        {false && field.value?.enabled && (
                          <SelectNative
                            value={field.value?.complementFor || ""}
                            onChange={(e) => field.onChange({ ...(field.value || {}), complementFor: e.target.value })}
                          >
                            <option value="">{t("settings_store_products_complementFor_none", "Main product")}</option>
                            {products?.map((opt) => (
                              <option key={product.id + opt.id + "_opt"} value={opt.id}>
                                {t("settings_store_products_complementFor_label", "Complements {{product}}", {
                                  product: opt.name,
                                })}
                              </option>
                            ))}
                          </SelectNative>
                        )}
                      </div>
                    )}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Button type="submit">{t("common_button_save")}</Button>
      </form>
    </Form>
  );

  function onSave(formData: StoreProductFormData) {
    const productsData = Object.keys(formData)
      .filter((productId) => formData[productId]?.enabled)
      .map((productId) => ({
        productId,
        complementFor: formData[productId]?.complementFor,
      }));

    patchSettings.mutate({ products: productsData });
  }
}

import CopyToClipboard from "react-copy-to-clipboard";
import { Button, ButtonProps } from "./Button";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";
import { cn } from "./utils";

type CodeProps = {
  text: string;
  copyButtonText?: string;
  buttonPlacement?: "right" | "bottom";
  buttonVariant?: ButtonProps["variant"];
  className?: string;
  hideButton?: boolean;
};

export function Code({ text, hideButton, copyButtonText, buttonPlacement, buttonVariant, className }: CodeProps) {
  const { t } = useTranslation();

  return (
    <div
      className={cn(
        "p-3 border rounded-md bg-secondary/50 font-mono flex gap-3 text-sm",
        buttonPlacement === "bottom" ? "flex-col" : "items-center justify-between",
        className
      )}
    >
      <pre className="text-wrap break-all">{text}</pre>
      {!hideButton && (
        <div>
          <CopyToClipboard text={text} onCopy={() => toast.success(t("common_copy_success", "Copied to clipboard!"))}>
            <Button variant={buttonVariant || "outline"} size="sm">
              {copyButtonText || t("common_button_copy", "Copy")}
            </Button>
          </CopyToClipboard>
        </div>
      )}
    </div>
  );
}

import { api } from "@/lib/api-client";
import { ApiObjects } from "@pulso/api-client";
import { useMutation } from "@/lib/useMutation";

export function useStockItemsCreate(productId: string) {
  return useMutation({
    mutationFn: async (data: ApiObjects["CreateStockItemsBody"]) => {
      await api.createStockItems(
        { productId },
        {
          count: data.count,
          fields: data.fields,
        }
      );
    },
    invalidateKeys: [["stock-items"]],
    successMessage: "SAVE_SUCCESS",
  });
}

import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";

export function useLocationCreate(facilityId: string) {
  return useMutation({
    mutationFn: async (data: { name: string }) => {
      await api.createLocation(
        { facilityId },
        {
          name: data.name,
        }
      );
    },
    successMessage: "SAVE_SUCCESS",
    invalidateKeys: [["locations"]],
  });
}

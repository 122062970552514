import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { PropsWithChildren, useState } from "react";
import { FormData, UserForm, UserFormProps } from "./UserForm";

type UserFormDialogProps = PropsWithChildren<
  Omit<UserFormProps, "onSubmit"> & {
    title: string;
    description: string;
    onSubmit: (data: FormData) => Promise<unknown>;
  }
>;

export function UserFormDialog(props: UserFormDialogProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>{props.children}</DialogTrigger>
      <DialogContent hideFooter>
        <DialogHeader>
          <DialogTitle>{props.title}</DialogTitle>
          <DialogDescription>{props.description}</DialogDescription>
        </DialogHeader>
        <UserForm
          users={props.users}
          initialData={props.initialData}
          onSubmit={(v) => {
            props.onSubmit(v).then(() => setIsOpen(false));
          }}
        />
      </DialogContent>
    </Dialog>
  );
}

import { DateFormat } from "./DateFormat";

export function DateColumn({ date, timezone }: { date: Date | string; timezone: string }) {
  return (
    <div className="md:text-center -space-y-1 md:space-y-0">
      <div className="font-bold">
        <DateFormat showToday={true} timezone={timezone} date={date} format="date-short" />
      </div>
      <div>
        <DateFormat date={date} format="time" />
      </div>
    </div>
  );
}

import { ArrowLeft } from "lucide-react";
import { Separator } from "../ui/separator";
import { Link } from "react-router-dom";

export function PageHeader(props: { title: string; subtitle: string; back?: { to: string; label: string } }) {
  return (
    <>
      <div className="flex flex-col items-start">
        {props.back && (
          <Link to={props.back.to} className="mb-1 flex items-center space-x-1 text-sm hover:bg-transparent px-0">
            <ArrowLeft size={16} /> <span>{props.back.label}</span>
          </Link>
        )}
        <div className="space-y-0.5">
          <h2 className="text-2xl font-bold tracking-tight">{props.title}</h2>
          <p className="text-muted-foreground">{props.subtitle}</p>
        </div>
      </div>
      <Separator className="my-6" />
    </>
  );
}

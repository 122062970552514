import { useBookingDocumentsSignUrl } from "@/api/useBookingDocumentsSignUrl";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogHeader,
  DialogTrigger,
  DialogContent,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { Spinner } from "@/components/ui/spinner";
import { ApiObjects } from "@pulso/api-client";
import { PropsWithChildren } from "react";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";

type BookingSignModalProps = {
  bookingId: string;
  documents: ApiObjects["BookingDocumentDto"][];
};

export function BookingSignModal({ children, bookingId }: PropsWithChildren<BookingSignModalProps>) {
  const { t } = useTranslation();
  const { url, isLoading } = useBookingDocumentsSignUrl(bookingId);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Dialog>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("bookings_details_documents_sign_modal_title", "Sign documents")}</DialogTitle>
          <DialogDescription>
            {t(
              "bookings_details_documents_sign_modal_description",
              "Ask your customer to scan this QR code, so that they can sign all documents on their phone."
            )}
          </DialogDescription>
        </DialogHeader>
        {url && (
          <div className="flex flex-col items-center space-y-3">
            <QRCode value={url} />
            <a href={url} target="_blank">
              <Button variant="secondary">
                {t("bookings_details_documents_sign_modal_signOnTheSameDevice_button", "Open in a new window")}
              </Button>
            </a>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

import { Separator } from "@/components/ui/separator";
import { SheetContent, SheetHeader, SheetTitle, SheetDescription, Sheet } from "@/components/ui/sheet";
import { ApiObjects } from "@pulso/api-client";
import { PropsWithChildren, memo } from "react";
import { StockItemStatus } from "./StockItemStatus";
import { useStockItems } from "@/api/useStockItems";
import { useStockItemUpdate } from "@/api/useStockItemUpdate";
import { useEditStockItemsForm } from "./useEditStockItemsForm";
import { useTranslation } from "react-i18next";
import { fieldToHashZodTransformer } from "@/lib/utils";
import { formatDateTnLocalTimezone } from "@pulso/utils";
import { PulsoFormProvider } from "@/components/specific/Form";

type Props = {
  open: boolean;
  onOpenChange: (open: boolean) => void;
  stockItemId: string;
  product: ApiObjects["ProductWithExtraDto"];
};

export const InventoryDetails = memo(InventoryDetailsInner, (a, b) => a.stockItemId === b.stockItemId);

export function InventoryDetailsInner({ stockItemId, product, ...dialogProps }: Props) {
  const stockItemQuery = useStockItems(product.id);
  const stockItem = stockItemQuery.items.find((item) => item.id === stockItemId);
  const saveMutation = useStockItemUpdate(stockItemId);
  const form = useEditStockItemsForm(product.facilityId, product.fields);
  const { t } = useTranslation();

  if (!stockItem) {
    return null;
  }

  return (
    <Sheet {...dialogProps}>
      <SheetContent className="overflow-y-auto h-screen flex flex-col overflow-hidden">
        <SheetHeader>
          <SheetTitle>
            {product.name} - {stockItem.identifier}
          </SheetTitle>
          <SheetDescription>
            {product.name} with identifier {stockItem.identifier}
          </SheetDescription>
        </SheetHeader>
        <Separator className="my-0" />
        <div className="flex-1 overflow-y-auto pt-6 flex flex-col">
          <Line label={t("stockItems_table_header_status", "Status")}>
            <StockItemStatus
              status={stockItem.status}
              from={stockItem.from}
              isChangeable={true}
              stockItemId={stockItem.id}
            />
          </Line>
          <Separator className="my-3" />
          <PulsoFormProvider
            {...form}
            className="flex-1 flex flex-col overflow-y-auto"
            fieldsClassName="overflow-y-auto px-6 pb-6 flex-1"
            buttonWrapClassName="px-6 py-6 __shadow-top z-10 mt-0"
            initialValues={
              fieldToHashZodTransformer({
                ...stockItem,
                from: stockItem.from ? new Date(stockItem.from) : null,
              }) as never
            }
            isLoading={saveMutation.isPending}
            onSubmit={(values) =>
              saveMutation.mutate(
                { ...values, from: values.from ? formatDateTnLocalTimezone(values.from) : null },
                { onSuccess: () => dialogProps.onOpenChange?.(false) }
              )
            }
          />
        </div>
      </SheetContent>
    </Sheet>
  );
}

function Line({ label, children }: PropsWithChildren<{ label: string }>) {
  return (
    <div className="grid px-6" style={{ gridTemplateColumns: "1fr 3fr" }}>
      <div>{label}</div>
      <div>{children}</div>
    </div>
  );
}

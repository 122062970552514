import { useFacilitySettings } from "@/api/useFacilitySettings";
import { Button } from "@/components/ui/button";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "sonner";

export function ScanIdModal(props: {
  facilityId: string;
  onSuccess: (data: { firstname: string; lastname: string; fields?: Record<string, string> }) => void;
}) {
  const { settings } = useFacilitySettings(props.facilityId);
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const callback = useCallback(
    function (e: MessageEvent) {
      if (settings && e.data?.source === "deltaid") {
        setOpen(false);

        if (e.data?.action === "ready") {
          const data = e.data?.data?.documento;

          if (!settings.idScan) {
            return;
          }

          if (!data) {
            toast.error("Scanner error");
          } else {
            data.passport = data.tipo_documento === "P" ? data.numero_passaporte : data.numero_documento;
            data.address = [data.domicilio1, data.domicilio2, data.domicilio3, data.domicilio4]
              .filter(Boolean)
              .join(", ");

            const fields = Object.keys(settings.idScan.fieldsMapping).reduce(
              (obj, fieldId) => ({ ...obj, [`fields_${fieldId}`]: data[settings.idScan.fieldsMapping[fieldId]] }),
              {}
            );

            props.onSuccess({
              firstname: data.nombre,
              lastname: [data.apellido1, data.apellido2].filter(Boolean).join(" "),
              fields,
            });
          }
        }
      }
    },
    [settings?.idScan]
  );

  useEffect(() => {
    window.addEventListener("message", callback);
    return () => window.removeEventListener("message", callback);
  }, [settings?.idScan]);

  if (!settings || !settings.idScan) {
    return null;
  }

  if (settings.idScan.test) {
    return (
      <Button className="mr-3" onClick={() => props.onSuccess({ firstname: "John", lastname: "Smith", fields: {} })}>
        {t("bookings_add_withScan_button", "Scan ID")}
      </Button>
    );
  }

  return (
    <>
      <Button className="mr-3" onClick={() => setOpen(true)}>
        {t("bookings_add_withScan_button", "Scan ID")}
      </Button>
      {open && (
        <div className="fixed left-9 right-9 top-9 bottom-9 z-50">
          <iframe className="w-full h-full" src={settings.idScan?.iframeUrl}></iframe>
        </div>
      )}
    </>
  );
}

import { api } from "@/lib/api-client";
import { useQueryWithPagination } from "@/lib/useQueryWithPagination";
import { ApiObjects } from "@pulso/api-client";

export function useDocumentSignatures(facilityId: string, filter: ApiObjects["DocumentSignaturesFilter"]) {
  const query = useQueryWithPagination({
    queryKey: ["signatures", facilityId, filter.documentIds],
    queryFn: async ({ page, pageSize }) =>
      await api.getDocumentsList({ facilityId, documentIds: filter.documentIds, page, pageSize }),
  });

  return {
    signatures: query.data?.documents || [],
    pagination: { ...query.pagination, total: query.data?.total || 0 },
    isLoading: query.isLoading,
  };
}

import { api } from "@/lib/api-client";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

export function useUserFacilities(userId: string) {
  const query = useQuery({
    queryKey: ["users", "facilities", userId],
    queryFn: async () => await api.getUserFacilities({ userId }),
    placeholderData: keepPreviousData,
  });

  return {
    facilities: query.data || [],
    isLoading: query.isLoading,
  };
}

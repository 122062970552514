"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createWebHttpProvider = createWebHttpProvider;
function createWebHttpProvider(baseUrl, tokenProvider) {
    return function (options) {
        const isWithFiles = typeof options.extraOptions === "object" &&
            options.extraOptions !== null &&
            "withFiles" in options.extraOptions &&
            options.extraOptions.withFiles;
        const isFormData = isWithFiles ||
            (typeof options.extraOptions === "object" &&
                options.extraOptions !== null &&
                "formData" in options.extraOptions &&
                options.extraOptions.formData);
        // Calculate body
        let body = undefined;
        if (isWithFiles) {
            const [newBody, files] = buildBodyWithFiles(options.body);
            body = getFormData(Object.assign(Object.assign({}, files), { data: JSON.stringify(newBody), filenames: JSON.stringify(Object.keys(files).reduce((obj, fileKey) => (Object.assign(Object.assign({}, obj), { [fileKey]: files[fileKey].name })), {})) }));
        }
        else if (isFormData) {
            body = getFormData(options.body);
        }
        else if (typeof options.body === "object") {
            body = JSON.stringify(options.body);
        }
        const params = {
            url: options.url,
            method: options.method.toUpperCase(),
            headers: Object.assign(Object.assign({}, (isFormData ? {} : { "Content-Type": "application/json" })), { Authorization: "Bearer " + tokenProvider() }),
            body,
        };
        return fetch(baseUrl + options.url, params).then((r) => {
            return resolveBody().then((body) => r.status < 400 ? body : Promise.reject({ body, status: r.status, headers: r.headers }));
            function resolveBody() {
                var _a;
                if ((_a = r.headers.get("Content-Type")) === null || _a === void 0 ? void 0 : _a.startsWith("application/json")) {
                    if (r.headers.get("Content-Length") === "0") {
                        return Promise.resolve();
                    }
                    return r.json();
                }
                if (r.headers.get("Content-Type") === "application/pdf") {
                    return r.arrayBuffer();
                }
                return r.text();
            }
        });
        function getFormData(data) {
            const body = new FormData();
            if (typeof data === "object" && data) {
                for (const [key, val] of Object.entries(data)) {
                    if (typeof val !== "undefined") {
                        body.append(key, val);
                    }
                }
            }
            return body;
        }
        function buildBodyWithFiles(body) {
            if (Array.isArray(body)) {
                let files = {};
                const p = body.map((item) => {
                    const [newItem, fs] = buildBodyWithFiles(item);
                    files = Object.assign(Object.assign({}, files), fs);
                    return newItem;
                });
                return [p, files];
            }
            else if (typeof body === "object") {
                if (body === null) {
                    return [null, {}];
                }
                let files = {};
                const newBody = Object.keys(body).reduce((obj, key) => {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    const value = body[key];
                    if (value instanceof File) {
                        const id = Math.ceil(Math.random() * 10000000) + "";
                        files[id] = value;
                        return Object.assign(Object.assign({}, obj), { [key]: id });
                    }
                    const [newValue, newFiles] = buildBodyWithFiles(value);
                    files = Object.assign(Object.assign({}, files), newFiles);
                    return Object.assign(Object.assign({}, obj), { [key]: newValue });
                }, {});
                return [newBody, files];
            }
            return [body, {}];
        }
    };
}

import { api } from "@/lib/api-client";
import { useAuth } from "@/lib/useAuth";
import { useInfiniteScrollQuery } from "@/lib/useInfiniteScrollQuery";
import { ApiObjects } from "@pulso/api-client";

export function useBookings(filter: ApiObjects["BookingsQuery"]) {
  const auth = useAuth();

  const query = useInfiniteScrollQuery({
    queryKey: ["bookings", auth.facility?.id, filter],
    queryFn: async (context) => {
      if (!auth.facility?.id) {
        return {
          items: [] as ApiObjects["BookingDto"][],
          page: context.pageParam,
          pageSize: filter.pageSize || 25,
          total: 0,
        };
      }

      const bookings = await api.getBookings({
        ...filter,
        facilityId: auth.facility.id,
        page: context.pageParam,
      });

      return {
        items: bookings.bookings,
        page: bookings.page,
        total: bookings.total,
        pageSize: bookings.pageSize,
      };
    },
    staleTime: 60000,
  });

  return query;
}

import { useProductUpdatePackageProducts } from "@/api/useProductUpdatePackageProducts";
import { useProductsWithVariants } from "@/api/useProductsWithVariants";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { zodResolver } from "@hookform/resolvers/zod";
import { ApiObjects } from "@pulso/api-client";
import { SelectNative } from "@pulso/components/lib/SelectNative";
import flatten from "lodash/flatten";
import { Trash2 } from "lucide-react";
import { useEffect } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { z, ZodIssueCode } from "zod";

type PackageProductsFormProps = {
  facilityId: string;
  product: ApiObjects["ProductWithExtraDto"];
};

export function PackageProductsForm(props: PackageProductsFormProps) {
  const { t } = useTranslation();

  const { products } = useProductsWithVariants(props.facilityId);
  const saveMutation = useProductUpdatePackageProducts(props.product.id);

  const productIdproductVariantIdPairs = props.product.packageVariants.map((p) => ({
    productIdproductVariantIdPair: `${p.productId}_${p.productVariantId}`,
    count: p.count,
  }));

  const options = flatten(
    products
      .filter((p) => !p.isPackage)
      .map((product) =>
        product.variants.map((variant) => ({
          label: variant.variantName === "default" ? product.name : `${product.name} - ${variant.variantName}`,
          value: `${product.id}_${variant.id}`,
        }))
      )
  ).concat([{ label: t("common_select_placeholder", "Select ..."), value: "" }]);

  const formSchema = z
    .object({
      products: z.array(
        z.object({
          productIdproductVariantIdPair: z.string().min(1),
          count: z.number().min(1).max(20),
        })
      ),
    })
    .superRefine(({ products }, ctx) => {
      for (let i = 0; i < products.length - 1; i++) {
        for (let j = i + 1; j < products.length; j++) {
          if (products[i].productIdproductVariantIdPair === products[j].productIdproductVariantIdPair && i !== j) {
            ctx.addIssue({
              path: [`products.${i}.productIdproductVariantIdPair`],
              code: ZodIssueCode.custom,
              message: t(
                "products_packageProducts_form_errors_duplicateProduct",
                "Only one product of a type is allowed"
              ),
            });
          }
        }
      }
    });

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: { products: productIdproductVariantIdPairs || [] },
  });

  const { fields, append, remove } = useFieldArray({ control: form.control, name: "products" });

  useEffect(() => {
    form.setValue("products", productIdproductVariantIdPairs);
  }, [props.product, products]);

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit((values) =>
          saveMutation.mutate({
            packageProducts: values.products.map((v) => {
              const [productId, productVariantId] = v.productIdproductVariantIdPair.split("_");
              return { productId, productVariantId, count: v.count };
            }),
          })
        )}
      >
        {fields.length > 0 && (
          <div className="flex flex-col gap-3 appearance-none">
            <div className="flex gap-3 text-sm">
              <label className="w-96">{t("products_packageProducts_form_labels_products", "Products")}</label>
              <label>{t("products_packageProducts_form_labels_quantity", "Quantity")}</label>
            </div>
            {fields.map((field, index) => (
              <div key={field.id} className="flex gap-3">
                <div>
                  <FormField
                    name={`products.${index}.productIdproductVariantIdPair`}
                    control={form.control}
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <SelectNative className="w-96" value={field.value} onChange={field.onChange}>
                            {options.map((opt) => (
                              <option key={opt.value} value={opt.value}>
                                {opt.label}
                              </option>
                            ))}
                          </SelectNative>
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>

                <div>
                  <FormField
                    control={form.control}
                    name={`products.${index}.count`}
                    render={({ field }) => (
                      <FormItem>
                        <FormControl>
                          <Input
                            {...field}
                            type="number"
                            className="w-24"
                            min={1}
                            max={20}
                            onChange={(e) => field.onChange(parseInt(e.target.value))}
                          />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div>
                  <Button variant="ghost" onClick={() => remove(index)}>
                    <Trash2 size={16} strokeWidth={1.2} />
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}
        {fields.length < options.length - 1 && (
          <div className="mt-3">
            <Button
              type="button"
              variant="outline"
              onClick={() => append({ productIdproductVariantIdPair: "", count: 1 })}
            >
              {t("products_packageProducts_form_addProduct_button", "Add product")}
            </Button>
          </div>
        )}
        <Button className="mt-6" type="submit">
          {t("common_button_save")}
        </Button>
      </form>
    </Form>
  );
}

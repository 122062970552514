import { PulsoFormProvider, createForm } from "@/components/specific/Form";
import { ApiObjects } from "@pulso/api-client";
import { useTranslation } from "react-i18next";
import { z } from "zod";

export type FormData = {
  name: string;
};

export type LocationFormProps = {
  locations: ApiObjects["LocationDto"][];
  initialData: FormData;
  onSubmit: (data: FormData) => Promise<unknown>;
};

export function LocationForm(props: LocationFormProps) {
  const { t } = useTranslation();
  const formSchema = z.object({
    name: z.string().min(1).max(40),
  });

  const form = createForm(
    {
      name: {
        label: t("settings_locations_form_label_name", "Name"),
        type: "string" as const,
      },
    },
    formSchema
  );

  return <PulsoFormProvider {...form} onSubmit={props.onSubmit} initialValues={props.initialData} />;
}

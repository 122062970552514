import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";
import { ApiObjects } from "@pulso/api-client";

export function useFastTrackSettingsPatch(facilityId: string) {
  return useMutation({
    mutationFn: async (data: ApiObjects["PatchFacilityFastTrackSettingsBody"]) => {
      await api.patchFacilityFastTrackSettings({ facilityId }, data, { withFiles: true });
    },
    invalidateKeys: [["fast-track-settings"], ["products"]],
    successMessage: "SAVE_SUCCESS",
  });
}

import { api } from "@/lib/api-client";
import { useQuery } from "@tanstack/react-query";

export function useBookingDocumentsSignUrl(bookingId: string) {
  const query = useQuery({
    queryKey: ["bookings", "booking", bookingId, "sign-url"],
    queryFn: () => api.getSignBookingDocumentsUrl({ bookingId }),
  });

  return {
    url: query.data?.url || null,
    isLoading: query.isLoading,
  };
}

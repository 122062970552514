import { analytics } from "@/lib/analytics/analytics";
import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";

export function useBookingConfirm(bookingId: string) {
  return useMutation({
    mutationFn: async () => {
      await api.confirmBooking({ bookingId }, undefined);
    },
    invalidateKeys: [["bookings"]],
    successMessage: "SAVE_SUCCESS",
    onSuccess: () => {
      analytics.trackEvent("booking_confirmation", { bookingId });
    },
  });
}

import { ApiObjects } from "@pulso/api-client";
import { formatDateTnLocalTimezone } from "@pulso/utils";
import { addDays, endOfDay } from "date-fns";
import { atom, useAtom, useAtomValue } from "jotai";
import { atomWithStorage } from "jotai/utils";

export type BookingsFilter = ReturnType<typeof useBookingsFilter>;

const now = new Date();
const rangeAtom = atom(6);
const dateAtom = atomWithStorage("pulso-bookings-filter-date", formatDateTnLocalTimezone(now)); // start of the day
const dateToAtom = atomWithStorage("pulso-bookings-filter-date-to", formatDateTnLocalTimezone(now));
const searchTermAtom = atom("");
const statusAtom = atomWithStorage<Record<ApiObjects["BookingDto"]["status"], boolean>>(
  "pulso-bookings-filter-status",
  {
    UNCONFIRMED: true,
    PENDING: true,
    IN_PROGRESS: true,
    COMPLETED: true,
    ON_HOLD: false,
    CANCELLED: false,
  }
);
const pageAtom = atom(1);

export function useBookingsFilter() {
  const [date, setDate] = useAtom(dateAtom);
  const [dateTo, setDateTo] = useAtom(dateToAtom);
  const range = useAtomValue(rangeAtom);
  const [searchTerm, setSearchTerm] = useAtom(searchTermAtom);
  const [statuses, setStatuses] = useAtom(statusAtom);
  const [page, setPage] = useAtom(pageAtom);
  const pageSize = 25;

  function toggleStatus(status: ApiObjects["BookingDto"]["status"]) {
    setStatuses({ ...statuses, [status]: !statuses[status] });
  }

  function forApi(page: number) {
    const status = Object.entries(statuses)
      .filter(([key, value]) => value)
      .map(([key, value]) => key)
      .join(",");

    return {
      q: searchTerm || "",
      from: date,
      to: dateTo ? formatDateTnLocalTimezone(endOfDay(dateTo)) : "",
      ...(status ? { status } : {}),
      page,
      pageSize,
    };
  }

  return {
    date,
    dateTo,
    range,
    searchTerm,
    statuses,
    page,
    pageSize,

    setDate,
    setDateTo,
    setSearchTerm,
    toggleStatus,
    setPage,

    prevDate: () => setDate(formatDateTnLocalTimezone(addDays(date, -1))),
    nextDate: () => setDate(formatDateTnLocalTimezone(addDays(date, 1))),
    prevPage: () => setPage(page - 1),
    nextPage: () => setPage(page + 1),
    nextRange: () => setDate(formatDateTnLocalTimezone(addDays(date, range + 1))),
    prevRange: () => setDate(formatDateTnLocalTimezone(addDays(date, -range - 1))),

    forApi,
  };
}

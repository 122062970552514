import { cn } from "@/lib/utils";
import { Button } from "./button";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { CalendarIcon, ChevronDown, Trash2 } from "lucide-react";
import { PropsWithChildren, forwardRef, useState } from "react";
import { t } from "i18next";
import { es } from "date-fns/locale";
import { DateRange } from "react-day-picker";
import { CalendarMulti } from "@pulso/components/lib/CalendarMulti";
import { Calendar, CalendarProps } from "@pulso/components/lib/Calendar";
import { formatDate } from "@pulso/utils";

export type DatePickerProps = PropsWithChildren<
  CalendarProps & { hideClear?: boolean } & (
      | {
          mode: "single";
          label?: (date: Date) => string;
        }
      | {
          mode: "range";
          label?: (date: DateRange) => string;
        }
    )
>;
export const DatePicker = forwardRef<HTMLDivElement, DatePickerProps>((props, ref) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="flex items-center" ref={ref}>
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant={"outline"}
            className={cn(
              "justify-start text-left font-normal",
              !props.selected && "text-muted-foreground",
              props.mode === "range" && "w-64",
              props.className
            )}
          >
            <span>
              <CalendarIcon className="mr-2" size={16} />
            </span>
            {props.selected ? (
              <span>
                {props.label
                  ? /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
                    props.label(props.selected as any)
                  : formatDate(props.selected as Date, null, undefined, "date")}
              </span>
            ) : (
              <span>{t("common_datepicker_pickDate", "Pick a date")}</span>
            )}
            <span className="ml-auto pl-3">
              <ChevronDown size={16} strokeWidth={1.1} />
            </span>
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0" align="start">
          {props.mode === "range" ? (
            <CalendarMulti {...props} locale={es} {...es.options} />
          ) : (
            <Calendar {...props} locale={es} {...es.options} />
          )}
          {props.children}
        </PopoverContent>
      </Popover>
      {props.selected && !props.hideClear && (
        <Button variant="ghost" type="button" onClick={(e) => props.onSelect?.(undefined, new Date(), {}, e)}>
          <Trash2 size={16} strokeWidth={1.2} />
        </Button>
      )}
    </div>
  );
});

DatePicker.displayName = "PeriodInput";

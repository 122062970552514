import { forwardRef, useEffect, useState } from "react";

import { cn } from "@/lib/utils";

export interface PriceInputProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, "defaultValue" | "step" | "onChange"> {
  onChange: (value: number | undefined) => void;
  sign?: string;
  inputClassName?: string;
  invalid?: boolean;
}

const formatter = Intl.NumberFormat(undefined, {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  useGrouping: false,
});

const SIGNS: Record<string, string> = {
  AED: "AED",
  EUR: "€",
  USD: "$",
};

const PriceInput = forwardRef<HTMLInputElement, PriceInputProps>(
  ({ className, inputClassName, type, sign: signParam, invalid, ...props }, ref) => {
    const sign = signParam || "EUR";
    const signStr = SIGNS[sign] || sign;
    const isShort = signStr.length === 1;
    const [outsideValue, setOutsideValue] = useState<string | number | readonly string[] | undefined>(
      props.value || ""
    );
    const formattedValue =
      typeof props.value === "number" ? formatter.format(props.value).replace(",", ".") : props.value || "";
    const [val, setVal] = useState(formattedValue);

    useEffect(() => {
      if (outsideValue !== undefined && outsideValue !== props.value) {
        setVal(formattedValue);
      }
    }, [outsideValue, props.value, formattedValue]);

    return (
      <div
        className={cn(
          "relative flex h-9 w-full rounded-md text-sm shadow-sm transition-colors",
          className,
          invalid && "bg-destructive/10"
        )}
        ref={ref}
      >
        <span
          className={cn(
            "flex-shrink-0 flex justify-center items-center cursor-pointer absolute left-[1px] top-[1px] bottom-[1px] rounded-tl-sm rounded-bl-sm",
            isShort ? "w-[34px]" : "w-[42px]",
            invalid ? "bg-destructive/10" : "bg-gray-100",
            props.disabled && "text-muted-foreground"
          )}
        >
          {signStr}
        </span>
        <input
          {...props}
          className={cn(
            "w-full h-full rounded-tr-md border border-input rounded-br-md px-3 py-1 no-number-controls rounded-tl-md rounded-bl-md z-10 bg-transparent placeholder:text-muted-foreground focus-visible:outline-none focus-visible:border-gray-700 disabled:cursor-not-allowed disabled:opacity-50",
            isShort ? "pl-[46px]" : "pl-[54px]",
            inputClassName,
            invalid && "border-destructive"
          )}
          type="number"
          value={val}
          step="0.01"
          disabled={props.disabled}
          onChange={(e) => {
            setVal(e.target.value);
            const val = parseFloat(e.target.value);
            if (typeof val === "number" && !Number.isNaN(val)) {
              setOutsideValue(val);
              props.onChange(val);
            } else {
              setOutsideValue(undefined);
              props.onChange(undefined);
            }
          }}
          onScroll={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
        />
      </div>
    );
  }
);
PriceInput.displayName = "PriceInput";

export { PriceInput };

import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";
import { ApiObjects } from "@pulso/api-client";

export function useStoreSettingsPatch(facilityId: string) {
  return useMutation({
    mutationFn: async (data: ApiObjects["PatchStoreSettingsBody"]) => {
      return await api.patchStoreSettings({ facilityId }, data);
    },
    invalidateKeys: [["store-settings"]],
    successMessage: "SAVE_SUCCESS",
  });
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fieldsToHash = fieldsToHash;
exports.hashToVariantId = hashToVariantId;
exports.fieldsToVariantId = fieldsToVariantId;
exports.generateVariants = generateVariants;
exports.createVariantsPermutations = createVariantsPermutations;
const crypto_js_1 = require("crypto-js");
function fieldsToHash(fields) {
    if (fields.length === 0) {
        return "default";
    }
    const hash = fields
        .map((f) => f.value)
        .sort()
        .join("_");
    return hash;
}
function hashToVariantId(productId, hash) {
    return (0, crypto_js_1.MD5)(productId + "_" + hash).toString();
}
function fieldsToVariantId(productId, fields) {
    return hashToVariantId(productId, fieldsToHash(fields));
}
function generateVariants(productId, fields) {
    const permutations = createVariantsPermutations(fields.map((field) => field.options.map((o) => (Object.assign(Object.assign({}, o), { fieldId: field.id }))) || []));
    const variants = permutations.map((variant) => {
        const hash = fieldsToHash(variant.map((v) => ({ value: v.id })));
        return {
            id: hashToVariantId(productId, hash),
            label: variant.map((o) => o.value).join(" / "),
            optionValues: variant.map((o) => o.value),
            fields: variant,
            hash,
        };
    });
    if (permutations.length === 1 && permutations[0].length === 0) {
        return [
            {
                id: hashToVariantId(productId, "default"),
                label: "Default",
                optionValues: [],
                fields: [],
                hash: "default",
            },
        ];
    }
    return variants;
}
function createVariantsPermutations(arrays) {
    return arrays.reduce((acc, current) => {
        const result = [];
        acc.forEach((accItem) => {
            current.forEach((currItem) => {
                result.push([...accItem, currItem]);
            });
        });
        return result;
    }, [[]]);
}

import { cn } from "./utils";
import "./assets/flags.css";

export function LanguageFlagSprite(props: {
  lang: string | undefined;
  size?: "sm" | "md" | "lg" | "xl";
  className?: string;
}) {
  return (
    <span
      className={cn(
        "fflag",
        "fflag-" + (typeof props.lang === "string" ? props.lang : "").toUpperCase(),
        "ff-" + (props.size || "sm"),
        props.className
      )}
    />
  );
}

import { useEffect } from "react";
import { useAuth } from "./useAuth";
import { useLocation } from "react-router-dom";

export function usePageTitle() {
  const auth = useAuth();
  const location = useLocation();

  useEffect(() => {
    const parts = ["Pulso"];

    if (auth.facility) {
      parts.unshift(auth.facility.organization.name, auth.facility.name);
    }

    if (location.state) {
      parts.unshift(location.state);
    }

    window.document.title = parts.join(" - ");
  }, [auth.facility, location]);
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLanguageNamesMap = getLanguageNamesMap;
exports.translateLanguage = translateLanguage;
function getLanguageNamesMap(t) {
    return {
        en: t("common_english", "English"),
        es: t("common_spanish", "Spanish"),
        de: t("common_german", "German"),
        fr: t("common_french", "French"),
        it: t("common_italian", "Italian"),
    };
}
function translateLanguage(t, lang) {
    const map = getLanguageNamesMap(t);
    return map[lang] || "";
}

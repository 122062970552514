import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";
import { ApiObjects } from "@pulso/api-client";

export function useBookingItemCreate(bookingId: string) {
  return useMutation({
    mutationFn: async ({
      periodId,
      productId,
      fields,
    }: {
      periodId: string;
      productId: string;
      fields: { fieldId: string; value: string | null }[];
    }) => {
      const fieldsToSubmit: ApiObjects["AddItemToBookingBody"]["fields"] = [];
      for (const field of fields) {
        if (field.value !== null) {
          fieldsToSubmit.push({ fieldId: field.fieldId, value: field.value });
        }
      }

      await api.addBookingItem(
        { bookingId },
        {
          fields: fieldsToSubmit,
          productId,
          periodId,
        }
      );
    },
    invalidateKeys: [["bookings"]],
  });
}

import { api } from "@/lib/api-client";
import { ApiObjects } from "@pulso/api-client";
import { useMutation } from "@/lib/useMutation";

export function useCustomerUpdate(customerId: string) {
  return useMutation({
    mutationFn: async (data: ApiObjects["UpdateCustomerBody"]) => {
      await api.updateCustomer({ customerId }, data, { withFiles: true });
    },
    invalidateKeys: [["customers"], ["bookings"]],
  });
}

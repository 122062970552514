import { MouseEvent, PropsWithChildren, useState } from "react";
import {
  Dialog,
  DialogHeader,
  DialogTrigger,
  DialogContent,
  DialogTitle,
  DialogDescription,
  DialogFooter,
} from "./dialog";
import { Button, ButtonProps } from "./button";
import { useTranslation } from "react-i18next";

type ConfirmDialogProps = PropsWithChildren<{
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  title: string;
  description: string;
  okButtonText?: string;
  cancelButtonText?: string;
  okButtonVariant?: ButtonProps["variant"];
  cancelButtonVariant?: ButtonProps["variant"];
  onContinue: () => void;
  onCancel?: () => void;
}>;
export function ConfirmDialog(props: ConfirmDialogProps) {
  const [open, setOpen] = useState("open" in props ? props.open : false);
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onOpenChange={(o) => {
        setOpen(o);
        props.onOpenChange?.(o);
      }}
    >
      <DialogTrigger asChild>{props.children}</DialogTrigger>
      <DialogContent noFullScreen hideFooter onClick={(e) => e.stopPropagation()}>
        <DialogHeader>
          <DialogTitle>{props.title}</DialogTitle>
          <DialogDescription>{props.description}</DialogDescription>
        </DialogHeader>
        <DialogFooter className="flex flex-col-reverse md:flex-row">
          <Button
            variant={props.cancelButtonVariant || "secondary"}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              props.onCancel?.();
              setOpen(false);
              props.onOpenChange?.(false);
            }}
            className="mt-3 md:mt-0"
          >
            {props.cancelButtonText || t("common_button_cancel")}
          </Button>
          <Button
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();

              props.onContinue();
              setOpen(false);
              props.onOpenChange?.(false);
            }}
            variant={props.okButtonVariant || "default"}
          >
            {props.okButtonText ?? t("common_button_ok", "OK")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

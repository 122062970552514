type ImagePreviewProps = {
  url: string | null;
  name?: string;
};

export function ImagePreview({ url, name }: ImagePreviewProps) {
  const format = getFormat(url) || getFormat(name) || "FILE";

  if (!url) {
    return <span className="overflow-hidden text-ellipsis break-all">{name}</span>;
  }

  return (
    <a href={url} target="_blank" rel="noreferrer" className="external-link flex items-center">
      {format === "IMG" && <img src={url} alt="" className="h-10 rounded mr-2 flex-shrink-0" />}
      {format === "PDF" && (
        <div className="h-8 w-10 rounded mr-2 bg-gray-500 flex items-center justify-center text-center text-white font-bold flex-shrink-0">
          PDF
        </div>
      )}
      <span className="overflow-hidden text-ellipsis break-all">{name}</span>
    </a>
  );
}

function getFormat(name?: string | null) {
  const formatMatch = name?.toLowerCase().match(/\.(gif|png|jpg|jpeg|pdf)$/);
  if (formatMatch) {
    return formatMatch[1] === "pdf" ? "PDF" : "IMG";
  }
  return null;
}

import { api } from "@/lib/api-client";
import { useInfiniteScrollQuery } from "@/lib/useInfiniteScrollQuery";
import { ApiObjects } from "@pulso/api-client";
import { keepPreviousData } from "@tanstack/react-query";

export function useBookingAvailableStockItems(bookingItemId: string | undefined, q: string) {
  const pageSize = 25;
  const query = useInfiniteScrollQuery({
    queryKey: ["stock-items", bookingItemId, q],
    queryFn: async (context) => {
      if (!bookingItemId) {
        return {
          items: [] as ApiObjects["StockItemDto"][],
          pageSize,
          page: 1,
          total: 0,
        };
      }

      return await api.getApplicableStockItemsforBookingItem({
        bookingItemId,
        page: context.pageParam,
        pageSize,
        query: q,
      });
    },
    staleTime: 1,
    placeholderData: keepPreviousData,
  });

  return query;
}

import { ApiObjects } from "@pulso/api-client";
import { t, TFunction } from "i18next";
import { cn } from "@/lib/utils";
import { PropsWithChildren } from "react";
import { ArrowDown, Check, Ellipsis, LucideIcon, PauseCircle, ShieldAlert, X } from "lucide-react";
import { Spinner } from "../ui/spinner";

export const BOOKING_STATUS_CLASS_NAME: Record<ApiObjects["BookingDto"]["status"], string> = {
  UNCONFIRMED: "bg-yellow-100 text-yellow-800 hover:bg-yellow-200 hover:text-yellow-900",
  PENDING: "bg-green-100 text-green-800 hover:bg-green-200 hover:text-green-900",
  IN_PROGRESS: "bg-blue-100 text-blue-800  hover:bg-blue-200 hover:text-blue-900",
  COMPLETED: "bg-gray-100 text-gray-800 hover:bg-gray-200 hover:text-gray-900",
  CANCELLED: "bg-zinc-700 text-white hover:bg-zinc-500 hover:text-white",
  ON_HOLD: "bg-orange-200 text-orange-800 hover:bg-orange-300 hover:text-orange-900",
};

export function getBookingStatusLabels(t: TFunction) {
  return {
    UNCONFIRMED: t("bookings_status_unconfirmed", "Review"),
    PENDING: t("bookings_status_pending"),
    IN_PROGRESS: t("bookings_status_inProgress"),
    COMPLETED: t("bookings_status_completed", "Done"),
    CANCELLED: t("bookings_status_cancelled", "Cancelled"),
    ON_HOLD: t("bookings_status_onHold", "On Hold"),
  };
}

export const BOOKING_STATUS_ICON: Record<ApiObjects["BookingDto"]["status"], LucideIcon> = {
  UNCONFIRMED: ShieldAlert,
  PENDING: ArrowDown,
  IN_PROGRESS: Ellipsis,
  COMPLETED: Check,
  CANCELLED: X,
  ON_HOLD: PauseCircle,
};

export function BookingStatus({
  status,
  ...props
}: PropsWithChildren<{ status: ApiObjects["BookingDto"]["status"]; className?: string; isLoading?: boolean }>) {
  const STATUS_LABELS: Record<ApiObjects["BookingDto"]["status"], string> = getBookingStatusLabels(t);
  const Icon = BOOKING_STATUS_ICON[status];
  return (
    <div
      className={cn(
        "rounded-full flex items-center px-3 py-2 gap-1",
        BOOKING_STATUS_CLASS_NAME[status],
        props.isLoading && "opacity-50",
        props.className
      )}
    >
      {!props.isLoading ? <Icon size={16} className="flex-shrink-0" /> : <Spinner size={16} />}
      <span className="text-xs text-nowrap">{STATUS_LABELS[status]}</span>
    </div>
  );
}

import { api } from "@/lib/api-client";
import { useAuth } from "@/lib/useAuth";
import { useQuery } from "@tanstack/react-query";

export function useProducts() {
  const auth = useAuth();

  return useQuery({
    queryKey: ["products", auth.facility?.id],
    queryFn: async () => {
      if (auth.facility) {
        return api.getAllProductsForFacility({
          facilityId: auth.facility.id,
        });
      }
      return [];
    },
  });
}

import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";
import { useTranslation } from "react-i18next";

export function useBookingSendFastTrackLink() {
  const { t } = useTranslation();

  return useMutation({
    mutationFn: async (bookingId: string) => {
      await api.sendBookingFastTrackLink({ bookingId }, undefined);
    },
    invalidateKeys: [["bookings"]],
    successMessage: t("booking_sendFastTrackLink_success", "Fast Track link sent successfully"),
  });
}

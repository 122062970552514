import { Info } from "lucide-react";
import { TooltipSimple } from "../ui/tooltip";

export function InfoBox({ text }: { text: string }) {
  return (
    <TooltipSimple text={text}>
      <Info size={18} strokeWidth={2} />
    </TooltipSimple>
  );
}

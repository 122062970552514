import { useFacilityFastTrackSettings } from "@/api/useFastTrackSettings";
import { useFastTrackSettingsPatch } from "@/api/useFastTrackSettingsPatch";
import { PulsoFormProvider, createForm } from "@/components/specific/Form";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { CentralSpinner } from "@/components/ui/central-spinner";
import { withFacilityIdParam } from "@/lib/withParams";
import { zodFile } from "@pulso/components/utils/zodFile";
import { useTranslation } from "react-i18next";
import QRCode from "react-qr-code";
import { z } from "zod";
import { SendFastTrackLinkModal } from "./SendFastTrackLinkModal";
import { useProducts } from "@/api/useProducts";

export const FastTrackSettingsPage = withFacilityIdParam((params) => {
  const { t } = useTranslation();
  const { settings, isLoading } = useFacilityFastTrackSettings(params.facilityId);
  const { data: products } = useProducts();
  const productsForFastTrack = (products || []).filter((p) => p.showInFastTrack).map((p) => p.id);
  const patchSettings = useFastTrackSettingsPatch(params.facilityId);

  const settingsFormSchema = z.object({
    enabled: z.boolean(),
    welcomeText: z.string().min(1).max(10000),
    confirmationText: z.string().min(1).max(10000),
    welcomePicture: zodFile(t, { optional: true, allowedTypes: ["gif", "jpeg", "jpg", "png"] }),
    enableProducts: z.boolean(),
    enableDates: z.boolean(),
    enableSignature: z.boolean(),
    bookingStatus: z.enum(["UNCONFIRMED", "PENDING", "IN_PROGRESS", "COMPLETED", "CANCELLED", "ON_HOLD"]),
    products: z.array(z.string()),
  });

  const settingsForm = createForm(
    {
      enabled: {
        type: "switch",
        label: t(
          "settings_fastTrack_form_enabled_label",
          "Allow customers to pre-register their bookings via Fast Track"
        ),
        placeholder: t("settings_fastTrack_form_enabled_placeholder", "Enable fast track"),
      },
      welcomeText: {
        type: "text",
        label: t("settings_fastTrack_form_enabled_welcomeText", "Welcome Text"),
        hideIf: () => true,
      },
      confirmationText: {
        type: "text",
        label: t("settings_fastTrack_form_enabled_confirmationText", "Confirmation Text"),
        hideIf: () => true,
      },
      welcomePicture: { type: "image", label: t("settings_fastTrack_form_enabled_welcomePicture", "Welcome Picture") },
      enableProducts: {
        type: "switch",
        label: t("settings_fastTrack_form_enableProducts_label", "Enable product selection"),
        placeholder: t(
          "settings_fastTrack_form_enableProducts_placeholder",
          "Allow clients to choose the products they wish to book"
        ),
      },
      products: {
        type: "multi",
        label: t("settings_fastTrack_form_products_label", "Choose the products shown in the Fast Track"),
        options: (products || []).map((p) => ({ label: p.name, value: p.id })),
        hideIf: (s) => !s.enableProducts,
      },
      enableDates: {
        type: "switch",
        label: t("settings_fastTrack_form_enableDates_label", "Enable dates selection"),
        placeholder: t(
          "settings_fastTrack_form_enableDates_placeholder",
          "Allow clients to choose the start day of their bookings"
        ),
      },
      enableSignature: {
        type: "switch",
        label: t("settings_fastTrack_form_enableSignature_label", "Enable signature selection"),
        placeholder: t(
          "settings_fastTrack_form_enableSignature_placeholder",
          "Allow clinets to sign the necessary contracts during the Fast Track"
        ),
      },
      bookingStatus: {
        type: "select",
        label: t(
          "settings_fastTrack_form_bookingStatus_label",
          "Initial status of the bookings created via Fast Track"
        ),
        className: "w-96",
        options: [
          {
            value: "UNCONFIRMED",
            label: t("bookings_status_unconfirmed", "Review"),
          },
          {
            value: "PENDING",
            label: t("bookings_status_pending"),
          },
        ],
      },
    },
    settingsFormSchema
  );

  if (!settings || isLoading) {
    return <CentralSpinner />;
  }

  return (
    <Card>
      <CardContent className="pt-6">
        <div className="grid grid-cols-2 gap-6">
          <div>
            <PulsoFormProvider
              {...settingsForm}
              isLoading={patchSettings.isPending}
              onSubmit={(values) => patchSettings.mutate(values)}
              initialValues={{
                enabled: settings.enabled,
                welcomeText: settings.welcomeText || "",
                confirmationText: settings.confirmationText || "",
                welcomePicture: settings.welcomePicture || "",
                enableProducts: settings.enableProducts,
                enableDates: settings.enableDates,
                enableSignature: settings.enableSignature,
                bookingStatus: settings.bookingStatus,
                products: productsForFastTrack,
              }}
            />
          </div>
          <div>
            {settings.enabled && (
              <>
                <div>
                  <div className="text-lg font-bold">
                    {t("settings_fastTrack_integrate_tablet_title", "Integrate using a tablet")}
                  </div>
                  <div className="mb-3 text-sm">
                    {t(
                      "settings_fastTrack_integrate_tablet_description",
                      "In order to integrate with a tablet, open the following link with your tablet (or scan this QR code) and then bookmark it."
                    )}
                  </div>
                  <a href={settings.permanentUrl} target="_blank">
                    <Button variant="outline">{t("settings_fastTrack_open_button", "Open Fast Track")}</Button>
                  </a>

                  <div className="mt-3">
                    <QRCode value={settings.permanentUrl} size={150} />
                  </div>
                </div>
                <div className="pt-9">
                  <div className="text-lg font-bold">
                    {t("settings_fastTrack_integrate_qr_title", "Integrate using a printed QR code")}
                  </div>
                  <div className="mb-3 text-sm">
                    {t(
                      "settings_fastTrack_integrate_qr_description",
                      "Download the QR code from the button bellow and print it. Then, ask your customers to scan and complete the Fast Track."
                    )}
                  </div>

                  <a href={settings.downloadUrl} target="_blank">
                    <Button variant="outline">{t("settings_fastTrack_download_button", "Download QR code")}</Button>
                  </a>
                </div>
                <div className="pt-9">
                  <div className="text-lg font-bold">
                    {t("settings_fastTrack_integrate_sendLink_title", "Send a self-service link")}
                  </div>
                  <div className="mb-3 text-sm">
                    {t(
                      "settings_fastTrack_integrate_sendLink_description",
                      "Send a self-service link to clients, so they can create their bookings and fill all the necessary data using the Fast Track."
                    )}
                  </div>

                  <SendFastTrackLinkModal facilityId={params.facilityId}>
                    <Button variant="outline">
                      {t("settings_fastTrack_integrate_sendLink_button", "Create a shareable link")}
                    </Button>
                  </SendFastTrackLinkModal>
                </div>
              </>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
});

import { api } from "@/lib/api-client";
import { ApiObjects } from "@pulso/api-client";
import { useMutation } from "@/lib/useMutation";

export function useProductPricesUpdate(productId: string) {
  return useMutation({
    mutationFn: async (data: ApiObjects["PricesUpdateBody"]) => {
      await api.updatePrices(
        { productId },
        {
          variantPrices: data.variantPrices,
        }
      );
    },
    invalidateKeys: [["bookings"], ["stock-items"], ["products"]],
    successMessage: "SAVE_SUCCESS",
  });
}

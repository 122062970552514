import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";
import { ApiObjects } from "@pulso/api-client";

export function useStockItemUpdate(stockItemId: string) {
  return useMutation({
    mutationFn: async (data: ApiObjects["UpdateStockItemBody"]) => {
      await api.updateStockItem({ stockItemId }, data);
    },
    invalidateKeys: [["stock-items"], ["bookings"]],
    successMessage: "SAVE_SUCCESS",
  });
}

import { TimePicker } from "@/components/ui/time-picker";
import { forwardRef } from "react";

type TimeRange = { from: number; to: number };
type TimeRangeInputProps = {
  value: TimeRange;
  onChange: (value: TimeRange) => void;
  disabled?: boolean;
};

export const TimeRangeInput = forwardRef((props: TimeRangeInputProps, ref: React.ForwardedRef<HTMLDivElement>) => {
  if (typeof props.value !== "object") {
    return null;
  }

  return (
    <div ref={ref} className="flex space-x-3">
      <TimePicker
        value={props.value.from}
        max={1424}
        onChange={(from) => {
          if (from > props.value.to) {
            props.onChange({ ...props.value, from, to: from });
          } else {
            props.onChange({ ...props.value, from, to: props.value.to });
          }
        }}
        disabled={props.disabled}
      />
      <TimePicker
        min={props.value.from + 1}
        value={props.value.to}
        onChange={(to) => props.onChange({ ...props.value, from: props.value.from, to })}
        disabled={props.disabled}
      />
    </div>
  );
});

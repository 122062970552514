import { api } from "@/lib/api-client";
import { useQuery } from "@tanstack/react-query";

export function useBookingFastTrackLink(bookingId: string | undefined, enabled: boolean) {
  const query = useQuery({
    queryKey: ["bookings", "booking", bookingId, "fast-track-link"],
    queryFn: () =>
      bookingId ? api.getFastTrackLinkForBooking({ bookingId }).catch((e) => Promise.reject(e.body)) : null,
    enabled: Boolean(bookingId && enabled),
    retry: false,
    staleTime: 300_000, // 5  mintues
  });

  return {
    url: query.data?.url || null,
    isLoading: query.isLoading,
    error: query.error,
  };
}

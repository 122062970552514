import {
  DefaultError,
  QueryClient,
  useInfiniteQuery,
  QueryKey,
  InfiniteData,
  UndefinedInitialDataInfiniteOptions,
  FetchNextPageOptions,
  InfiniteQueryObserverResult,
} from "@tanstack/react-query";

export function useInfiniteScrollQuery<
  TD,
  TQueryFnData extends { items: TD[]; pageSize: number; page: number; total: number },
  TError = DefaultError,
  TQueryKey extends QueryKey = QueryKey,
>(
  options: Omit<
    UndefinedInitialDataInfiniteOptions<
      TQueryFnData,
      TError,
      InfiniteData<{ items: TD[]; pageSize: number; page: number; total: number }, number>,
      TQueryKey,
      number
    >,
    "initialPageParam" | "getNextPageParam"
  >,
  queryClient?: QueryClient
): {
  items: TQueryFnData["items"];
  hasMore: boolean;
  isLoading: boolean;
  dataLength: number;
  total: number;
  next: (
    options?: FetchNextPageOptions
  ) => Promise<
    InfiniteQueryObserverResult<
      InfiniteData<{ items: TD[]; pageSize: number; page: number; total: number }, number>,
      TError
    >
  >;
} {
  const query = useInfiniteQuery<
    TQueryFnData,
    TError,
    InfiniteData<{ items: TD[]; pageSize: number; page: number; total: number }, number>,
    TQueryKey,
    number
  >(
    {
      ...options,
      initialPageParam: 1,
      getNextPageParam: (lastPage, allPages, lastPageParam, allPageParams) => {
        if (!lastPage) {
          return null;
        }
        return lastPage.items.length < lastPage.pageSize ? null : lastPageParam + 1;
      },
    },
    queryClient
  );

  const items = query.data?.pages.reduce((arr, page) => [...arr, ...(page ? page.items : [])], [] as TD[]) || [];

  return {
    items,
    total: query.data?.pages[0].total || 0,
    dataLength: items.length,
    hasMore: query.hasNextPage,
    isLoading: query.isLoading,
    next: query.fetchNextPage,
  };
}

import { Info } from "lucide-react";
import { TooltipSimple } from "../ui/tooltip";
import { PropsWithChildren } from "react";

export function InfoBoxWrap({ tooltip, children }: PropsWithChildren<{ tooltip: string }>) {
  return (
    <div className="flex items-center space-x-1">
      <div>{children}</div>
      <TooltipSimple text={tooltip}>
        <Info size={18} strokeWidth={2} />
      </TooltipSimple>
    </div>
  );
}

import { useBookingPatch } from "@/api/useBookingPatch";
import { Button } from "@/components/ui/button";
import { Textarea } from "@/components/ui/textarea";
import { ApiObjects } from "@pulso/api-client";
import { t } from "i18next";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export function BookingDetailsNotes({ booking }: { booking: ApiObjects["BookingDto"] }) {
  const [notes, setNotes] = useState(booking.notes || "");
  const [isEditing, setIsEditing] = useState(false);
  const save = useBookingPatch(booking.id);
  const { t } = useTranslation();

  return (
    <>
      <Button size="sm" variant="link" className="p-0 absolute top-4 right-6" onClick={() => setIsEditing(true)}>
        {t("common_button_edit", "Edit")}
      </Button>

      {!isEditing && (notes ? <pre className="pb-6 font-sans text-sm text-wrap">{notes.trim()}</pre> : null)}

      {isEditing && (
        <div className="pb-6">
          <Textarea
            placeholder={t("bookings_notes_label", "Notes")}
            rows={6}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            style={{ marginLeft: "calc(-0.75rem - 1px)", marginTop: "calc(-0.5rem - 1px)", width: "109%" }}
          />

          <div className="space-x-3">
            <Button className="mt-3" onClick={() => save.mutate({ notes }, { onSuccess: () => setIsEditing(false) })}>
              {t("common_button_save", "Save")}
            </Button>
            <Button variant="outline" className="mt-3" onClick={() => setIsEditing(false)}>
              {t("common_button_cancel", "Cancel")}
            </Button>
          </div>
        </div>
      )}
    </>
  );

  function isSame() {
    return (!booking.notes && !notes) || booking.notes === notes;
  }
}

import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";

export function useUserDelete() {
  return useMutation({
    mutationFn: async (userId: string) => {
      await api.deleteUser({ userId });
    },
    successMessage: "DELETE_SUCCESS",
    invalidateKeys: [["users"]],
  });
}

import { useBookingItemUnassign } from "@/api/useBookingItemUnassign";
import { Notes } from "@/components/specific/Notes";
import { Warning } from "@/components/specific/Warning";
import { Badge } from "@/components/ui/badge";
import { TooltipSimple } from "@/components/ui/tooltip";
import { ApiObjects } from "@pulso/api-client";
import { Cross1Icon } from "@radix-ui/react-icons";
import { useTranslation } from "react-i18next";

type AssignedStockItemsProps = {
  items: ApiObjects["BookingItemDto"][];
  groupUnassigned?: boolean;
  onUnassignedClick?: () => void;
};

export function AssignedStockItems(props: AssignedStockItemsProps) {
  const assignedItems = props.items.filter((i) => i.stockItem);
  const unassignedItems = props.items.filter((i) => !i.stockItem);

  return (
    <div className="w-full -ml-1 -mt-1 flex flex-wrap">
      {assignedItems.map((item) => (
        <div key={item.id} className="ml-1 mt-1">
          {item.stockItem && <AssignedStockItem item={item} editable={item.status !== "COMPLETED"} />}
        </div>
      ))}
      {props.groupUnassigned ? (
        unassignedItems.length ? (
          <div className="ml-1 mt-1">
            <UnassignedGroupedStockItem onClick={() => props.onUnassignedClick?.()} count={unassignedItems.length} />
          </div>
        ) : null
      ) : (
        unassignedItems.map((item) => (
          <div key={item.id} className="ml-1 mt-1">
            <UnassignedStockItem onClick={() => props.onUnassignedClick?.()} />
          </div>
        ))
      )}
    </div>
  );
}

function UnassignedStockItem(props: { onClick: () => void }) {
  const { t } = useTranslation();

  return (
    <div className="opacity-75">
      <Badge variant="dashedWarning" onClick={props.onClick} className="cursor-pointer flex">
        {t("booking_items_unassigned_label", "Assign")}
      </Badge>
    </div>
  );
}

function UnassignedGroupedStockItem(props: { onClick: () => void; count: number }) {
  const { t } = useTranslation();

  return (
    <Badge variant="dashedWarning" onClick={props.onClick} className="cursor-pointer flex">
      {t("booking_items_unassignedGrouped_label", "Assign {{count}} products", { count: props.count })}
    </Badge>
  );
}

function AssignedStockItem({ item, editable }: { item: ApiObjects["BookingItemDto"]; editable: boolean }) {
  const { t } = useTranslation();

  const stockItem = item.stockItem;
  const unassignBookingItemMutation = useBookingItemUnassign();

  if (!stockItem) {
    return null;
  }

  const variant = stockItem.fields
    .filter((f) => !f.priceable)
    .map((f) => f.value)
    .join(" / ");

  return (
    <Badge variant="outline" className="flex flex-col items-start">
      <div className="flex items-center gap-2 w-full">
        <div>
          {stockItem.identifier}
          <span className="font-normal">{variant ? `: ${variant}` : ""}</span>
        </div>
        {!stockItem.notes && (
          <div>
            <Notes notes={stockItem.notes} iconOnly iconSize={14} id={stockItem.id} />
          </div>
        )}
        {item.isDoubleBooked && (
          <TooltipSimple text={t("bookings_items_doubleBooking_tooltip", "This item is double booked")}>
            <Warning size={14} />
          </TooltipSimple>
        )}
        {editable && (
          <Cross1Icon className="ml-auto cursor-pointer" onClick={() => unassignBookingItemMutation.mutate(item.id)} />
        )}
      </div>

      <div>
        {stockItem.notes && (
          <div className="text-xs font-normal">
            <Notes notes={stockItem.notes} id={stockItem.id} iconSize={14} />
          </div>
        )}
      </div>
    </Badge>
  );
}

import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";
import { ApiObjects } from "@pulso/api-client";

export function useFacilityUpdate(facilityId: string | null) {
  return useMutation({
    mutationFn: async (data: ApiObjects["FacilityUpdateBody"]) => {
      if (facilityId) {
        await api.updateFacility({ facilityId }, data);
      }
    },
    invalidateKeys: [],
    successMessage: "SAVE_SUCCESS",
  });
}

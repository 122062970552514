import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";

export function useDashboardViewDelete() {
  return useMutation({
    mutationFn: async (dashboardViewId: string) => {
      await api.deleteDashboardView({ dashboardViewId });
    },
    successMessage: "DELETE_SUCCESS",
    invalidateKeys: [["dashboard-views"]],
  });
}

import { api } from "@/lib/api-client";
import { useQuery } from "@tanstack/react-query";

export function useOpeningHours(facilityId: string | null) {
  const query = useQuery({
    queryKey: ["opening-hours", facilityId],
    queryFn: async () => {
      if (!facilityId) {
        return null;
      }

      return await api.getFacilityOpeningHours({ facilityId });
    },
  });

  return {
    openingHours: query.data || null,
    isLoading: query.isLoading,
  };
}

import { cn } from "@/lib/utils";
import { Spinner } from "./spinner";

export function SplashSpinner({ className }: { className?: string }) {
  return (
    <div className="h-dvh w-dvw flex justify-center items-center">
      <Spinner className={cn("m-9", className)} />
    </div>
  );
}

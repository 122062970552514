import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";

export function useBookingInvoiceItemDelete(bookingId: string) {
  return useMutation({
    mutationFn: async (invoiceItemId: string) => {
      await api.deleteBookingInvoiceItem({ bookingId, invoiceItemId });
    },
    invalidateKeys: [["bookings"]],
  });
}

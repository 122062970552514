import { useTranslation } from "react-i18next";

export function useLanguageLabel() {
  const { t } = useTranslation();

  const labels: Record<string, string> = {
    en: t("common_english", "English"),
    es: t("common_spanish", "Spanish"),
  };

  return labels;
}

import { useOpeningHours } from "@/api/useOpeningHours";
import { Card, CardContent } from "@/components/ui/card";
import { Form, FormField } from "@/components/ui/form";
import { zodResolver } from "@hookform/resolvers/zod";
import { Fragment, PropsWithChildren, useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { z } from "zod";
import { TimeRangeInput } from "./TimeRangeInput";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { useOpeningHoursUpdate } from "@/api/useOpeningHoursUpdate";
import { DatePicker } from "@/components/ui/date-picker";
import { Ampersand, PlusCircle, Trash2Icon, XCircle } from "lucide-react";
import { Switch } from "@/components/ui/switch";
import { formatDateTnLocalTimezone, zonedNow } from "@pulso/utils";
import { Spinner } from "@/components/ui/spinner";
import { withFacility } from "@/lib/withParams";
import { ApiObjects } from "@pulso/api-client";
import { cn } from "@pulso/components/lib/utils";

export const OpeningHoursPage = withFacility(({ facility }) => {
  const { openingHours, isLoading } = useOpeningHours(facility.id);
  const { t } = useTranslation();
  const [hasSeparateOpeningHours, setHasSeparateOpeningHours] = useState(
    Boolean(openingHours && openingHours.days[0].delivery.some((hour) => hour.type === "DELIVERY"))
  );

  useEffect(() => {
    if (openingHours) {
      setHasSeparateOpeningHours(openingHours.days[0].delivery.some((hour) => hour.type === "DELIVERY"));
    }
  }, [openingHours]);

  return (
    <Card>
      <CardContent className="pt-6">
        {isLoading || !openingHours ? (
          <Spinner />
        ) : (
          <div>
            <OpeningHoursForm
              openingHours={openingHours}
              timezone={facility.timezone}
              facilityId={facility.id}
              hasSeparateOpeningHours={hasSeparateOpeningHours}
            >
              <Button
                type="button"
                variant="link"
                className="p-0"
                onClick={() => setHasSeparateOpeningHours((v) => !v)}
              >
                {hasSeparateOpeningHours
                  ? t("settings_openingHours_merge_button", "Merge opening hours")
                  : t("settings_openingHours_split_button", "Split pick-up and return hours")}
              </Button>
            </OpeningHoursForm>
          </div>
        )}
      </CardContent>
    </Card>
  );
});

type OpeningHoursForm = PropsWithChildren<{
  openingHours: ApiObjects["OpeningHoursDto"];
  timezone: string;
  facilityId: string;
  hasSeparateOpeningHours: boolean;
}>;
export function OpeningHoursForm(props: OpeningHoursForm) {
  const update = useOpeningHoursUpdate(props.facilityId);
  const { t } = useTranslation();

  const DAYS_LABEL = [
    t("common_date_monday", "Monday"),
    t("common_date_tuesday", "Tuesday"),
    t("common_date_wednesday", "Wednesday"),
    t("common_date_thursday", "Thursday"),
    t("common_date_friday", "Friday"),
    t("common_date_saturday", "Saturday"),
    t("common_date_sunday", "Sunday"),
  ];

  const formSchema = z.object({
    days: z.array(
      z.object({
        return: z.array(
          z.union([
            z.object({
              from: z.number(),
              to: z.number(),
              timeOfDay: z.enum(["MORNING", "AFTERNOON"]),
              type: z.enum(["DELIVERY", "RETURN", "BOTH"]),
            }),
            z.null(),
            z.undefined(),
          ])
        ),
        delivery: z.array(
          z.union([
            z.object({
              from: z.number(),
              to: z.number(),
              timeOfDay: z.enum(["MORNING", "AFTERNOON"]),
              type: z.enum(["DELIVERY", "RETURN", "BOTH"]),
            }),
            z.null(),
            z.undefined(),
          ])
        ),
        isOpen: z.boolean(),
      })
    ),
    exceptions: z.array(
      z.object({
        from: z.number(),
        to: z.number(),
        date: z.string(),
      })
    ),
  });

  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: props.openingHours,
  });
  const { fields: dayFields } = useFieldArray({ control: form.control, name: "days" });
  const {
    fields: exceptions,
    append: addException,
    remove: removeException,
  } = useFieldArray({ control: form.control, name: "exceptions" });

  const openingHoursTypes: ApiObjects["OpeningHourDto"]["type"][] = props.hasSeparateOpeningHours
    ? ["DELIVERY", "RETURN"]
    : ["BOTH"];
  const openingHoursBuckets: Record<ApiObjects["OpeningHourDto"]["type"], "delivery" | "return"> = {
    DELIVERY: "delivery",
    RETURN: "return",
    BOTH: "delivery",
  };
  const openingHoursTitles: Record<ApiObjects["OpeningHourDto"]["type"], string> = {
    DELIVERY: t("settings_openingHours_delivery_title", "Pick-up hours"),
    RETURN: t("settings_openingHours_return_title", "Return hours"),
    BOTH: t("settings_openingHours_title", "Opening hours"),
  };

  return (
    <div>
      <Form {...form}>
        <form
          onSubmit={form.handleSubmit((values) =>
            update.mutate({
              ...values,
              days: values.days.map((day) => ({
                ...day,
                delivery: day.delivery.filter(Boolean).map((hour) => ({
                  ...hour,
                  type: props.hasSeparateOpeningHours ? "DELIVERY" : "BOTH",
                  isOpen: day.isOpen,
                })),
                return: day.return.filter(Boolean).map((hour) => ({
                  ...hour,
                  type: props.hasSeparateOpeningHours ? "RETURN" : "BOTH",
                  isOpen: day.isOpen,
                })),
              })),
            })
          )}
        >
          <div className="flex flex-col overflow-x-auto gap-6">
            {openingHoursTypes.map((type) => (
              <div key={type}>
                <div className="mb-6 font-bold">{openingHoursTitles[type]}</div>
                <div className="grid gap-3 mr-6" style={{ gridTemplateColumns: "min-content auto" }}>
                  {dayFields.map((dayField, day) => (
                    <Fragment key={dayField.id}>
                      <div className="flex items-center">{DAYS_LABEL[day]}</div>
                      <div className="flex items-center gap-3">
                        <FormField
                          name={`days.${day}.${openingHoursBuckets[type]}.0`}
                          control={form.control}
                          render={({ field }) => (
                            <TimeRangeInput {...field} disabled={field.disabled || !field.value.isOpen} />
                          )}
                        ></FormField>
                        <FormField
                          name={`days.${day}.${openingHoursBuckets[type]}.1`}
                          control={form.control}
                          render={({ field }) => (
                            <div>
                              {field.value ? (
                                <div className="flex items-center gap-3">
                                  <Ampersand size={16} className={cn(!field.value.isOpen && "opacity-50")} />
                                  <TimeRangeInput {...field} disabled={field.disabled || !field.value.isOpen} />
                                  <Button type="button" variant="ghost" onClick={() => field.onChange(null)}>
                                    <XCircle strokeWidth={1.2} />
                                  </Button>
                                </div>
                              ) : (
                                <Button
                                  variant="ghost"
                                  type="button"
                                  onClick={() =>
                                    field.onChange({
                                      from: 960,
                                      to: 1200,
                                      timeOfDay: "AFTERNOON",
                                      type,
                                      isOpen: true,
                                    })
                                  }
                                >
                                  <PlusCircle strokeWidth={1.2} />
                                </Button>
                              )}
                            </div>
                          )}
                        ></FormField>
                        <FormField
                          name={`days.${day}.isOpen`}
                          control={form.control}
                          render={({ field }) => (
                            <>
                              <div className="flex items-center space-x-3">
                                <Switch checked={field.value} onCheckedChange={field.onChange} />
                              </div>
                            </>
                          )}
                        ></FormField>
                      </div>
                    </Fragment>
                  ))}
                </div>
              </div>
            ))}
            <div>{props.children}</div>
            <div>
              <div className="mb-6 font-bold">{t("settings_openingHoursExceptions_title", "Closed on")}</div>
              <div className="mb-3 space-y-3">
                {exceptions.map((exceptionField, exceptionIndex) => (
                  <div className="flex space-x-3" key={exceptionField.id}>
                    <FormField
                      name={`exceptions.${exceptionIndex}.date`}
                      control={form.control}
                      render={({ field }) => (
                        <DatePicker
                          mode="single"
                          hideClear
                          selected={new Date(field.value)}
                          onSelect={(_, date) => field.onChange(formatDateTnLocalTimezone(date))}
                          fromDate={zonedNow(props.timezone)}
                        />
                      )}
                    ></FormField>
                    <FormField
                      name={`exceptions.${exceptionIndex}`}
                      control={form.control}
                      render={({ field }) => <TimeRangeInput {...field} />}
                    ></FormField>
                    <Button size="icon" variant="ghost" type="button" onClick={() => removeException(exceptionIndex)}>
                      <Trash2Icon size={16} strokeWidth={1.2} />
                    </Button>
                  </div>
                ))}
              </div>
              <Button
                variant="outline"
                size="sm"
                type="button"
                onClick={() =>
                  addException({ date: formatDateTnLocalTimezone(zonedNow(props.timezone)), from: 540, to: 1080 })
                }
              >
                {t("settings_openingHoursExceptions_add_button", "Add closed on")}
              </Button>
            </div>
            <div>
              <Button className="mt-6" type="submit">
                {t("common_button_save")}
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
}

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { PropsWithChildren, useState } from "react";
import { SeasonForm, SeasonFormProps } from "./SeasonForm";

type SeasonFormDialogProps = PropsWithChildren<
  SeasonFormProps & {
    title: string;
    description: string;
  }
>;

export function SeasonFormDialog(props: SeasonFormDialogProps) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>{props.children}</DialogTrigger>
      <DialogContent hideFooter>
        <DialogHeader>
          <DialogTitle>{props.title}</DialogTitle>
          <DialogDescription>{props.description}</DialogDescription>
        </DialogHeader>
        <SeasonForm
          seasons={props.seasons}
          isLoading={props.isLoading}
          initialData={props.initialData}
          onSubmit={(v) => props.onSubmit(v).then(() => setIsOpen(false))}
        />
      </DialogContent>
    </Dialog>
  );
}

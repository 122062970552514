import { logger } from "@/lib/logger";
import { t } from "i18next";
import { XCircle } from "lucide-react";
import { useEffect } from "react";
import { useRouteError } from "react-router-dom";

export function ErrorPage() {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const error: any = useRouteError();

  useEffect(() => {
    const message = "message" in error ? error.message : "error" in error ? error.error?.message : "React Error";
    logger.error(message, { error });
  }, [error]);

  return (
    <div className="w-screen h-screen bg-primary flex flex-col items-center justify-center text-white space-y-3">
      <div>
        <XCircle size="48" strokeWidth={1.2} />
      </div>
      <div className="text-2xl">{t("errorPage_title", "An unexpected error ocurred")}</div>
      <div className="text-xl">{t("errorPage_description", "Please, contact us in order to resolve the issue")}</div>
    </div>
  );
}

import { api } from "@/lib/api-client";
import { useAuth } from "@/lib/useAuth";
import { ApiObjects } from "@pulso/api-client";
import { useQuery } from "@tanstack/react-query";

type ReturnType = Partial<Record<ApiObjects["UserRightDto"]["right"], true>>;

export function useMeRights(): ReturnType {
  const { facilityId } = useAuth();

  const query = useQuery({
    queryKey: ["me-rights", facilityId],
    queryFn: async () => {
      if (facilityId) {
        const toReturn: ReturnType = {};
        const rights = await api.getMeRights({ facilityId });
        rights.rights.forEach((r) => (toReturn[r] = true));
        return { rights: toReturn };
      }

      return { rights: {} as ReturnType };
    },
    staleTime: 60000,
  });

  return query.data?.rights || ({} as ReturnType);
}

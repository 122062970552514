import * as React from "react";
import * as SwitchPrimitives from "@radix-ui/react-switch";
import { Switch } from "./switch";
import { useTimer } from "@/lib/useTimer";
import { cn } from "@pulso/components/lib/utils";
import { Check } from "lucide-react";

type SwitchAutosaveProps = React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> & {
  onAttempt: (checked: boolean) => Promise<unknown>;
  notifyRight?: boolean;
};

const SwitchAutosave = React.forwardRef<React.ElementRef<typeof Switch>, SwitchAutosaveProps>(
  ({ onAttempt, notifyRight, ...props }, ref) => {
    const [isLoading, setIsLoading] = React.useState(false);
    const [isChecked, setIsChecked] = React.useState(props.checked);
    const timer = useTimer(2000);

    React.useEffect(() => setIsChecked(props.checked), [props.checked]);

    return (
      <div className="inline-flex items-center gap-2 relative">
        <Switch
          {...props}
          className={cn(props.className, "z-10")}
          ref={ref}
          checked={isChecked}
          onCheckedChange={(checked) => {
            setIsLoading(true);
            timer.deactivate();
            const promise = onAttempt(checked);
            const minTimer = new Promise((resolve) => setTimeout(resolve, 500));

            Promise.all([promise, minTimer])
              .then(([result]) => {
                props.onCheckedChange?.(checked);
                setIsChecked(checked);
                timer.activate();
              })
              .finally(() => {
                setIsLoading(false);
              });
          }}
          isLoading={isLoading}
        />

        <span
          className={cn(
            "absolute top-0 text-green-700 transition-all z-0",
            notifyRight ? "right-full pr-1" : "left-full pl-1",
            !timer.isActive && "left-0 opacity-0"
          )}
        >
          <Check size={20} />
        </span>
      </div>
    );
  }
);
SwitchAutosave.displayName = SwitchPrimitives.Root.displayName;

export { SwitchAutosave };

import { cn } from "@/lib/utils";
import { ApiObjects } from "@pulso/api-client";
import { DateColumn } from "./DateColumn";
import { ArrowRight } from "lucide-react";
import { Period } from "./Period";
import { formatDateTnLocalTimezone, zonedNow } from "@pulso/utils";

export function BookingDate({
  booking,
  periodLabel,
}: {
  booking: ApiObjects["BookingDto"];
  periodLabel?: "DURATION" | "LABEL";
}) {
  return (
    <div className="flex-shrink-0 grid grid-cols-3 items-center gap-3">
      <div
        className={cn(
          booking.status === "PENDING" &&
            booking.startAt < formatDateTnLocalTimezone(zonedNow(booking.timezone)) &&
            "text-destructive",
          booking.status !== "PENDING" && "text-muted-foreground"
        )}
      >
        <div className="text-center">
          <DateColumn date={booking.startAt} timezone={booking.timezone} />
        </div>
      </div>
      <div>
        <div className="flex flex-col items-center justify-evenly h-12 overflow-hidden">
          <ArrowRight className="flex-shrink-0" size={16} />
          {booking.period && (
            <span className="text-sm leading-4 inline-flex flex-col text-center" title={booking.period?.name}>
              {periodLabel === "DURATION" ? <Period minutes={booking.period.period} /> : booking.period.name}
            </span>
          )}
        </div>
      </div>
      <div
        className={cn(
          booking.status === "IN_PROGRESS" &&
            booking.endAt < formatDateTnLocalTimezone(zonedNow(booking.timezone)) &&
            "text-destructive",
          booking.status !== "IN_PROGRESS" && "text-muted-foreground"
        )}
      >
        <div className="text-center">
          <DateColumn date={booking.endAt} timezone={booking.timezone} />
        </div>
      </div>
    </div>
  );
}

import { analytics } from "@/lib/analytics/analytics";
import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";
import { useTranslation } from "react-i18next";

export function useBookingDocumentSend(bookingId: string) {
  const { t } = useTranslation();

  return useMutation({
    mutationFn: async (documentId: string) => {
      await api.sendSignatureForBooking({ documentId, bookingId }, undefined);
    },
    invalidateKeys: [["bookings"]],
    successMessage: t("booking_document_send_success", "Document sent to customer"),
    onSuccess: () => {
      analytics.trackEvent("booking_document_send_document", { bookingId });
    },
  });
}

import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";

export function useBookingCancel(bookingId: string) {
  return useMutation({
    mutationFn: async (data: { notifyCustomer: boolean; reason: string }) => {
      await api.cancelBooking({ bookingId }, data);
    },
    invalidateKeys: [["bookings"]],
    successMessage: "SAVE_SUCCESS",
  });
}

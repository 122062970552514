import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";

export function useUserRemoveFacility(userId: string) {
  return useMutation({
    mutationFn: async (facilityId: string) => await api.removeUserFromFacility({ userId, facilityId }, undefined),
    invalidateKeys: [["users"]],
    successMessage: "SAVE_SUCCESS",
  });
}

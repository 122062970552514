import { api } from "@/lib/api-client";
import { useQuery } from "@tanstack/react-query";

export function useProduct(productId: string | undefined | null) {
  const query = useQuery({
    queryKey: ["products", "product", productId],
    queryFn: async () => {
      if (!productId) {
        return null;
      }

      return await api.getProduct({ productId });
    },
  });

  return {
    data: query.data,
    isLoading: query.isLoading,
    product: query.data || null,
  };
}

import { useState } from "react";

export function useTimer(time: number) {
  const [isActive, setIsActive] = useState(false);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);

  return {
    isActive,
    activate: () => {
      setIsActive(true);
      if (timer) {
        clearTimeout(timer);
      }

      setTimer(
        setTimeout(() => {
          setIsActive(false);
        }, time)
      );
    },
    deactivate: () => {
      setIsActive(false);
      if (timer) {
        clearTimeout(timer);
        setTimer(null);
      }
    },
  };
}

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createApi = createApi;
function createApi(endpointAliases, httpClientProvider) {
    return Object.keys(endpointAliases).reduce((api, apiMethod) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const [httpMethod, endpoint, hasParams] = endpointAliases[apiMethod];
        api[apiMethod] = (params, body, extraOptions) => {
            if (!hasParams) {
                extraOptions = body;
                body = params;
                params = {};
            }
            return httpClientProvider({
                method: httpMethod,
                url: replaceVars(endpoint, params),
                body,
                extraOptions: httpMethod === "get" || httpMethod === "delete" ? body : extraOptions,
            });
        };
        api[apiMethod].getUrl = (params) => replaceVars(endpoint, params);
        function replaceVars(url, params) {
            let replacedUrl = url;
            if (typeof params === "object") {
                let match = replacedUrl.match(/\{\w+\}/);
                while (match) {
                    const param = match[0].substring(1, match[0].length - 1);
                    replacedUrl = replacedUrl.replace(match[0], encodeURIComponent(params[param]));
                    delete params[param];
                    match = replacedUrl.match(/\{\w+\}/);
                }
                if (!isEmpty(params)) {
                    replacedUrl += "?" + new URLSearchParams(params).toString();
                }
            }
            return replacedUrl;
        }
        return api;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
    }, {});
}
function isEmpty(obj) {
    if (typeof obj !== "object") {
        return true;
    }
    for (const i in obj) {
        return false;
    }
    return true;
}

import { MouseEvent, useEffect, useState } from "react";
import { Calendar } from "./Calendar";
import { DayPickerRangeProps, ActiveModifiers } from "react-day-picker";
import { isSameDay } from "date-fns";

export type DateRange = {
  from: Date | undefined;
  to?: Date | undefined;
};

function CalendarMulti({
  selected,
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: Omit<DayPickerRangeProps, "selected" | "mode" | "onSelect"> & {
  selected?: DateRange;
  onSelect?: (range: DateRange, selectedDay: Date, modifiers: ActiveModifiers, event: MouseEvent) => unknown;
}) {
  const [range, setRange] = useState(selected);
  useEffect(() => {
    setRange(selected);
  }, [selected]);
  return (
    <Calendar
      {...props}
      showOutsideDays={showOutsideDays}
      mode="range"
      selected={selected}
      onSelect={(_, selectedDay, modifiers, event) => {
        if (!range || !range?.from || range.to || (selectedDay < range.from && !isSameDay(selectedDay, range.from))) {
          const newRange = { from: selectedDay, to: undefined };
          const valid = props.onSelect?.(newRange, selectedDay, modifiers, event);
          if (valid !== false) {
            setRange(newRange);
          }
        } else if (!range?.to) {
          const newRange = { from: range.from, to: selectedDay };
          const valid = props.onSelect?.(newRange, selectedDay, modifiers, event);
          if (valid !== false) {
            setRange(newRange);
          }
        }
      }}
    />
  );
}
CalendarMulti.displayName = "CalendarMulti";

export { CalendarMulti };

import { Button } from "@/components/ui/button";
import { CentralSpinner } from "@/components/ui/central-spinner";
import { useTranslation } from "react-i18next";
import { useLocationCreate } from "@/api/useLocationCreate";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { ConfirmDialog } from "@/components/ui/confirm-dialog";
import { EditIcon, Trash2Icon } from "lucide-react";
import { useLocationDelete } from "@/api/useLocationDelete";
import { useLocationUpdate } from "@/api/useLocationUpdate";
import { useLocations } from "@/api/useLocations";
import { LocationFormDialog } from "./LocationFormDialog";
import { withFacilityIdParam } from "@/lib/withParams";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";

export const LocationsSettingsPage = withFacilityIdParam(({ facilityId }) => {
  const { t } = useTranslation();
  const { locations, isLoading } = useLocations(facilityId);
  const createMutation = useLocationCreate(facilityId);
  const updateMutation = useLocationUpdate();
  const deleteMutation = useLocationDelete();

  if (isLoading) {
    return <CentralSpinner />;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("settings_locations_title", "Locations")}</CardTitle>
        <CardDescription>
          {t(
            "settings_locations_description",
            "Add multiple locations of your business where customers can purchase and return products."
          )}
        </CardDescription>
      </CardHeader>

      <CardContent>
        <div>
          <LocationFormDialog
            title={t("settings_locations_createModal_title", "Add a new location")}
            description={t(
              "settings_locations_createModal_description",
              "Add a new location which can be used to modify the prices of a product for a specific period of the year"
            )}
            locations={locations}
            initialData={{ name: "" }}
            onSubmit={(values) => createMutation.mutateAsync(values)}
          >
            <Button size="sm">{t("settings_locations_create_button", "Add location")}</Button>
          </LocationFormDialog>
        </div>
        {locations.length > 0 && (
          <Table className="mt-3">
            <TableHeader>
              <TableRow>
                <TableHead>{t("settings_rentalPeriods_tableHeader_name", "Name")}</TableHead>
                <TableHead></TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {locations.map((location) => (
                <TableRow key={location.id}>
                  <TableCell>{location.name}</TableCell>
                  <TableCell align="right">
                    <div className="flex items-center justify-end">
                      <LocationFormDialog
                        title={t("settings_locations_createModal_title", "Add a new location")}
                        description={t(
                          "settings_locations_createModal_description",
                          "Add a new location which can be used to modify the prices of a product for a specific period of the year"
                        )}
                        locations={locations.filter((s) => s.id !== location.id)}
                        initialData={{
                          name: location.name,
                        }}
                        onSubmit={(values) => updateMutation.mutateAsync({ id: location.id, ...values })}
                      >
                        <Button variant="ghost">
                          <EditIcon strokeWidth={1.2} />
                        </Button>
                      </LocationFormDialog>
                      <ConfirmDialog
                        title={t("settings_locations_deleteModal_title", "Delete location")}
                        description={t(
                          "settings_locations_deleteModal_description",
                          "Are you sure you want to delete this location?"
                        )}
                        onContinue={() => deleteMutation.mutate(location.id)}
                      >
                        <Trash2Icon strokeWidth={1.2} />
                      </ConfirmDialog>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )}
      </CardContent>
    </Card>
  );
});

import { BaseAnalytics } from "./BaseAnalytics";

/*
  Events Documentation: https://developers.google.com/analytics/devguides/collection/ga4/reference/events?client_type=gtag
  Config Documentation: https://developers.google.com/analytics/devguides/collection/ga4/reference/config
*/
declare function gtag(action: string, name: string, params: object | string): void;

export class GoogleAnalytics extends BaseAnalytics {
  identify(userId: string, organization: string, facility: string): void {
    this.gtagFn("set", "user_id", userId);
  }
  trackEvent(event: string, params: object): void {
    this.gtagFn("event", event, params);
  }

  private gtagFn(action: string, name: string, params: object | string) {
    if (process.env.REACT_APP_GOOGLE_ANALYTICS_KEY) {
      gtag(action, name, params);
    }
  }
}

import { analytics } from "@/lib/analytics/analytics";
import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";

export function useBookingDocumentsRemoveSignatures(bookingId: string) {
  return useMutation({
    mutationFn: async () => {
      await api.removeBookingSignatures({ bookingId });
    },
    invalidateKeys: [["bookings"]],
    onSuccess: () => {
      analytics.trackEvent("booking_document_remove_signatures", { bookingId });
    },
  });
}

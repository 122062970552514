import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";
import { ApiObjects } from "@pulso/api-client";

export function useUserRightEnable(facilityId: string) {
  return useMutation({
    mutationFn: async (userRight: ApiObjects["UserRightDto"]["right"]) =>
      await api.enableUserRight({ facilityId, userRight }, undefined),
    invalidateKeys: [["user-rights"]],
  });
}

import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { useAuth } from "@/lib/useAuth";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { LoginForm } from "./LoginPage";
import { useTranslation } from "react-i18next";
import { usePageTitle } from "@/lib/usePageTitle";
import { useMe } from "@/api/useMe";
import i18nInstance from "../setup/i18n";

export function RootPage() {
  const auth = useAuth();
  const user = useMe();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const destination = searchParams.get("destination");
  const [isLoginDialogOpen, setIsLoginDialogOpen] = useState(false);
  const { t } = useTranslation();
  usePageTitle();

  useEffect(() => {
    if (auth.isEvaluated) {
      if (auth.isAuthenticated && auth.facility) {
        if (location.pathname === "/login" || location.pathname === "/") {
          if (destination && destination !== "/login" && destination !== "/") {
            navigate(destination);
          } else {
            navigate("/facility/" + auth.facility.id + "/bookings");
          }
        }
      } else if (!location.pathname.startsWith("/external")) {
        const query = new URLSearchParams({
          destination: destination || location.pathname,
        });
        navigate(`/login?${query.toString()}`);
      }
    }
  }, [auth.isAuthenticated, auth.isEvaluated, auth.facility, navigate]);

  useEffect(() => {
    if (user.user) {
      i18nInstance.changeLanguage(user.user.language);
    }
  }, [user.user?.language]);

  return (
    <>
      <Dialog open={isLoginDialogOpen || auth.isExpired} onOpenChange={setIsLoginDialogOpen}>
        <DialogContent hideFooter>
          <DialogHeader>
            <DialogTitle>{t("login_modal_title", "Login")}</DialogTitle>
            <DialogDescription>
              {t("login_modal_description", "Your session has expired. Please login again.")}
            </DialogDescription>
          </DialogHeader>
          <LoginForm />
        </DialogContent>
      </Dialog>
      <Outlet />
    </>
  );
}

import { useAgents } from "@/api/useAgents";
import { useAgentDelete } from "@/api/useAgentsDelete";
import {
  AlertDialog,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from "@/components/ui/table";
import { EditIcon, Trash2Icon } from "lucide-react";
import { AgentFormModal } from "./AgentsFormModal";
import { useFacilitySettings } from "@/api/useFacilitySettings";
import { withFacility, withFacilityIdParam } from "@/lib/withParams";
import { CentralSpinner } from "@/components/ui/central-spinner";
import { useTranslation } from "react-i18next";
import { Switch } from "@/components/ui/switch";
import { useFacilitySettingsPatch } from "@/api/useFacilitySettingsPatch";
import { InfoCard } from "@/components/specific/InfoCard";
import { useProducts } from "@/api/useProducts";
import { Price } from "@pulso/components/lib/Price";

export const AgentsSettingsPage = withFacility(function ({ facility }) {
  const { t } = useTranslation();
  const { agents } = useAgents(facility.id);
  const deleteAgent = useAgentDelete();
  const { settings } = useFacilitySettings(facility.id);
  const updateSettings = useFacilitySettingsPatch(facility.id);
  const products = useProducts();

  if (!settings) {
    return <CentralSpinner />;
  }

  return (
    <Card>
      <CardContent className="pt-6">
        <div className="flex space-x-6">
          <div className="flex-1">
            <div className="flex items-center space-x-3 pt-3">
              <Switch
                checked={settings.agentsEnabled}
                onCheckedChange={(v) => updateSettings.mutate({ agentsEnabled: v })}
              />
              <span>{t("settings_agents_disabled_button", "Enable agents")}</span>
            </div>
            {settings.agentsEnabled && (
              <div className="mt-6 space-y-6">
                <div>
                  <AgentFormModal
                    id={facility.id}
                    action="create"
                    agent={null}
                    products={products.data || []}
                    currency={facility.currency}
                  >
                    <Button variant="outline">{t("settings_agents_addButton", "Add agent")}</Button>
                  </AgentFormModal>
                </div>

                {agents.length > 0 && (
                  <Table className="w-full max-w-xl">
                    <TableHeader>
                      <TableRow>
                        <TableHead>{t("settings_agents_tableHeader_name", "Name")}</TableHead>
                        <TableHead>{t("settings_agents_tableHeader_products", "Products")}</TableHead>
                        <TableHead className="w-24"></TableHead>
                      </TableRow>
                    </TableHeader>
                    <TableBody>
                      {agents.map((agent) => (
                        <TableRow key={agent.id}>
                          <TableCell>{agent.name}</TableCell>
                          <TableCell>
                            <div>
                              {agent.productFees.length === 0 && (
                                <span className="text-muted-foreground text-xs">
                                  {t(
                                    "settings_agents_table_products_empty",
                                    "No products are associated with this agent yet"
                                  )}
                                </span>
                              )}
                              {agent.productFees.map((fee) => (
                                <div key={fee.productId + "agent_table_fee"}>
                                  {products.data?.find((p) => p.id === fee.productId)?.name} -{" "}
                                  {fee.feeType === "FLATFEE" ? (
                                    <Price price={fee.fee} currency={facility.currency} />
                                  ) : (
                                    fee.fee + "%"
                                  )}
                                </div>
                              ))}
                            </div>
                          </TableCell>
                          <TableCell align="right">
                            <AgentFormModal
                              id={agent.id}
                              action="edit"
                              agent={agent}
                              products={products.data || []}
                              currency={facility.currency}
                            >
                              <Button variant="ghost">
                                <EditIcon strokeWidth={1.2} />
                              </Button>
                            </AgentFormModal>

                            <AlertDialog>
                              <AlertDialogTrigger>
                                <Trash2Icon strokeWidth={1.2} />
                              </AlertDialogTrigger>
                              <AlertDialogContent>
                                <AlertDialogHeader>
                                  <AlertDialogTitle>
                                    {t("settings_agents_delete_title", "Delete {{agent}}", {
                                      agent: agent.name,
                                    })}
                                  </AlertDialogTitle>
                                  <AlertDialogDescription>
                                    {t(
                                      "settings_agents_delete_subtitle",
                                      "Are you sure you want to delete this agent?"
                                    )}
                                  </AlertDialogDescription>
                                </AlertDialogHeader>
                                <AlertDialogFooter>
                                  <AlertDialogCancel>{t("common_button_cancel")}</AlertDialogCancel>
                                  <AlertDialogAction onClick={() => deleteAgent.mutate(agent.id)}>
                                    {t("common_button_delete")}
                                  </AlertDialogAction>
                                </AlertDialogFooter>
                              </AlertDialogContent>
                            </AlertDialog>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                )}
              </div>
            )}
          </div>

          <div>
            <InfoCard
              title={t("settings_agents_info_title", "Agents")}
              content={t(
                "settings_agents_info_content",
                "Agents help you with your day-to-day operations and charge a fee for every booking. If you would like to make use of it, you can enable it."
              )}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
});

import { PriceInput } from "@/components/ui/price-input";
import { Label } from "@/components/ui/label";
import { api } from "@/lib/api-client";
import { fieldsToHash, hashToVariantId } from "@pulso/utils";
import { t } from "i18next";
import { ClipboardEvent, forwardRef, useState } from "react";
import { Controller, ControllerProps, UseFormReturn } from "react-hook-form";
import { cn } from "@/lib/utils";
import { DropdownMultiSelect } from "@/components/ui/dropdown-multi-select";
import { useSeasons } from "@/api/useSeasons";

type ProductVariantsProps = {
  form: UseFormReturn<{ prices: Record<string, Record<string, number | null | undefined>> }>;
  product: Awaited<ReturnType<typeof api.getProduct>>;
  rentalPeriods: Awaited<ReturnType<typeof api.getAllRentalPeriodsForFacility>>;
  currency: string;
};

const ControllerFn = forwardRef<HTMLInputElement, ControllerProps>((props, ref) => {
  return <Controller {...props} />;
});
ControllerFn.displayName = "ControllerFn";

export function ProductVariants({ form, product, rentalPeriods, currency }: ProductVariantsProps) {
  const variants = generateVariants();
  const [focusedPeriod, setFocusedPeriod] = useState("");
  const rentalPeriodsSorted = rentalPeriods.sort((a, b) => a.period - b.period);
  const hasVariants = variants.length > 1 || variants[0].hash !== "default";
  const { seasons } = useSeasons(product.facilityId);
  const hasSeasons = seasons.length > 0;
  const [selectedSeasons, setSelectedSeasons] = useState<Record<string, string[]>>(getPreSelectedSeasons());

  return (
    <div className="flex">
      <div className="w-40">
        {hasVariants && <div className="pl-2 pr-6 pb-2 h-16 flex items-end"></div>}
        {hasSeasons && (
          <>
            <div className="h-10 text-sm text pl-2 font-medium flex items-center">
              {t("products_pricing_seasonsEnabled", "Seasons enabled")}
            </div>
            <div className="h-10 text-sm text pl-2 font-medium flex items-center">
              {t("products_pricing_season", "Season")}
            </div>
          </>
        )}
        {rentalPeriodsSorted.map((period, i) => (
          <div
            key={period.id}
            className={cn("pl-2 pr-6 h-10 flex items-center", focusedPeriod === period.id && "bg-gray-50")}
          >
            <Label className="whitespace-nowrap">{period.name}</Label>
          </div>
        ))}
      </div>
      <div className="flex overflow-x-auto">
        {variants.map((variant) => (
          <div key={variant.id}>
            {hasVariants && <div className="pl-2 pr-6 pb-2 h-16 flex items-end text-sm">{variant.label}</div>}
            {hasSeasons && (
              <>
                <div className="h-10">
                  <DropdownMultiSelect
                    value={selectedSeasons[variant.hash] || []}
                    options={seasons.map((season) => ({ label: season.name, value: season.id }))}
                    onChange={(seasons) => setSelectedSeasons({ ...selectedSeasons, [variant.hash]: seasons })}
                  />
                </div>
              </>
            )}
            <div className="flex">
              {["default", ...(selectedSeasons[variant.hash] || [])].map((seasonId) => (
                <div key={`${variant.hash}.${seasonId}`}>
                  {hasSeasons && (
                    <div className="h-10 flex items-center text-sm">
                      {seasons.find((s) => s.id === seasonId)?.name || t("products_prices_defaultSeason", "Default")}
                    </div>
                  )}
                  {rentalPeriodsSorted.map((period, i) => (
                    <div
                      key={`${variant.hash}.${seasonId}.${period.id}`}
                      className={cn(
                        "flex items-center pr-6 h-10 space-x-6 w-full",
                        focusedPeriod === period.id && "bg-gray-50"
                      )}
                    >
                      <ControllerFn
                        {...form.register(`prices.${variant.hash}.${period.id}_${seasonId}` as never)}
                        render={({ field, fieldState }) => (
                          <div className="w-40">
                            <PriceInput
                              {...field}
                              onFocus={() => setFocusedPeriod(period.id)}
                              onBlur={() => setFocusedPeriod("")}
                              onPaste={(e) => onPaste(e, variant.hash, period.id, seasonId)}
                              className={cn("w-full", !fieldState.invalid && "bg-white")}
                              inputClassName="placeholder-opacity-20"
                              invalid={fieldState.invalid}
                              onChange={field.onChange}
                              placeholder="N/A"
                              sign={currency}
                            />
                          </div>
                        )}
                      />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );

  function generateVariants() {
    const enabledFields = product.fields.filter((f) => f.priceable);

    const permutations = createVariantsPermutations(enabledFields.map((field) => field?.options || []));

    const variants = permutations.map((variant) => {
      const hash = fieldsToHash(variant.map((v) => ({ value: v.id })));

      return {
        id: hashToVariantId(product.id, hash),
        label: variant.map((o) => o.value).join(" / "),
        hash,
      };
    });

    if (permutations.length === 1 && permutations[0].length === 0) {
      return [
        {
          id: product.id + "_default",
          hash: "default",
          label: t("products_pricing_defaultVariant", "Default"),
        },
      ];
    }

    return variants;
  }

  function getPreSelectedSeasons() {
    const value = form.getValues().prices;
    const seasonPerVariant: Record<string, string[]> = {};
    for (const variantHash in value) {
      const seasons: Record<string, true> = {};
      for (const periodIdSeasonIdPair in value[variantHash]) {
        const [periodId, seasonId] = periodIdSeasonIdPair.split("_");
        if (seasonId !== "default") {
          seasons[seasonId] = true;
        }
      }
      seasonPerVariant[variantHash] = Object.keys(seasons);
    }
    return seasonPerVariant;
  }

  function onPaste(e: ClipboardEvent, startVariantHash: string, startPeriodId: string, seasonId: string) {
    e.preventDefault();

    const data = e.clipboardData.getData("text/plain");
    const dataRows = data
      .trim()
      .split("\n")
      .map((row) =>
        row
          .trim()
          .split(/[\t]/)
          .map((cell) => parseFloat(cell))
      );

    const periodsToUpdate = sliceAfter(rentalPeriodsSorted, (rp) => rp.id === startPeriodId, dataRows.length);
    const variantsToUpdate = sliceAfter(variants, (v) => v.hash === startVariantHash);

    let periodIndex = 0;
    for (const p of periodsToUpdate) {
      let varIndex = 0;
      for (const v of variantsToUpdate) {
        const seasonsForVariant = ["default", ...(selectedSeasons[v.hash] || [])];
        const seasonsToUpdate =
          varIndex === 0 ? sliceAfter(seasonsForVariant, (s) => s === seasonId) : seasonsForVariant;
        for (const s of seasonsToUpdate) {
          if (typeof dataRows[periodIndex] !== "undefined" && typeof dataRows[periodIndex][varIndex] !== "undefined") {
            form.setValue(`prices.${v.hash}.${p.id}_${s}`, dataRows[periodIndex][varIndex]);
          }
          varIndex++;
        }
      }
      periodIndex++;
    }
  }
}

function createVariantsPermutations<T>(arrays: T[][]): T[][] {
  return arrays.reduce<T[][]>(
    (acc, current) => {
      const result: T[][] = [];
      acc.forEach((accItem) => {
        current.forEach((currItem) => {
          result.push([...accItem, currItem]);
        });
      });
      return result;
    },
    [[]]
  );
}

function sliceAfter<T>(arr: T[], pred: (item: T) => boolean, len?: number) {
  const index = arr.findIndex((i) => pred(i));
  return len ? arr.slice(index, index + len) : arr.slice(index);
}

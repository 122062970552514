import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";
import { ApiObjects } from "@pulso/api-client";

export function useBookingItemsCreate(bookingId: string) {
  return useMutation({
    mutationFn: async (data: ApiObjects["AddItemsToBookingBody"]) => {
      await api.addBookingItems({ bookingId }, data);
    },
    invalidateKeys: [["bookings"]],
  });
}

import { z } from "zod";
import { DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Dialog, DialogTrigger, DialogContent } from "@/components/ui/dialog";
import { PropsWithChildren, useState } from "react";
import { PulsoFormProvider, createForm } from "../../../components/specific/Form";
import { useExtraChargeCreate } from "@/api/useExtraChargesCreate";
import { t } from "i18next";
import { ApiObjects } from "@pulso/api-client";

type ExtraChargeFormModalType = PropsWithChildren<{
  id: string;
  action: "create" | "edit";
  extraCharge: ApiObjects["ExtraChargeDto"] | null;
  currency: string;
}>;

export function ExtraChargeFormModal({ id, action, extraCharge, children, currency }: ExtraChargeFormModalType) {
  const [open, setOpen] = useState(false);

  const formSchema = z.object({
    concept: z.string().min(2).max(50),
    type: z.enum(["DISCOUNT", "CHARGE"]),
    defaultAmount: z.number().min(0),
    vat: z.any(),
  });

  const form = createForm(
    {
      concept: {
        label: t("settings_extraCharges_form_concept_label", "Concept"),
        type: "string",
      },
      type: {
        label: t("settings_extraCharges_form_type_label", "Type"),
        type: "radio",
        options: [
          {
            label: t("settings_extraCharges_form_type_option_extraCharge", "Extra charge"),
            value: "CHARGE",
          },
          {
            label: t("settings_extraCharges_form_type_option_discount", "Discount"),
            value: "DISCOUNT",
          },
        ],
      },
      defaultAmount: {
        label: t("settings_extraCharges_form_defaultAmount_label", "Default amount"),
        type: "price",
        sign: currency,
        className: "w-32",
      },
      vat: {
        label: t("settings_extraCharges_form_defaultAmount_vat", "VAT"),
        type: "number",
        prefix: "%",
        className: "w-32",
        hideIf: (v) => v.type === "DISCOUNT",
      },
    },
    formSchema
  );

  const save = useExtraChargeCreate(id, action);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent hideFooter>
        <DialogHeader>
          <DialogTitle>{t("settings_extraCharges_form_title", "Extra charges")}</DialogTitle>
          <DialogDescription>
            {t("settings_extraCharges_form_subtitle", "Define extra charges that may be added to a booking")}
          </DialogDescription>
        </DialogHeader>
        <PulsoFormProvider
          {...form}
          isLoading={save.isPending}
          onSubmit={(values) =>
            save.mutate(
              {
                concept: values.concept,
                defaultAmount: values.defaultAmount * (values.type === "DISCOUNT" ? -1 : 1),
                vat: values.vat || null,
              },
              { onSuccess: () => setOpen(false) }
            )
          }
          initialValues={{
            concept: extraCharge?.concept || "",
            type: extraCharge ? (extraCharge.defaultAmount > 0 ? "CHARGE" : "DISCOUNT") : "CHARGE",
            defaultAmount: extraCharge?.defaultAmount ? Math.abs(extraCharge?.defaultAmount) : 0,
            vat: extraCharge?.vat ?? null,
          }}
        />
      </DialogContent>
    </Dialog>
  );
}

import { Input } from "../ui/input";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";
import { Textarea } from "../ui/textarea";

type FieldInputProps = {
  type: "TEXT" | "OPTION";
  onChange: (value: string) => void;
  options?: { value: string; id: string }[];
  value: string | null;
  disabled?: boolean;
  placeholder?: string;
};

export function FieldInput({ type, options, onChange, value, placeholder, disabled }: FieldInputProps) {
  if (type === "OPTION") {
    return (
      <Select onValueChange={onChange} disabled={disabled} value={value || ""}>
        <SelectTrigger>
          <SelectValue placeholder={placeholder} />
        </SelectTrigger>
        <SelectContent>
          {(Array.isArray(options) ? options : []).map((opt) => (
            <SelectItem key={opt.id} value={opt.id}>
              {opt.value}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    );
  } else if (type === "TEXT") {
    return (
      <Input
        value={value || ""}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        disabled={disabled}
      />
    );
  } else if (type === "MULTILINE") {
    return (
      <Textarea
        value={value || ""}
        onChange={(e) => onChange(e.target.value)}
        placeholder={placeholder}
        disabled={disabled}
      />
    );
  }
}

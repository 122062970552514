import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";

export function useBookingHold(bookingId: string) {
  return useMutation({
    mutationFn: async () => {
      await api.holdBooking({ bookingId }, undefined);
    },
    invalidateKeys: [["bookings"]],
    successMessage: "SAVE_SUCCESS",
  });
}

import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import { NewBookingModal, NewBookingModalProps } from "./NewBookingModal";

export function NewBookingButton(props: NewBookingModalProps) {
  const { t } = useTranslation();

  return (
    <div className="flex items-center rounded-md bg-primary text-primary-foreground">
      <NewBookingModal {...props}>
        <Button variant="default" className="px-3 shadow-none">
          {t("bookings_add_button", "New booking")}
        </Button>
      </NewBookingModal>
    </div>
  );
}

import { api } from "@/lib/api-client";
import { ApiObjects } from "@pulso/api-client";
import { useQuery } from "@tanstack/react-query";

export function useProductPrices(
  productId: string | undefined
): ApiObjects["ProductPricesGetResponse"] & { isLoading: boolean } {
  const query = useQuery({
    queryKey: ["products", "prices", productId],
    queryFn: async () => {
      if (!productId) {
        return { prices: [], enabledPeriods: [] };
      }

      return await api.getPrices({ productId });
    },
    staleTime: 3600000,
  });

  return {
    prices: query.data?.prices || [],
    enabledPeriods: query.data?.enabledPeriods || [],
    isLoading: query.isLoading,
  };
}

import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";

export function useDashboardViewUpdate() {
  return useMutation({
    mutationFn: async (data: { id: string; name: string; state: object }) => {
      await api.updateDashboardView({ dashboardViewId: data.id }, {
        name: data.name,
        state: data.state,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any);
    },
    successMessage: "SAVE_SUCCESS",
    invalidateKeys: [["dashboard-views"]],
  });
}

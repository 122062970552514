import { api } from "@/lib/api-client";
import { useQuery } from "@tanstack/react-query";

export function useBooking(bookingId: string | undefined) {
  return useQuery({
    queryKey: ["bookings", "booking", bookingId],
    queryFn: async () => {
      if (!bookingId) {
        return null;
      }

      return await api.getBooking({ bookingId });
    },
  });
}

import { useCustomerFields } from "@/api/useCustomerFields";
import { createForm } from "@/components/specific/Form";
import { useAuth } from "@/lib/useAuth";
import { transformHashToFieldValues } from "@/lib/utils";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { zodFile } from "@pulso/components/utils/zodFile";
import { useLanguages } from "@/api/useLanguages";
import { zodPhone } from "@pulso/components/lib/PhoneInput";

export function useCustomerForm(formType: "full" | "quick", sendFastTrackAllowed: boolean = false) {
  const { facilityId } = useAuth();
  const { fields } = useCustomerFields(facilityId || "");
  const { t } = useTranslation();
  const { languages } = useLanguages(facilityId || "");

  const schema = z
    .object({
      firstname: z.string().min(2).max(50),
      lastname: z.string().min(2).max(50),
      email: z.union([z.string().email().max(150), z.string().length(0), z.null()]),
      language: z.string().min(2),
      ...fields
        .filter((f) => formType === "full" || f.type === "PHONE")
        .reduce(
          (obj, field) => ({
            ...obj,
            ["fields_" + field.id]:
              field.type === "FILE"
                ? zodFile(t, { optional: !field.mandatory })
                : field.type === "PHONE"
                  ? zodPhone({ optional: !field.mandatory })
                  : field.mandatory
                    ? z.string().min(1).max(100)
                    : z.string().max(100).optional(),
          }),
          {}
        ),
      sendFastTrackLink: z.boolean().optional(),
    })
    .transform((o) => {
      return {
        firstname: o.firstname,
        lastname: o.lastname,
        email: o.email,
        language: o.language,
        sendFastTrackLink: o.sendFastTrackLink,
        fields: transformHashToFieldValues<string | null | File | boolean>(o).map((v) =>
          typeof v.value === "object" && !(v.value instanceof File) && v.value !== null ? { ...v, value: "-" } : v
        ) as { id: string; value: string | null }[], // Hack :/
      };
    });

  const form = createForm(
    {
      firstname: {
        label: t("customer_form_label_firstname", "First Name"),
        type: "string",
        mandatory: true,
      },
      lastname: {
        label: t("customer_form_label_lastname", "Last Name"),
        type: "string",
        mandatory: true,
      },
      email: {
        label: t("customer_form_label_email", "Email"),
        type: "string",
      },
      language: {
        label: t("customer_form_label_language", "Language"),
        type: "language",
        className: "w-auto",
        mandatory: true,
        languages,
      },
      ...fields
        .filter((f) => formType === "full" || f.type === "PHONE")
        .reduce(
          (obj, field) => ({
            ...obj,
            ["fields_" + field.id]: {
              label: field.name,
              type:
                field.type === "FILE"
                  ? "file"
                  : field.type === "OPTION"
                    ? "select"
                    : field.type === "MULTILINE"
                      ? "text"
                      : field.type === "DATE"
                        ? "date-string"
                        : field.type === "PHONE"
                          ? "phone"
                          : "string",
              options: field.type !== "OPTION" ? undefined : field.options.map((v) => ({ label: v, value: v })),
              mandatory: field.mandatory,
            },
          }),
          {}
        ),
      sendFastTrackLink: {
        label: "",
        placeholder: t("customer_form_checkbox_sendFastTrack", "Send Fast Track link"),
        type: "switch",
        hideIf: (values) => !sendFastTrackAllowed || !values.email,
      },
    },
    schema
  );

  return form;
}

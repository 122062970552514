import {
  DefaultError,
  InvalidateQueryFilters,
  UseMutationOptions,
  UseMutationResult,
  useMutation as defaultUseMutation,
  useQueryClient,
} from "@tanstack/react-query";
import { useErrorLabelResolver } from "./useErrorLabelResolver";
import { toast } from "sonner";
import { useTranslation } from "react-i18next";

export function useMutation<TData = unknown, TError = DefaultError, TVariables = void, TContext = unknown>(
  options: UseMutationOptions<TData, TError, TVariables, TContext> & {
    invalidateKeys: InvalidateQueryFilters["queryKey"][];
    successMessage?: "DELETE_SUCCESS" | "SAVE_SUCCESS" | (string & Record<never, never>);
  }
): UseMutationResult<TData, TError, TVariables, TContext> {
  const queryClient = useQueryClient();
  const { t } = useTranslation();
  const errorLabelResolver = useErrorLabelResolver();

  const opts: UseMutationOptions<TData, TError, TVariables, TContext> = {
    ...options,
    onSuccess: (data, variables, context) => {
      options.onSuccess?.(data, variables, context);
      for (const key of options.invalidateKeys) {
        queryClient.invalidateQueries({ queryKey: key });
      }
      if (options.successMessage) {
        if (options.successMessage === "DELETE_SUCCESS") {
          toast.success(t("common_messag_deleteSuccess", "Successfully deleted"));
        } else if (options.successMessage === "SAVE_SUCCESS") {
          toast.success(t("common_messag_saveSuccess", "Successfully saved"));
        } else {
          toast.success(options.successMessage);
        }
      }
    },
    onError: (error, variables, context) => {
      options.onError?.(error, variables, context);
      toast.error(errorLabelResolver.resolve(error));
    },
  };

  return defaultUseMutation(opts, queryClient);
}

import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { PropsWithChildren, useEffect } from "react";
import { Separator } from "@/components/ui/separator";
import { SidebarMenu, SidebarMenuProps } from "@/components/specific/SidebarMenu";

export function LayoutWithSiebar(props: PropsWithChildren<{ items: SidebarMenuProps["items"]; urlPrefix: string }>) {
  const activeUrlRegexp = new RegExp(`/${props.urlPrefix}/([a-z-]+)/?`);
  const navigate = useNavigate();
  const location = useLocation();
  const active = location.pathname.match(activeUrlRegexp);
  const currentTab = (active && active[1]) || "";
  console.log({ currentTab });
  const activeItem = props.items.find((item) => item.href === currentTab);

  useEffect(() => {
    if (!activeItem) {
      navigate(props.items[0].href);
    }
  }, [activeItem, props.items, navigate]);

  return (
    <div className="flex h-full">
      <SidebarMenu items={props.items} activeItem={activeItem} />
      <div className="flex-1 h-full overflow-y-auto">
        <div className="h-full">
          <div className="p-6">
            <div className="space-y-0.5">
              <h2 className="text-2xl font-bold tracking-tight">{activeItem?.title}</h2>
              <p className="text-muted-foreground">{activeItem?.description}</p>
            </div>
            <Separator className="my-6" />
            {props.children}
            <Outlet />
          </div>
        </div>
      </div>
    </div>
  );
}

import { api } from "@/lib/api-client";
import { useQuery } from "@tanstack/react-query";

export function useFacilityFastTrackSettings(facilityId: string) {
  const query = useQuery({
    queryKey: ["fast-track-settings", facilityId],
    queryFn: async () => await api.getFastTrackSettings({ facilityId }),
  });

  return {
    settings: query.data || null,
    isLoading: query.isLoading,
  };
}

import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";

export function useDocumentCreatePoNumber(bookingId: string) {
  return useMutation({
    mutationFn: async (documentId: string) => {
      return await api.createDocumentPoNumber({ bookingId, documentId }, undefined);
    },
    invalidateKeys: [["documents"], ["bookings"]],
    successMessage: "SAVE_SUCCESS",
  });
}

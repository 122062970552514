import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";

export function useLocationUpdate() {
  return useMutation({
    mutationFn: async (data: { id: string; name: string }) => {
      await api.updateLocation(
        { locationId: data.id },
        {
          name: data.name,
        }
      );
    },
    successMessage: "SAVE_SUCCESS",
    invalidateKeys: [["locations"]],
  });
}

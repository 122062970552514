import { useAtom } from "jotai";
import { atomWithStorage } from "jotai/utils";

type BookingsDashboardSettings = {
  periodLabel: "LABEL" | "DURATION";
  viewMode: "RECEPTION" | "MANAGER";
};
const settingsAtom = atomWithStorage<BookingsDashboardSettings>("pulso-bookings-dashboard-settings", {
  periodLabel: "DURATION",
  viewMode: "RECEPTION",
});

export function useBookingsDashboardSettings() {
  const [settings, setSettings] = useAtom(settingsAtom);

  function setPeriodLabel(periodLabel: BookingsDashboardSettings["periodLabel"]) {
    setSettings({ ...settings, periodLabel });
  }

  function setViewMode(viewMode: BookingsDashboardSettings["viewMode"]) {
    setSettings({ ...settings, viewMode });
  }

  return {
    settings,
    setPeriodLabel,
    setViewMode,
  };
}

import * as React from "react";
import { RadioGroup } from "./radio-group";
import { Label } from "./label";
import { Checkbox } from "./checkbox";

export interface MultiSelectProps extends React.InputHTMLAttributes<HTMLButtonElement> {
  options: Array<{ label: string; value: string }>;
  value: string[];
}

const MultiSelect = React.forwardRef<HTMLButtonElement, MultiSelectProps>(({ className, type, ...props }, ref) => {
  const id = Math.random();
  const value = Array.isArray(props.value) ? props.value : [];
  return (
    <RadioGroup defaultValue="comfortable">
      {props.options.map((opt) => (
        <div key={`option-${id}-${opt.value}`} className="flex items-center space-x-2">
          <Checkbox
            value={opt.value}
            id={`option-${id}-${opt.value}`}
            checked={value.includes(opt.value)}
            onCheckedChange={(checked) => {
              if (checked) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                props.onChange?.([...value, opt.value] as any);
              } else {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                props.onChange?.([...value.filter((v) => v !== opt.value)] as any);
              }
            }}
          />
          <Label htmlFor={`option-${id}-${opt.value}`}>{opt.label}</Label>
        </div>
      ))}
    </RadioGroup>
  );
});
MultiSelect.displayName = "MultiSelect";

export { MultiSelect };

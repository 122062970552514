import { api } from "@/lib/api-client";
import { useQuery } from "@tanstack/react-query";

export function useBookingsTraceLog(bookingId: string) {
  const query = useQuery({
    queryKey: ["bookings", "booking", "traceLog", bookingId],
    queryFn: async () => {
      return await api.getBookingTraceLog({ bookingId });
    },
  });

  return {
    entries: query.data?.entries || [],
    isLoading: query.isLoading,
  };
}

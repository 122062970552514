import { useUserRoleLabel } from "@/pages/settings/users/useUserRoleLabel";
import { ApiObjects } from "@pulso/api-client";
import { Badge } from "../ui/badge";

export function UserRole(props: { role: ApiObjects["UserDto"]["role"] }) {
  const labels = useUserRoleLabel();

  return (
    <Badge variant="secondary" className={props.role === "ADMIN" ? "bg-red-200" : "bg-amber-200"}>
      {labels[props.role]}
    </Badge>
  );
}

import { Spinner } from "@/components/ui/spinner";
import { ProductFields } from "./fields/ProductFields";
import { Button } from "@/components/ui/button";
import { ProductFieldFormModal } from "./fields/ProductFieldFormModal";
import { useProduct } from "@/api/useProduct";
import { useProductUpdate } from "@/api/useProductUpdate";
import { useUpdateProductForm } from "./useUpdateProductForm";
import { useTranslation } from "react-i18next";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { ProductPricingForm } from "./pricing/ProductPricingPage";
import { useRentalPeriods } from "@/api/useRentalPeriods";
import { useProductPrices } from "@/api/useProductPrices";
import { PageHeader } from "@/components/specific/PageHeader";
import { cn } from "@/lib/utils";
import { useProducts } from "@/api/useProducts";
import { Switch } from "@/components/ui/switch";
import { useProductAddComplement } from "@/api/useProductAddComplement";
import { withParams } from "@/lib/withParams";
import { useProductDeleteComplement } from "@/api/useProductDeleteComplement";
import { PackageProductsForm } from "./PackageProductsForm";
import { useLocations } from "@/api/useLocations";
import { useProductAddLocation } from "@/api/useProductAddLocation";
import { useProductDeleteLocation } from "@/api/useProductDeleteLocation";
import { PulsoFormProvider } from "@/components/specific/Form";
import { useAuth } from "@/lib/useAuth";

export const ProductDetailsPage = withParams(["productId", "facilityId"], function ({ productId, facilityId }) {
  const product = useProduct(productId);
  const productsQuery = useProducts();
  const products = productsQuery.data || [];
  const { locations } = useLocations(facilityId);
  const { facility } = useAuth();
  const productForm = useUpdateProductForm({ hideIdentifiable: product.data?.isPackage });
  const { periods, isLoading: isLoadingPeriods } = useRentalPeriods();
  const { prices, isLoading: isLoadingPrices } = useProductPrices(productId);

  const saveProductMutation = useProductUpdate(productId);
  const addComplement = useProductAddComplement(productId);
  const deleteComplement = useProductDeleteComplement(productId);
  const addLocation = useProductAddLocation(productId);
  const deleteLocation = useProductDeleteLocation(productId);
  const { t } = useTranslation();

  if (product.isLoading || !product.data || isLoadingPeriods || isLoadingPrices || !facility) {
    return <Spinner />;
  }

  return (
    <>
      <PageHeader
        title={product.data.name}
        subtitle={t("product_subtitle", "Define the products that you offer to clients")}
        back={{ to: "../products", label: t("products_title") }}
      />
      <Card>
        <CardContent className="pt-6">
          <PulsoFormProvider
            {...productForm}
            initialValues={{ ...product.data, vat: product.data.vat || undefined, image: product.data.thumbUrl }}
            isLoading={saveProductMutation.isPending}
            onSubmit={(values) =>
              saveProductMutation.mutate({
                ...values,
                identifiable: values.identifiable ? "true" : "false",
                vatEnabled: values.vatEnabled ? "true" : "false",
                vat: values.vat + "",
              })
            }
          />
        </CardContent>
      </Card>
      {locations.length > 0 && (
        <Card className="mt-6">
          <CardHeader>
            <CardTitle>{t("product_locations_title", "Locations")}</CardTitle>
            <CardDescription>
              {t("product_locations_description", "Choose the locations which offer this product.")}
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="flex flex-col gap-3">
              {locations.map((location) => (
                <div key={location.id} className="flex items-center gap-3">
                  <Switch
                    checked={product.data?.locations.some((l) => l.id === location.id)}
                    onCheckedChange={(enabled) => {
                      if (enabled) {
                        addLocation.mutate(location.id);
                      } else {
                        deleteLocation.mutate(location.id);
                      }
                    }}
                  />{" "}
                  {location.name}
                </div>
              ))}
            </div>
          </CardContent>
        </Card>
      )}
      {product.data?.isPackage && product.data && (
        <Card className="mt-6">
          <CardHeader>
            <CardTitle>{t("products_packageProducts_title", "Packaged products")}</CardTitle>
            <CardDescription>
              {t("products_packageProducts_subtitle", "Define the products that are included in this package", {
                product: product.data.name,
              })}
            </CardDescription>
          </CardHeader>
          <CardContent>
            <PackageProductsForm product={product.data} facilityId={product.data.facilityId} />
          </CardContent>
        </Card>
      )}
      <Card className="mt-6">
        <CardHeader>
          <CardTitle>{t("product_complements_title", "Complements")}</CardTitle>
          <CardDescription>
            {t("product_complements_description", "Choose the extras that can complement this product.")}
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="flex flex-col gap-3">
            {products
              .filter((complement) => complement.id !== productId)
              .map((complement) => (
                <div key={complement.id} className="flex items-center gap-3">
                  <Switch
                    checked={product.data?.complements.includes(complement.id)}
                    onCheckedChange={(enabled) => {
                      if (enabled) {
                        addComplement.mutate(complement.id);
                      } else {
                        deleteComplement.mutate(complement.id);
                      }
                    }}
                  />{" "}
                  {complement.name}
                </div>
              ))}
          </div>
        </CardContent>
      </Card>
      {!product.data?.isPackage && (
        <Card className="mt-6">
          <CardHeader>
            <CardTitle>{t("product_fields_title", "Fields")}</CardTitle>
            <CardDescription>
              {t(
                "product_fields_description",
                "Fields describe a product. They are also used to split product in different price categories."
              )}
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className={cn("flex items-center", product.data.fields?.length ? "justify-start" : "justify-start")}>
              <ProductFieldFormModal
                id={product.data.id}
                action="create"
                field={null}
                allowText={product.data.identifiable}
              >
                <Button variant="outline" className="mb-3">
                  {t("product_fields_add_button", "Add field")}
                </Button>
              </ProductFieldFormModal>
            </div>
            {product.data.fields?.length ? (
              <ProductFields product={product.data} />
            ) : (
              <div className="text-muted-foreground pt-6 text-sm">
                {t(
                  "product_fields_empty",
                  'No fields are defined for this product. Add the first field by clicking on the "Add field" button'
                )}
              </div>
            )}
          </CardContent>
        </Card>
      )}
      <Card className="mt-6">
        <CardHeader>
          <CardTitle>{t("products_pricing_title", "{{product}} Pricing", { product: product.data.name })}</CardTitle>
          <CardDescription>
            {t("products_pricing_subtitle", "Define the {{product}} variants and their prices", {
              product: product.data.name,
            })}
          </CardDescription>
        </CardHeader>
        <CardContent>
          <ProductPricingForm
            product={product.data}
            rentalPeriods={periods}
            variantPrices={prices}
            currency={facility.currency}
          />
        </CardContent>
      </Card>
    </>
  );
});

import { useBookingPatch } from "@/api/useBookingPatch";
import { useLocations } from "@/api/useLocations";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { ApiObjects } from "@pulso/api-client";
import { useTranslation } from "react-i18next";

export function BookingDetailsLocations({ booking }: { booking: ApiObjects["BookingDto"] }) {
  const save = useBookingPatch(booking.id);
  const { t } = useTranslation();
  const { locations, isLoading } = useLocations(booking.facilityId);

  if (isLoading) {
    return null;
  }

  return (
    <div className="flex flex-col gap-3">
      <div>
        <Label>{t("booking_details_locations_start_label", "Pick-up at")}</Label>
        <Select
          value={booking.startLocationId || "empty"}
          onValueChange={(startLocationIdValue) => {
            const startLocationId = startLocationIdValue === "empty" ? null : startLocationIdValue;
            save.mutate({
              startLocationId,
              endLocationId: booking.endLocationId || startLocationId,
            });
          }}
        >
          <SelectTrigger className="max-w-64">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="empty">{t("booking_details_locations_option_noLocation", "Empty")}</SelectItem>
            {locations.map((location) => (
              <SelectItem key={location.id} value={location.id}>
                {location.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      <div>
        <Label>{t("booking_details_locations_end_label", "Return at")}</Label>
        <Select
          value={booking.endLocationId || "empty"}
          onValueChange={(endLocationId) =>
            save.mutate({ endLocationId: endLocationId === "empty" ? null : endLocationId })
          }
        >
          <SelectTrigger className="max-w-64">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="empty">{t("booking_details_locations_option_noLocation", "Empty")}</SelectItem>
            {locations.map((location) => (
              <SelectItem key={location.id} value={location.id}>
                {location.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  );
}

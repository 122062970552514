import { useFixedTimeSlots } from "@/pages/settings/rental-periods/useTimeSlots";
import { cn } from "@pulso/components/lib/utils";
import { ForwardedRef, forwardRef, PropsWithChildren } from "react";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "./dropdown-menu";
import { Check } from "lucide-react";

type CustomTimePickerProps = PropsWithChildren<{
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  disabled?: boolean;
  granularity: number;
}>;

export const CustomTimePicker = forwardRef(
  ({ min, max, granularity, ...props }: CustomTimePickerProps, ref: ForwardedRef<HTMLSelectElement>) => {
    const slots = useFixedTimeSlots(granularity);

    return (
      <DropdownMenu>
        <DropdownMenuTrigger>{props.children}</DropdownMenuTrigger>
        <DropdownMenuContent className="max-h-64 overflow-y-auto">
          {slots
            .filter((time) => (!min || time.valueNum > min) && (!max || time.valueNum < max))
            .map((time) => (
              <DropdownMenuItem
                key={time.value}
                onClick={() => props.onChange(time.valueNum)}
                className="flex items-center gap-1.5"
              >
                <Check size={14} className={cn(props.value !== time.valueNum && "invisible")} />
                {time.label}
              </DropdownMenuItem>
            ))}
        </DropdownMenuContent>
      </DropdownMenu>
    );
  }
);

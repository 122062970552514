"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportColumnType = void 0;
exports.ExportColumnType = [
    "CUSTOMER_NAME",
    "CUSTOMER_FIRST_NAME",
    "CUSTOMER_LAST_NAME",
    "CUSTOMER_EMAIL",
    "CUSTOMER_LANGUAGE",
    "BOOKING_REFERENCE",
    "BOOKING_STATUS",
    "BOOKING_START_AT",
    "BOOKING_END_AT",
    "BOOKING_DELIVERED_AT",
    "BOOKING_RETURNED_AT",
    "BOOKING_AGENT",
    "BOOKING_NOTES",
    "BOOKING_CREATED_VIA",
    "BOOKING_CREATED_AT",
    "INVOICE_DEPOSIT",
    "INVOICE_TOTAL_PENALTIES",
    "INVOICE_TOTAL_DISCOUNT",
    "INVOICE_TOTAL_PAYMENTS",
    "INVOICE_TOTAL_PRICE",
    "INVOICE_TOTAL_BEFORE_TAX",
    "INVOICE_TOTAL_AFTER_TAX",
    "INVOICE_TOTAL_TAX",
    "INVOICE_TOTAL_DUE",
    "INVOICE_PERIOD_NAME",
    "INVOICE_PERIOD_DURATION",
];
/**
{
    "customer": {
        "fields": []
    },
    "items": [
        {
            "id": "cm1aixm40005gqq5bwr52mgyg",
            "startAt": "2024-09-26T10:00:00",
            "endAt": "2024-09-27T22:00:00",
            "period": {
                "id": "cm167ucyd000baf69806chy8v",
                "name": "2 days",
                "period": 2160,
                "startTime": null,
                "endAtClosing": false
            },
            "product": {
                "id": "cm0zscst60054zwibnj17pmkx",
                "name": "Ski",
                "description": "",
                "identifiable": true,
                "facilityId": "abc",
                  "imageUrl": "http://localhost:3000/api/v1/file/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiRklMRSIsImlhdCI6MTcyNzQzMTU0MSwiZXhwIjoxNzI3NDM1MTQxLCJhdWQiOiJhcHAucHVsc29yZW50LmNvbSIsImlzcyI6ImFwcC5wdWxzb3JlbnQuY29tIiwic3ViIjoiY20wenNjeHZyMDA1Nnp3aWJzcXlieHc3NiJ9.AO_bfBMyAS_5u-VahpC5ukqYYZZ4nWEOkoQ2_vL3E7I/ski.png",
                  "thumbUrl": "http://localhost:3000/api/v1/file/eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiRklMRSIsImlhdCI6MTcyNzQzMTU0MSwiZXhwIjoxNzI3NDM1MTQxLCJhdWQiOiJhcHAucHVsc29yZW50LmNvbSIsImlzcyI6ImFwcC5wdWxzb3JlbnQuY29tIiwic3ViIjoiY20wenNjeHZyMDA1Nnp3aWJzcXlieHc3NiJ9.AO_bfBMyAS_5u-VahpC5ukqYYZZ4nWEOkoQ2_vL3E7I/ski.png",
                "vatEnabled": false,
                "vat": 21,
                "showInFastTrack": true,
                "complements": [],
                "isPackage": false
            },
            "variantName": "",
            "parentId": null,
            "status": "PENDING",
            "stockItem": {
                "id": "cm0zsgebk006zzwib445tkmi9",
                "identifier": "456A",
                "notes": "",
                "status": "AVAILABLE",
                "fields": []
            },
            "fields": [],
            "price": 84,
            "productVariantId": "83608240f7b4868196a25601ae066db5",
            "availability": 48,
            "isDoubleBooked": false
        }
    ],
    
}
*/

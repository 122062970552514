import { useLocations } from "@/api/useLocations";
import { createForm } from "@/components/specific/Form";
import { fieldZodTransformer, zodFieldsType } from "@/lib/utils";
import { ApiObjects } from "@pulso/api-client";
import { useTranslation } from "react-i18next";
import { z } from "zod";

export function useEditStockItemsForm(facilityId: string, fields: ApiObjects["ProductWithExtraDto"]["fields"]) {
  const { t } = useTranslation();
  const { locations } = useLocations(facilityId);
  const formSchema = z
    .object({
      identifier: z.string().min(1).max(150),
      locations: z.array(z.string()).optional(),
      currentLocationId: z.string().nullable(),
      fields: zodFieldsType(fields),
      from: z.date().nullable(),
      notes: z.string().optional(),
    })
    .transform(fieldZodTransformer);

  const form = createForm(
    {
      identifier: { label: t("stockItems_form_label_identifier"), type: "string" },
      locations: {
        label: t("stockItems_form_label_linkedLocations", "Available in"),
        type: "multi",
        options: locations.map((l) => ({ value: l.id, label: l.name })),
        hideIf: () => locations.length === 0,
      },
      currentLocationId: {
        label: t("stockItems_form_label_location", "Location"),
        type: "select",
        options: locations.map((l) => ({ value: l.id, label: l.name })),
        hideIf: () => locations.length === 0,
      },
      from: {
        label: t("stockItems_form_label_unavailableUntil", "Unavailable until"),
        type: "date",
        hideIf: (stockItem) => stockItem.status === "AVAILABLE",
      },
      fields: {
        label: "",
        type: "fields",
        fields,
      },
      notes: { label: t("stockItems_form_label_notes"), type: "text" },
    },
    formSchema
  );
  return form;
}

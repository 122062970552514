import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  useReactTable,
  RowData,
  SortingState,
  getSortedRowModel,
} from "@tanstack/react-table";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { AutoPagination, AutoPaginationProps } from "../specific/AutoPagination";
import { useState } from "react";
import { ArrowDown, ArrowUp, ChevronDown, ChevronsUpDown, ChevronUp } from "lucide-react";
import { cn } from "@pulso/components/lib/utils";

interface DataTableProps<TData extends RowData, TValue = unknown> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  emptyText?: string;
  pagination?: AutoPaginationProps;
  initialSorting?: SortingState;
}

export function DataTable<TData, TValue>({
  columns,
  data,
  emptyText,
  pagination,
  initialSorting,
}: DataTableProps<TData, TValue>) {
  const [sorting, setSorting] = useState<SortingState>(initialSorting || []);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  return (
    <div>
      <Table>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id}>
                    {header.column.columnDef.enableSorting ? (
                      <div
                        className={cn(
                          "cursor-pointer flex justify-between items-center",
                          header.column.getIsSorted() && "font-bold underline"
                        )}
                        onClick={() => header.column.toggleSorting(header.column.getIsSorted() === "asc")}
                      >
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                        {header.column.getIsSorted() === "asc" ? (
                          <ArrowUp size={16} strokeWidth={2} />
                        ) : header.column.getIsSorted() === "desc" ? (
                          <ArrowDown size={16} strokeWidth={2} />
                        ) : (
                          <ChevronsUpDown size={14} strokeWidth={1.2} className="text-muted-foreground" />
                        )}
                      </div>
                    ) : (
                      <>
                        {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                      </>
                    )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow key={row.id} data-state={row.getIsSelected() && "selected"}>
                {row.getVisibleCells().map((cell) => (
                  <TableCell key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</TableCell>
                ))}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24 text-center text-muted-foreground">
                {emptyText || "-"}
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {pagination && (
        <div className="mt-6">
          <AutoPagination {...pagination} />
        </div>
      )}
    </div>
  );
}

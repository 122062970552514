import { MouseEventHandler, PropsWithChildren } from "react";
import { Avatar, AvatarFallback, AvatarImage } from "./avatar";
import { cn } from "@/lib/utils";
import { ApiObjects } from "@pulso/api-client";

type UserCardProps = PropsWithChildren<{
  user: {
    name: string;
    email: string | null;
    fields: ApiObjects["CustomerFieldValueDto"][];
  };
  onClick?: MouseEventHandler<HTMLDivElement>;
  className?: string;
  noAvatar?: boolean;
}>;

export function UserCard({ user, onClick, className, noAvatar, children }: UserCardProps) {
  const fallback = user.name
    .split(" ")
    .slice(0, 2)
    .map((names) => names[0])
    .join("");

  return (
    <div
      className={cn("grid gap-3 w-full", className)}
      style={{ gridTemplateColumns: !noAvatar ? "40px 1fr auto" : "1fr auto" }}
      onClick={onClick}
    >
      {!noAvatar && (
        <Avatar>
          <AvatarImage src="/avatars/01.png" />
          <AvatarFallback>{fallback}</AvatarFallback>
        </Avatar>
      )}
      <div className="min-w-0 flex flex-col justify-center">
        <p className="text-sm font-medium leading-none overflow-ellipsis overflow-hidden">{user.name}</p>
        {
          <p className="text-sm text-muted-foreground overflow-ellipsis overflow-hidden">
            {user.email || user.fields[0]?.value || "-"}
          </p>
        }
      </div>
      {children}
    </div>
  );
}

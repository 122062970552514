import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";

export function useSeasonDelete() {
  return useMutation({
    mutationFn: async (seasonId: string) => {
      await api.deleteSeason({ seasonId });
    },
    successMessage: "DELETE_SUCCESS",
    invalidateKeys: [["seasons"]],
  });
}

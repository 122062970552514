import { isZonedToday } from "@pulso/utils";
import { t } from "i18next";

type Format = "date" | "date-time" | "time" | "date-short" | "date-short-words" | "date-time-long";

const FORMATTERS: Record<Format, Intl.DateTimeFormat> = {
  date: Intl.DateTimeFormat(undefined, { dateStyle: "short" }),
  "date-short": Intl.DateTimeFormat(undefined, { month: "2-digit", day: "2-digit" }),
  "date-short-words": Intl.DateTimeFormat(undefined, { month: "long", day: "numeric" }),
  time: Intl.DateTimeFormat(undefined, { hour: "2-digit", minute: "2-digit", hourCycle: "h24" }),
  "date-time": Intl.DateTimeFormat(undefined, { dateStyle: "short", timeStyle: "short" }),
  "date-time-long": Intl.DateTimeFormat(undefined, { dateStyle: "long", timeStyle: "short" }),
};

export function formatDate(
  dt: Date | string | null,
  format?: Format,
  showToday?: boolean,
  timezone?: string
): string | null {
  if (!dt) {
    return null;
  }

  const date = getDate(dt);

  if (!date) {
    return null;
  }

  if (showToday && timezone && isZonedToday(date, timezone)) {
    return t("common_today", "Today");
  }

  return FORMATTERS[format || "date-time"].format(date);
}

export function DateFormat(
  props: {
    date: Date | string | null;
    format?: Format;
  } & ({ showToday: true; timezone: string } | { showToday?: false; timezone?: string })
) {
  return <>{formatDate(props.date, props.format, props.showToday, props.timezone)}</>;
}

function getDate(date: Date | string | null) {
  if (date === null) {
    return null;
  }

  if (typeof date === "string") {
    return new Date(date);
  }

  return date;
}

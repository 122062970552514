import { useBookingAddPenalty } from "@/api/useBookingAddPenalty";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { PulsoFormProvider, createForm } from "@/components/specific/Form";
import { t } from "i18next";
import { ForwardedRef, forwardRef, PropsWithChildren, useEffect, useState } from "react";
import { z } from "zod";
import { useExtraCharges } from "@/api/useExtraCharges";
import { PriceInput } from "@/components/ui/price-input";
import { Input } from "@/components/ui/input";
import { formatPrice } from "@pulso/utils";

type AddPenaltyModalProps = PropsWithChildren<{
  facilityId: string;
  bookingId: string;
  currency: string;
}>;

export function AddPenaltyModal({ facilityId, bookingId, currency, children }: AddPenaltyModalProps) {
  const [open, setOpen] = useState(false);
  const { extraCharges } = useExtraCharges(facilityId);

  const formSchema = z.object({
    amount: z.number(),
    reason: z.string(),
    notes: z.string().optional(),
    vat: z.any(),
  });

  const form = createForm(
    {
      reason: {
        label: t("bookings_invoice_penalties_form_label_reason", "Reason"),
        type: "select",
        placeholder: "",
        options: [
          ...extraCharges
            .filter((c) => c.defaultAmount >= 0)
            .map((extraCharge) => ({
              label: extraCharge.concept,
              value: extraCharge.concept,
            })),
          { label: t("bookings_invoice_penalties_form_option_other", "Other"), value: "OTHER" },
        ],
      },
      amount: {
        label: t("bookings_invoice_penalties_form_label_amount", "Amount"),
        type: "custom",
        disableIf: (values) => !values.reason,
        control: (field) => <PenaltyInput {...field} currency={currency} />,
      },
      vat: {
        label: t("bookings_invoice_penalties_form_label_vat", "VAT"),
        type: "number",
        prefix: "%",
        readOnly: true,
        className: "w-32",
      },
      notes: {
        label: t("bookings_invoice_penalties_form_label_notes", "Notes"),
        type: "text",
      },
    },
    formSchema
  );

  const add = useBookingAddPenalty(bookingId);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent hideFooter>
        <DialogHeader>
          <DialogTitle>{t("bookings_penalties_form_title", "Add penalty")}</DialogTitle>
          <DialogDescription>
            {t("bookings_penalties_form_subtitle", "Add penalty to the booking which the customer needs to pay")}
          </DialogDescription>
        </DialogHeader>
        <PulsoFormProvider
          {...form}
          isLoading={add.isPending}
          onSubmit={(values) => add.mutate(values, { onSuccess: () => setOpen(false) })}
          onChange={(values, info, form) => {
            if (info.name === "reason") {
              if (values.reason !== "OTHER") {
                const extraCharge = extraCharges.find((c) => c.concept === values.reason);
                const newAmount = extraCharge?.defaultAmount;
                if (typeof newAmount === "number") {
                  form.setValue("amount", newAmount);
                }

                const vat = extraCharge?.vat;
                if (typeof vat === "number") {
                  form.setValue("vat", vat);
                } else {
                  form.setValue("vat", undefined);
                }
              } else {
                form.setValue("amount", 0);
                form.setValue("vat", 21);
              }
            }
          }}
        />
      </DialogContent>
    </Dialog>
  );
}

type PenaltyInputProps = {
  value: number;
  onChange: (value: number) => void;
  currency: string;
  disabled?: boolean;
};
const PenaltyInput = forwardRef((props: PenaltyInputProps, ref: ForwardedRef<HTMLDivElement>) => {
  const [amount, setAmount] = useState<number | undefined>(props.value);
  const [quantity, setQuantity] = useState<number | "">(1);

  useEffect(() => {
    if (props.value !== (amount || 0) * (quantity || 0)) {
      update(props.value, quantity);
    }
  }, [props.value]);

  return (
    <div className="flex items-center gap-3" ref={ref}>
      <PriceInput
        disabled={props.disabled}
        value={amount}
        onChange={(v) => update(v, quantity)}
        sign={props.currency}
        className="w-32"
      />
      {!props.disabled && (
        <>
          <span>x</span>
          <Input
            type="number"
            className="w-32"
            value={quantity}
            min={1}
            onChange={(e) => {
              update(amount, parseFloat(e.target.value) || "");
            }}
          />
          <span className="text-muted-foreground text-sm">=</span>
          <span className="text-muted-foreground text-sm">{formatPrice(props.value, props.currency)}</span>
        </>
      )}
    </div>
  );

  function update(newAmount: number | undefined, newQuantity: number | "") {
    const newAmountHasValue = typeof newAmount === "number" && !isNaN(newAmount);
    const newQuantityHasValue = typeof newQuantity === "number" && !isNaN(newQuantity);

    setAmount(newAmountHasValue ? newAmount : undefined);
    setQuantity(newQuantityHasValue ? newQuantity : "");

    if (newAmountHasValue && newQuantityHasValue) {
      props.onChange(newAmount * newQuantity);
    } else {
      props.onChange(0);
    }
  }
});

import { api } from "@/lib/api-client";
import { useQuery } from "@tanstack/react-query";

export function useFacilitySettings(facilityId: string) {
  const query = useQuery({
    queryKey: ["facility-settings", facilityId],
    queryFn: async () => {
      return await api.getFacilitySettings({
        facilityId,
      });
    },
    staleTime: 60000,
  });

  return {
    settings: query.data || null,
    isLoading: query.isLoading,
  };
}

import * as z from "zod";
import { Button } from "@/components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useAuth } from "@/lib/useAuth";
import { useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { toast } from "sonner";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import { t } from "i18next";
import { Link } from "react-router-dom";

export function LoginPage() {
  return (
    <div className="h-screen w-screen bg-primary flex justify-center items-center">
      <Card className="sm:w-[450px]">
        <CardHeader>{t("login_title", "Login")}</CardHeader>
        <CardContent>
          <LoginForm />{" "}
        </CardContent>
      </Card>
    </div>
  );
}

export function LoginForm() {
  const formSchema = z.object({
    email: z.string().min(2),
    password: z.string().min(8),
  });

  const auth = useAuth();

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(login)} className="space-y-8">
        <FormField
          control={form.control}
          name="email"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("login_email", "Username")}</FormLabel>
              <FormControl>
                <Input {...field} autoComplete="username" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="password"
          render={({ field }) => (
            <FormItem>
              <FormLabel>{t("login_password", "Password")}</FormLabel>
              <FormControl>
                <Input {...field} type="password" autoComplete="current-password" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="flex justify-between items-center">
          <Button type="submit">{t("login_button", "Login")}</Button>
          <Link to="/external/reset-password">
            <Button variant="link" size="xs">
              {t("login_forgotPassword_button", "Forgot password?")}
            </Button>
          </Link>
        </div>
      </form>
    </Form>
  );

  async function login(values: z.infer<typeof formSchema>) {
    try {
      await auth.login(values.email, values.password);
    } catch {
      toast.error(t("login_error_invalidCredentials", "Invalid username or password"), {});
    }
  }
}

import * as React from "react";
import { Button, ButtonProps } from "./Button";
import { Spinner } from "./Spinner";
import { cn } from "./utils";

export function ButtonCallback(
  props: Omit<ButtonProps, "onClick"> & { onClick: (e: React.MouseEvent) => Promise<unknown> }
) {
  const { onClick, ...otherProps } = props;
  const [isLoading, setIsLoading] = React.useState(false);

  return (
    <Button
      className={cn(otherProps.className, "relative")}
      {...otherProps}
      disabled={otherProps.disabled || isLoading}
      onClick={(e) => {
        setIsLoading(true);
        onClick(e).finally(() => setIsLoading(false));
      }}
    >
      {otherProps.children}
      {isLoading && (
        <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-white/50">
          <Spinner size={18} />
        </div>
      )}
    </Button>
  );
}

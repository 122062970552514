import { api } from "@/lib/api-client";
import { ApiObjects } from "@pulso/api-client";
import { useMutation } from "@/lib/useMutation";

export function useBookingAddPenalty(bookingId: string) {
  return useMutation({
    mutationFn: async (data: ApiObjects["AddPenaltyToBookingBody"]) => {
      await api.addPenaltyToBooking({ bookingId }, data);
    },
    invalidateKeys: [["bookings"]],
  });
}

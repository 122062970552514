import { Form, FormField } from "@/components/ui/form";
import { useForm } from "react-hook-form";
import { useBookingsFilter } from "./useBookingsFilter";
import { Input } from "@/components/ui/input";
import { t } from "i18next";
import { Button } from "@/components/ui/button";

export function BookingsInputSearch() {
  const filter = useBookingsFilter();

  const form = useForm<{ searchTerm: string }>({
    defaultValues: { searchTerm: filter.searchTerm },
    values: { searchTerm: filter.searchTerm },
  });

  return (
    <Form {...form}>
      <div className="flex items-center">
        <form onSubmit={form.handleSubmit((values) => filter.setSearchTerm(values.searchTerm))}>
          <FormField
            name="searchTerm"
            control={form.control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={t("common_placeholder_search", "Search ...")}
                className="w-40 md:w-64 mr-3"
              />
            )}
          />
        </form>
        {filter.searchTerm && (
          <Button onClick={() => filter.setSearchTerm("")}>{t("common_button_clear", "Clear")}</Button>
        )}
      </div>
    </Form>
  );
}

import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";
import { ApiObjects } from "@pulso/api-client";

export function useExtraChargeCreate(id: string | null, action: "create" | "edit") {
  return useMutation({
    mutationFn: async (data: ApiObjects["CreateExtraChargeBody"]) => {
      if (id) {
        if (action === "create") {
          await api.createExtraCharge({ facilityId: id }, data);
        } else {
          await api.updateExtraCharge({ extraChargeId: id }, data);
        }
      }
    },
    invalidateKeys: [["extra-charges"]],
    successMessage: "SAVE_SUCCESS",
  });
}

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { ApiObjects } from "@pulso/api-client";
import { PropsWithChildren, useState } from "react";
import { useStockItemsCreate } from "@/api/useStockItemsCreate";
import { useTranslation } from "react-i18next";
import { z } from "zod";
import { fieldZodTransformer, zodFieldsType } from "@/lib/utils";
import { createForm, PulsoFormProvider } from "@/components/specific/Form";

type NewInventoryFormModalProps = PropsWithChildren<{
  product: ApiObjects["ProductWithExtraDto"];
}>;

export function NewInventoryFormModal({ product, children }: NewInventoryFormModalProps) {
  const [open, setOpen] = useState(false);
  const saveMutation = useStockItemsCreate(product.id);
  const { t } = useTranslation();
  const formSchema = z
    .object({
      count: z.number().min(1).max(200),
      fields: zodFieldsType(product.fields),
    })
    .transform(fieldZodTransformer);

  const form = createForm(
    {
      count: { label: t("stockItems_form_label_count"), type: "number" },
      fields: {
        label: "",
        type: "fields",
        fields: product.fields,
      },
    },
    formSchema
  );

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent hideFooter>
        <DialogHeader>
          <DialogTitle>{t("stockItems_create_title", "Add {{product}}", { product: product.name })}</DialogTitle>
          <DialogDescription>
            {t("stockItems_create_subtitle", "Add {{product}} items you have in stock", { product: product.name })}
          </DialogDescription>
        </DialogHeader>
        <PulsoFormProvider
          className="lg:max-h-96 flex flex-col"
          fieldsClassName="flex flex-col overflow-y-auto"
          {...form}
          isLoading={saveMutation.isPending}
          onSubmit={(values) => saveMutation.mutate(values, { onSuccess: () => setOpen(false) })}
        />
      </DialogContent>
    </Dialog>
  );
}

import { z } from "zod";
import { PulsoFormProvider, createForm } from "../../../components/specific/Form";
import { DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Dialog, DialogTrigger, DialogContent } from "@/components/ui/dialog";
import { PropsWithChildren, useState } from "react";
import { useProductFieldSave } from "@/api/useProductFieldSave";
import { t } from "i18next";

type ProductFieldFormModalType = PropsWithChildren<{
  id: string;
  action: "create" | "edit";
  allowText: boolean;
  field: null | {
    id: string;
    name: string;
    type: "OPTION" | "TEXT";
    options: { id: string; value: string }[];
    priceable: boolean;
    showInDocuments: boolean;
  };
}>;

export function ProductFieldFormModal({ id, action, field, children, allowText }: ProductFieldFormModalType) {
  const [open, setOpen] = useState(false);

  const formSchema = z
    .object({
      name: z.string().min(2).max(50),
      type: action === "edit" ? z.any() : z.enum(["TEXT", "OPTION"]),
      showInDocuments: z.boolean(),
      options: z
        .array(
          z.object({
            id: z.string(),
            value: z.string(),
          })
        )
        .refine((items) => !items.some((i) => i.value.length > 150), {
          message: t("common_error_maxLength", { max: 150 }),
        })
        .refine((items) => !items.some((i) => items.some((j) => i.id !== j.id && i.value === j.value)), {
          message: t("common_error_duplicateValues", "Duplicate values are not allowed"),
        })
        .optional(),
    })
    .superRefine(({ options, type }, ctx) => {
      const typeToValidate = type ?? field?.type;
      if (typeToValidate === "OPTION" && options && options.some((i) => !i.value)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["options"],
          message: t("common_error_emptyValues", "Empty values are not allowed"),
        });
      }
    });
  const form = createForm(
    {
      name: {
        label: t("products_fields_form_label_name", "Name"),
        type: "string",
      },
      type: {
        label: t("products_fields_form_label_type", "Type"),
        type: "select",
        placeholder: "",
        disabled: action === "edit",
        options: [
          ...(allowText ? [{ label: t("products_fields_form_option_text", "Text"), value: "TEXT" }] : []),
          { label: t("products_fields_form_option_options", "Options"), value: "OPTION" },
        ],
      },
      showInDocuments: {
        label: t("products_fields_form_label_showInDocuments", "Show in documents"),
        placeholder: t(
          "products_fields_form_placeholder_showInDocuments",
          "Show this field inside of the products list of a document"
        ),
        type: "switch",
      },
      options: {
        label: t("products_fields_form_label_options", "Options"),
        type: "items",
        hideIf: (values) => (field ? field.type === "TEXT" : values.type === "TEXT"),
        className: "lg:max-h-96 overflow-y-auto px-1",
      },
    },
    formSchema
  );

  const mutation = useProductFieldSave(id, action);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger className="" asChild>
        {children}
      </DialogTrigger>
      <DialogContent hideFooter>
        <DialogHeader>
          <DialogTitle>{t("products_fields_form_title", "Product field")}</DialogTitle>
          <DialogDescription>
            {t("products_fields_form_subtitle", "Define the a field that is related to the product you are offering.")}
          </DialogDescription>
        </DialogHeader>
        <div className="overflow-y-auto">
          <PulsoFormProvider
            {...form}
            isLoading={mutation.isPending}
            onSubmit={(values) =>
              mutation.mutate({ ...values, priceable: Boolean(field?.priceable) }, { onSuccess: () => setOpen(false) })
            }
            initialValues={{
              name: field?.name || "",
              type: field?.type || (allowText ? "TEXT" : "OPTION"),
              options: field?.options || [{ id: "new_" + Math.random(), value: "" }],
              showInDocuments: field?.showInDocuments || true,
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
}

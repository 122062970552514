import { useStockItemPatch } from "@/api/useStockItemPatch";
import { Badge } from "@/components/ui/badge";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { TooltipSimple } from "@/components/ui/tooltip";
import { cn } from "@/lib/utils";
import { ApiObjects } from "@pulso/api-client";
import { formatDate } from "@pulso/utils";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { TFunction } from "i18next";
import { CalendarDays } from "lucide-react";
import { useTranslation } from "react-i18next";

type StockItemStatusProps =
  | {
      status: ApiObjects["StockItemDto"]["status"];
      from: string | null;
      isChangeable?: undefined;
      stockItemId?: undefined;
    }
  | {
      status: ApiObjects["StockItemDto"]["status"];
      from: string | null;
      isChangeable: true;
      stockItemId: string;
    };

export const STATUS_LABEL: Record<StockItemStatusProps["status"], (t: TFunction) => string> = {
  AVAILABLE: (t) => t("stockItems_status_available", "Available"),
  DAMAGED: (t) => t("stockItems_status_damaged", "Damaged"),
  OUTOFSERVICE: (t) => t("stockItems_status_outOfService", "Out of service"),
  LOST: (t) => t("stockItems_status_lost", "Lost"),
};

const CLASS_NAME: Record<StockItemStatusProps["status"], string> = {
  AVAILABLE: "bg-green-100 text-green-800 hover:bg-green-200",
  DAMAGED: "bg-red-100 text-red-800  hover:bg-red-200",
  LOST: "bg-gray-100 text-gray-800 hover:bg-gray-200",
  OUTOFSERVICE: "bg-blue-100 text-blue-800 hover:bg-blue-200",
};

const ALL_STATUSES: StockItemStatusProps["status"][] = ["AVAILABLE", "DAMAGED", "OUTOFSERVICE", "LOST"];

export function StockItemStatus({ status, from, isChangeable, stockItemId }: StockItemStatusProps) {
  const { t } = useTranslation();
  const statuses = ALL_STATUSES.filter((s) => s !== status);
  const updateStatus = useStockItemPatch(stockItemId || "");

  if (!isChangeable) {
    return <StockItemBadge status={status} isChangeable={false} />;
  }

  return (
    <span className="flex items-center">
      <DropdownMenu>
        <DropdownMenuTrigger>
          <StockItemBadge status={status} isChangeable={isChangeable} />
        </DropdownMenuTrigger>
        <DropdownMenuContent onClick={(e) => e.stopPropagation()}>
          {statuses.map((s) => (
            <DropdownMenuItem key={s} onClick={() => updateStatus.mutate({ status: s })}>
              <StockItemBadge status={s} isChangeable={false} />
            </DropdownMenuItem>
          ))}
        </DropdownMenuContent>
      </DropdownMenu>

      {from && (
        <TooltipSimple
          className="ml-2 flex items-center"
          text={t("stockItems_status_unavailableUntill", "Untill {{date}}", {
            date: formatDate(from, null, undefined, "date"),
          })}
        >
          <CalendarDays size={16} className="text-warn" />
        </TooltipSimple>
      )}
    </span>
  );
}

export function StockItemBadge({
  status,
  isChangeable,
}: {
  status: ApiObjects["StockItemDto"]["status"];
  isChangeable?: boolean;
}) {
  const { t } = useTranslation();
  return (
    <Badge
      className={cn(
        CLASS_NAME[status],
        "inline-flex justify-between items-center whitespace-nowrap",
        isChangeable && "pr-1"
      )}
    >
      {STATUS_LABEL[status](t)}
      {isChangeable ? <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" /> : null}
    </Badge>
  );
}

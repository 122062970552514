import { api } from "@/lib/api-client";
import { useQuery } from "@tanstack/react-query";

export function useProductsWithVariants(facilityId: string) {
  const query = useQuery({
    queryKey: ["products", "with-variants", facilityId],
    queryFn: async () => {
      return await api.getAllProductsWithVariantsForFacility({ facilityId });
    },
  });

  return {
    products: query.data || [],
    isLoading: query.isLoading,
  };
}

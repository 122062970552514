import { z } from "zod";

const MAX_FILE_SIZE = 5;
const FILE_TYPES = {
  jpg: "image/jpg",
  jpeg: "image/jpeg",
  gif: "image/gif",
  png: "image/png",
  pdf: "application/pdf",
};

type TFunction = (key: string | string[], defaultText: string, data?: Record<string, string | number>) => string;

export const zodFile = (
  t: TFunction,
  options?: { optional?: boolean; maxSize?: number; allowedTypes?: (keyof typeof FILE_TYPES)[] }
) => {
  const maxSize = options?.maxSize ?? MAX_FILE_SIZE;
  const maxSizeInBytes = maxSize * 1024 * 1024;
  const allowedTypes = options?.allowedTypes || ["jpg", "jpeg", "png", "gif", "pdf"];
  const allowedMimeTypes = allowedTypes.map((type) => FILE_TYPES[type]);

  return z.any().superRefine((file: File | object | string | null, ctx): void => {
    if (typeof file === "object") {
      if (file instanceof File) {
        if (file.size > maxSizeInBytes) {
          addIssue(t("common_error_file_tooLarge", "The maximum allowed file size is {{maxSize}} MB", { maxSize }));
        }

        if (!allowedMimeTypes.includes(file.type)) {
          addIssue(
            t("common_error_file_typeNotSupported", "Only {{allowedFiles}} files are supported", {
              allowedFiles: allowedTypes.map((t) => `.${t}`).join(", "),
            })
          );
        }
      }
    }

    if (!file && !options?.optional) {
      addIssue(t("common_error_required", "Required field"));
    }

    function addIssue(message: string) {
      ctx.addIssue({ code: z.ZodIssueCode.custom, path: [], message });
    }
  });
};

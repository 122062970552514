import { useBookingReschedule } from "@/api/useBookingReschedule";
import { formatDate } from "@/components/specific/DateFormat";
import { Button } from "@/components/ui/button";
import { DateTimePicker } from "@/components/ui/date-time-picker";
import { Form, FormField, FormMessage } from "@/components/ui/form";
import { Label } from "@/components/ui/label";
import { cn } from "@/lib/utils";
import { zodResolver } from "@hookform/resolvers/zod";
import { ApiObjects } from "@pulso/api-client";
import { t } from "i18next";
import { useForm } from "react-hook-form";
import { z } from "zod";

type BookingScheduleProps = {
  booking: ApiObjects["BookingDto"];
  vertical?: boolean;
};

export function BookingSchedule({ booking, ...props }: BookingScheduleProps) {
  const reschedule = useBookingReschedule();

  const formSchema = z
    .object({
      startAt: z.string(),
      endAt: z.string(),
      recalcEndAt: z.boolean(),
    })
    .superRefine(({ startAt, endAt, recalcEndAt }, ctx) => {
      if (startAt > endAt && !recalcEndAt) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ["endAt"],
          message: t("common_error_toDateBeforeFrom", "The return date should be after the delivery date."),
        });
      }
    });

  const form = useForm({
    resolver: zodResolver(formSchema),
    values: { startAt: booking.startAt, endAt: booking.endAt, recalcEndAt: booking.recalcEndAt },
  });

  const submit = form.handleSubmit((values) => reschedule.mutate({ bookingId: booking.id, ...values }));

  const formValues = form.watch();

  return (
    <Form {...form}>
      <form
        className={cn("grid", props.vertical ? "grid-cols-1 gap-6" : "grid-cols-1 md:grid-cols-2 gap-3")}
        onSubmit={submit}
      >
        <FormField
          control={form.control}
          name="startAt"
          render={({ field }) => (
            <div>
              <Label className="whitespace-nowrap">{t("bookings_schedule_startAt", "Pick-up Time")}</Label>
              <DateTimePicker
                className="w-full"
                selected={field.value}
                onSelect={(value) => {
                  field.onChange(value);
                  submit();
                }}
                disabled={booking.status !== "PENDING" && booking.status !== "UNCONFIRMED"}
                disableTime={booking.items.some((i) => i.period.startTime)}
              />
              <FormMessage />
              {booking.deliveredAt ? (
                <div className="mt-3 text-sm text-muted-foreground">
                  {t("bookings_schedule_deliveredAt", "Delivered")}: {formatDate(booking.deliveredAt)}
                </div>
              ) : null}
            </div>
          )}
        />

        <div>
          <FormField
            control={form.control}
            name="endAt"
            render={({ field }) => (
              <>
                <Label className="whitespace-nowrap">{t("bookings_schedule_endAt", "Return Time")}</Label>
                <DateTimePicker
                  className="w-full"
                  selected={booking.endAt}
                  onSelect={(value) => {
                    field.onChange(value);
                    submit();
                  }}
                  disabled={formValues.recalcEndAt || booking.status === "COMPLETED"}
                  fromDate={new Date(formValues.startAt)}
                />
                <FormMessage />
              </>
            )}
          />
          {booking.status !== "COMPLETED" && (
            <FormField
              control={form.control}
              name="recalcEndAt"
              render={({ field }) => (
                <div className="text-right">
                  <Button
                    type="button"
                    variant="link"
                    size="sm"
                    onClick={() => {
                      field.onChange(!field.value);
                      if (!field.value || form.getFieldState("endAt").isDirty) {
                        submit();
                      }
                    }}
                  >
                    {!field.value
                      ? t("booking_schedule_recalculateEndAt_button_lock", "Lock")
                      : t("booking_schedule_recalculateEndAt_button_unlock", "Unlock")}
                  </Button>
                </div>
              )}
            />
          )}
          {booking.returnedAt ? (
            <div className="mt-3 text-sm text-muted-foreground">
              {t("bookings_schedule_returnedAt", "Returned")}: {formatDate(booking.returnedAt)}
            </div>
          ) : null}
        </div>
      </form>
    </Form>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { Input } from "../ui/input";
import { Button } from "../ui/button";
import { t } from "i18next";
import { Trash2 } from "lucide-react";

type ValueType = { id: string; value: string }[];

type ItemsInputProps = {
  value: ValueType;
  onChange(v: ValueType): void;
};

const ItemsInput = React.forwardRef<HTMLDivElement, ItemsInputProps>((props, ref) => {
  const [focusedPossibleNegative, setFocused] = useState(0);
  const focused = Math.abs(focusedPossibleNegative);
  const optionsDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (optionsDivRef.current) {
      const elementToFocus = optionsDivRef.current.children[focused];
      if (elementToFocus) {
        (elementToFocus.firstChild as HTMLInputElement).focus();
      }
    }
  }, [focusedPossibleNegative]);

  return (
    <div ref={ref} className="space-y-3">
      <div className="space-y-3" ref={optionsDivRef}>
        {props.value.map((item) => (
          <div key={item.id} className="space-x-1 flex items-center">
            <Input
              name={item.id}
              value={item.value}
              onChange={(e) => update(item.id, e.target.value)}
              onKeyDown={onKeyDown}
            />{" "}
            <Button variant="ghost" size="sm" onClick={() => remove(item.id)} tabIndex={-1}>
              <Trash2 strokeWidth={1.2} size={20} />
            </Button>
          </div>
        ))}
      </div>
      <Button
        variant="secondary"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          add();
        }}
      >
        {t("common_button_add_options", "Add options")}
      </Button>
    </div>
  );

  function add(index?: number) {
    if (typeof index === "number") {
      const newValue = [...props.value];
      newValue.splice(index, 0, { id: "new_" + Math.random(), value: "" });
      props.onChange(newValue);
      setFocused(index);
    } else {
      props.onChange([...props.value, { id: "new_" + Math.random(), value: "" }]);
      setFocused(props.value.length);
    }
  }

  function update(id: string, value: string) {
    props.onChange(
      props.value.map((v) => {
        if (v.id === id) {
          return { id, value };
        }
        return v;
      })
    );
  }

  function remove(id: string) {
    props.onChange(props.value.filter((v) => v.id !== id));
  }

  function onKeyDown(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      const afterId = (e.target as HTMLInputElement).name;
      const afterIndex = props.value.findIndex((p) => p.id === afterId);
      add(afterIndex + 1);
    }

    if ((e.key === "Delete" || e.key === "Backspace") && !(e.target as HTMLInputElement).value) {
      e.preventDefault();
      e.stopPropagation();
      remove((e.target as HTMLInputElement).name);

      if (e.key === "Backspace") {
        moveFocusUp();
      } else {
        setFocusSafe(-focusedPossibleNegative);
      }
    }

    if (e.key === "ArrowUp") {
      moveFocusUp();
    }

    if (e.key === "ArrowDown") {
      moveFocusDown();
    }
  }

  function moveFocusUp() {
    if (focused > 0) {
      setFocusSafe(focused - 1);
    }
  }

  function moveFocusDown() {
    setFocusSafe(focused + 1);
  }

  function setFocusSafe(f: number) {
    if (f > 0) {
      setFocused(Math.max(0, Math.min(props.value.length - 1, f)));
    } else {
      setFocused(-Math.max(0, Math.min(props.value.length - 1, -f)));
    }
  }
});
ItemsInput.displayName = "ItemsInput";

export { ItemsInput };

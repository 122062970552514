import { PropsWithChildren } from "react";

export function WhatsAppLink(props: PropsWithChildren<{ phone: string; message?: string }>) {
  const phoneParsed = props.phone.replace("+", "");
  const urlParams = new URLSearchParams({ text: props.message || "" });
  const link = `https://wa.me/${phoneParsed}?` + urlParams.toString();

  return (
    <a href={link} target="_blank">
      {props.children}
    </a>
  );
}

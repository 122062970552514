import { api } from "@/lib/api-client";
import { useQuery } from "@tanstack/react-query";

export function useCustomerFields(facilityId: string | undefined) {
  const query = useQuery({
    queryKey: ["customer-fields", facilityId],
    queryFn: async () => {
      if (!facilityId) {
        return [];
      }

      return await api.getCustomerFields({
        facilityId,
      });
    },
  });

  return {
    fields: query.data || [],
    isLoading: query.isLoading,
  };
}

import { useProduct } from "@/api/useProduct";
import { useProducts } from "@/api/useProducts";
import { Separator } from "@/components/ui/separator";
import { Spinner } from "@/components/ui/spinner";
import { cn } from "@/lib/utils";
import { Link, useParams } from "react-router-dom";
import { InventoryDetails } from "./InventoryDetails";
import { useState } from "react";
import { ApiObjects } from "@pulso/api-client";
import { t } from "i18next";
import { StockItemsList } from "./StockItemsList";
import { ComplementsList } from "./ComplementsList";

export function InventoryPage() {
  const products = useProducts();
  const { productId: productIdParam, facilityId } = useParams();
  const productId = productIdParam || products.data?.[0]?.id;
  const { product } = useProduct(productId);

  const [selectedStockItem, setSelectedStockItem] = useState<ApiObjects["StockItemDto"] | null>(null);

  if (!products.data || !product) {
    return <Spinner />;
  }

  return (
    <>
      <div className="space-y-0.5">
        <h2 className="text-2xl font-bold tracking-tight">{t("stockItems_title", "Inventory")}</h2>
        <p className="text-muted-foreground">{t("stockItems_subtitle", "Define all the stock you have in store")}</p>
      </div>
      <Separator className="my-6" />
      <div className="flex flex-wrap text-muted-foreground text-sm">
        {products.data.map((product) => (
          <Link key={product.id} to={`/facility/${facilityId}/inventory/${product.id}`}>
            <div
              className={cn(
                "px-5 py-2 border-b-solid border-gray-300 border-b whitespace-nowrap",
                productId === product.id && "border-b-2 border-primary"
              )}
            >
              {product.name}
            </div>
          </Link>
        ))}
      </div>

      {product.identifiable ? (
        <StockItemsList product={product} onSelect={(item) => setSelectedStockItem(selectedStockItem ? null : item)} />
      ) : (
        <ComplementsList product={product} onSelect={() => {}} />
      )}
      <InventoryDetails
        product={product}
        stockItemId={selectedStockItem ? selectedStockItem.id : ""}
        open={!!selectedStockItem}
        onOpenChange={() => setSelectedStockItem(null)}
      />
    </>
  );
}

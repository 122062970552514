import { api } from "@/lib/api-client";
import { useInfiniteScrollQuery } from "@/lib/useInfiniteScrollQuery";
import { ApiObjects } from "@pulso/api-client";

export function useStockItems(productId: string | undefined, pageSize = 25) {
  const query = useInfiniteScrollQuery({
    queryKey: ["stock-items", productId],
    queryFn: async (context) => {
      if (!productId) {
        return {
          items: [] as ApiObjects["StockItemWithBookingDto"][],
          pageSize,
          page: 1,
          total: 0,
        };
      }

      return await api.getAllStockItemsForProduct({ productId, page: context.pageParam, pageSize });
    },
  });

  return query;
}

import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";
import { ApiObjects } from "@pulso/api-client";

export function useBookingReschedule() {
  return useMutation({
    mutationFn: (data: { bookingId: string } & ApiObjects["RescheduleBookingBody"]) => {
      return api.rescheduleBooking({ bookingId: data.bookingId }, data);
    },
    invalidateKeys: [["bookings"], ["stock-items"], ["bookings-calendar"]],
    successMessage: "SAVE_SUCCESS",
  });
}

import { Button } from "@/components/ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { EditIcon, Trash2Icon, FileText } from "lucide-react";
import { ProductFieldFormModal } from "./ProductFieldFormModal";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { useProductFieldDelete } from "@/api/useProductFieldDelete";
import { t } from "i18next";
import { ApiObjects } from "@pulso/api-client";
import { Switch } from "@/components/ui/switch";
import { useProductFieldUpdate } from "@/api/useProductFieldUpdate";

type ProductFieldsProps = {
  product: ApiObjects["ProductWithExtraDto"];
};

export function ProductFields({ product }: ProductFieldsProps) {
  const deleteMutation = useProductFieldDelete();
  const updatePriceable = useProductFieldUpdate();

  const { fields } = product;

  const FIELD_TYPE_LABELS: Record<ApiObjects["ProductFieldDto"]["type"], string> = {
    TEXT: t("products_fields_form_option_text"),
    OPTION: t("products_fields_form_option_options"),
  };

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-[100px]">{t("product_fields_table_header_field", "Field")}</TableHead>
          <TableHead>{t("product_fields_table_header_type", "Type")}</TableHead>
          <TableHead>{t("product_fields_table_header_priceable", "Defines a variant")}</TableHead>
          <TableHead>{t("product_fields_table_header_extra", "Extra options")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {fields.map((field) => (
          <TableRow key={field.id}>
            <TableCell className="font-medium text-nowrap">{field.name}</TableCell>
            <TableCell>{FIELD_TYPE_LABELS[field.type]}</TableCell>
            <TableCell>
              {field.type === "OPTION" && (
                <Switch
                  disabled={!product.identifiable}
                  checked={field.priceable}
                  onCheckedChange={(priceable) => updatePriceable.mutate({ ...field, priceable })}
                />
              )}
            </TableCell>
            <TableCell>{field.showInDocuments && <FileText size={16} />}</TableCell>
            <TableCell className="text-right">
              <ProductFieldFormModal id={field.id} action="edit" field={field} allowText={true}>
                <Button variant="ghost">
                  <EditIcon strokeWidth={1.2} />
                </Button>
              </ProductFieldFormModal>
              <AlertDialog>
                <AlertDialogTrigger asChild>
                  <Button variant="ghost">
                    <Trash2Icon strokeWidth={1.2} />
                  </Button>
                </AlertDialogTrigger>
                <AlertDialogContent>
                  <AlertDialogHeader>
                    <AlertDialogTitle>
                      {t("product_fields_delete_title", "Delete {{field}}", { field: field.name })}
                    </AlertDialogTitle>
                    <AlertDialogDescription>
                      {t("product_fields_delete_subtitle", "Are you sure you want to delete this field?")}
                    </AlertDialogDescription>
                  </AlertDialogHeader>
                  <AlertDialogFooter>
                    <AlertDialogCancel>{t("common_button_cancel")}</AlertDialogCancel>
                    <AlertDialogAction onClick={() => deleteMutation.mutate(field.id)}>
                      {t("common_button_delete")}
                    </AlertDialogAction>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { PropsWithChildren, useState } from "react";
import { useProductCreate } from "@/api/useProductCreate";
import { t } from "i18next";
import { createProductForm } from "./createProductForm";
import { PulsoFormProvider } from "@/components/specific/Form";

export function NewProductModal(props: PropsWithChildren) {
  const [open, setOpen] = useState(false);
  const form = createProductForm();

  const createProduct = useProductCreate();
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{props.children}</DialogTrigger>
      <DialogContent className="lg:max-w-[425px]" hideFooter>
        <DialogHeader>
          <DialogTitle>{t("products_create_title", "New product")}</DialogTitle>
          <DialogDescription>
            {t("products_create_subtitle", "Define the a product that you are offering")}
          </DialogDescription>
        </DialogHeader>
        <PulsoFormProvider
          {...form}
          isLoading={createProduct.isPending}
          onSubmit={(values) =>
            createProduct.mutate(
              {
                name: values.name,
                description: values.description,
                identifiable: values.productType === "unitary",
                isPackage: values.productType === "package",
              },
              { onSuccess: () => setOpen(false) }
            )
          }
          initialValues={{ name: "", description: "", productType: "unitary" }}
        />
      </DialogContent>
    </Dialog>
  );
}

import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { ApiObjects } from "@pulso/api-client";
import { PropsWithChildren, useEffect, useState } from "react";
import { Button } from "@/components/ui/button";
import { useTranslation } from "react-i18next";
import { FileInput } from "@pulso/components/lib/FileInput";
import { parse } from "csv-parse/browser/esm/sync";
import { stringify } from "csv-stringify/browser/esm";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { api } from "@/lib/api-client";
import { Spinner } from "@/components/ui/spinner";
import { useQueryClient } from "@tanstack/react-query";
import { TooltipSimple } from "@/components/ui/tooltip";
import { Warning } from "@/components/specific/Warning";

type NewInventoryFormModalProps = PropsWithChildren<{
  product: ApiObjects["ProductWithExtraDto"];
}>;

export function ImportInventoryFormModal({ product, children }: NewInventoryFormModalProps) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("stockItems_import_title", "Import items")}</DialogTitle>
          <DialogDescription>
            {t("stockItems_import_subtitle", "Import items for {{product}} from a CSV file", { product: product.name })}
          </DialogDescription>
        </DialogHeader>
        {open && <ImportInventoryForm product={product} onDone={() => setOpen(false)} />}
      </DialogContent>
    </Dialog>
  );
}

export function ImportInventoryForm({
  product,
  onDone,
}: {
  product: ApiObjects["ProductWithExtraDto"];
  onDone: () => void;
}) {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [progress, setProgress] = useState({ done: 0, total: 0 });
  const [data, setData] = useState<Array<Record<"identifier" | string, string>>>([]);
  const columns = [
    { name: "identifier", label: t("stockItems_import_heading_identifier", "Identifier") },
    ...product.fields.map((f) => ({ name: f.id, label: f.name })),
  ];

  useEffect(() => {
    if (file) {
      onFileChange(file);
    } else {
      setData([]);
      setError(null);
    }
  }, [file]);

  return (
    <>
      <div className="space-y-6">
        <div className="space-y-3">
          <div>{t("stockItems_import_downloadStep", "Step 1: Download the template file.")}</div>
          <Button variant="outline" onClick={onDownload}>
            {t("stockItems_import_downloadTemplate", "Download template")}
          </Button>
        </div>

        <div>{t("stockItems_import_editStep", "Step 2: Fill the template with all your stock items.")}</div>

        <div className="space-y-3">
          <div>{t("stockItems_import_uploadStep", "Step 3: Upload the file")}</div>
          <FileInput value={file} onChange={setFile} />
        </div>

        {error && <div className="text-sm text-destructive">{error}</div>}
        {data.length > 0 && (
          <div className="max-h-96 w-[460px] overflow-y-auto overflow-x-auto">
            <Table>
              <TableHeader>
                <TableRow>
                  {columns.map((col) => (
                    <TableHead>{col.label}</TableHead>
                  ))}
                </TableRow>
              </TableHeader>
              <TableBody>
                {data.map((row, rowIndex) => (
                  <TableRow key={rowIndex}>
                    {columns.map((col) => (
                      <TableCell key={col.name + "_" + rowIndex}>
                        {row["_" + col.name] ? (
                          <TooltipSimple text={row["_" + col.name]} className="flex items-center space-x-2">
                            <span className="text-destructive">{row[col.name]}</span>
                            <Warning />
                          </TooltipSimple>
                        ) : (
                          row[col.name]
                        )}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        )}
      </div>
      {progress.total > 0 && (
        <div className="flex space-x-3 items-center">
          <div>
            <Spinner />
          </div>
          <div>{t("stockItems_import_progress", "Progress: {{done}} / {{total}}", progress)}</div>
        </div>
      )}
      <DialogFooter>
        <Button disabled={!!error || !file} onClick={importData}>
          {t("stockItems_import_button_import", "Import")}
        </Button>
      </DialogFooter>
    </>
  );

  async function onDownload() {
    const { items: stockItems } = await api.getAllStockItemsForProduct({
      productId: product.id,
      page: 1,
      pageSize: 1000,
    });

    if (stockItems.length === 0) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      stockItems.push({ identifier: "", fields: [] } as any);
    }

    const data = stockItems.map((item) => ({
      [t("stockItems_import_heading_identifier", "Identifier")]: item.identifier,
      ...product.fields.reduce(
        (obj, field) => ({ ...obj, [field.name]: item.fields.find((f) => f.fieldId === field.id)?.value }),
        {}
      ),
    }));

    stringify(data, { header: true, encoding: "utf-8", delimiter: ";" }, (error, csv) => {
      if (!error) {
        download("export.csv", csv);
      }
    });

    function download(filename: string, text: string) {
      const element = document.createElement("a");
      element.setAttribute("href", "data:text/csv;charset=utf-8," + encodeURIComponent(text));
      element.setAttribute("download", filename);

      element.style.display = "none";
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
  }

  function onFileChange(file: File) {
    file.text().then((buff) => {
      try {
        setError(null);
        const parsedDataWithHeader = parse(buff, {
          delimiter: ";",
          columns,
          trim: true,
          skipEmptyLines: true,
        }) as Array<Record<"identifier" | string, string>>;
        const parsedData = parsedDataWithHeader.slice(1).filter((row) => Object.values(row).join("").length > 0);
        parsedData.forEach((row) => {
          const { identifier, ...fields } = row;
          const fieldsArray = Object.entries(fields).map(([fieldId, value], i) => {
            const field = product.fields[i];

            if (!identifier) {
              row["_identifier"] = t("stockItems_import_errors_missingIdentifier", "Missing identifier");
              row["_hasErrors"] = "true";
            }

            if (field.type === "TEXT") {
              return { fieldId, value };
            } else {
              const opt = field.options.find((opt) => opt.value === value);
              if (value && !opt) {
                row["_" + fieldId] = t("stockItems_import_errors_missingOption", "Missing option");
                row["_hasErrors"] = "true";
              }
              return { fieldId, value: opt?.id || "" };
            }
          });
          row["_fields"] = JSON.stringify(fieldsArray);
        });
        if (parsedData.some((row) => row["_hasErrors"])) {
          setError(
            t(
              "stockItems_import_errors_dataErrors",
              "There are problems with some of the stock items you are trying to import."
            )
          );
          setData(parsedData.filter((row) => row["_hasErrors"]));
        } else if (parsedData.length === 0) {
          setError(t("stockItems_import_errors_emptyFile", "There file is empty."));
          setData([]);
        } else {
          setData(parsedData);
        }
      } catch (er) {
        if (typeof er === "object" && er !== null && "message" in er && er.message && typeof er.message === "string") {
          setError(er.message);
        }
      }
    });
  }

  async function importData() {
    setProgress({ done: 0, total: data.length });

    const { items: stockItems } = await api.getAllStockItemsForProduct({
      productId: product.id,
      page: 1,
      pageSize: 1000,
    });

    let i = 0;
    for (const row of data) {
      const { identifier, _fields } = row;

      const fieldsArray = JSON.parse(_fields);

      const existingItem = stockItems.find((i) => i.identifier === identifier);

      if (existingItem) {
        await api.updateStockItem(
          {
            stockItemId: existingItem.id,
          },
          {
            identifier,
            notes: existingItem.notes,
            fields: fieldsArray,
          }
        );
      } else {
        const [item] = await api.createStockItems(
          {
            productId: product.id,
          },
          {
            count: 1,
            fields: fieldsArray,
          }
        );
        await api.patchStockItem(
          {
            stockItemId: item.id,
          },
          {
            identifier,
          }
        );
      }
      i++;
      setProgress({ done: i, total: data.length });
    }

    queryClient.invalidateQueries({ queryKey: ["bookings"] });
    queryClient.invalidateQueries({ queryKey: ["stock-items"] });

    setProgress({ done: 0, total: 0 });
    onDone();
  }
}

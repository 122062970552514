import { cn } from "./utils";
import "./assets/flags.css";
import { useMemo } from "react";

export function LanguageFlag(props: {
  lang: string | undefined;
  size?: "sm" | "md" | "lg" | "xl";
  className?: string;
}) {
  const langStr = useMemo(() => {
    if (typeof props.lang !== "string") {
      return "";
    }

    if (props.lang === "en") {
      return "GB";
    }

    return props.lang.toUpperCase();
  }, [props.lang]);

  return <span className={cn("fflag", "fflag-" + langStr, "ff-" + (props.size || "sm"), props.className)} />;
}

import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";

export function useCustomerFieldsDelete() {
  return useMutation({
    mutationFn: async (fieldId: string) => {
      return await api.deleteCustomerField({ fieldId });
    },
    invalidateKeys: [["customer-fields"]],
    successMessage: "DELETE_SUCCESS",
  });
}

import { z } from "zod";
import { DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Dialog, DialogTrigger, DialogContent } from "@/components/ui/dialog";
import { PropsWithChildren, useState } from "react";
import { Button } from "@/components/ui/button";
import { PulsoFormProvider, createForm } from "../../../components/specific/Form";
import { useRentalPeriodCreate } from "@/api/useRentalPeriodCreate";
import { t } from "i18next";
import { minutesToPeriod } from "@/components/specific/Period";
import { ApiObjects } from "@pulso/api-client";
import { useTimeSlots } from "./useTimeSlots";
import { useRentalPeriodUpdate } from "@/api/useRentalPeriodUpdate";

type RentalPeriodFormModalType = PropsWithChildren<{
  id: string;
  action: "create" | "edit";
  rentalPeriod: ApiObjects["RentalPeriodDto"] | null;
}>;

const RENTAL_OPTIONS: Array<{ label: string; value: string }> = [];

for (let hours = 0; hours < 12; hours++) {
  for (let minutes = 0; minutes < 60; minutes += 30) {
    const value = hours * 60 + minutes;
    if (value > 0) {
      RENTAL_OPTIONS.push({
        label: minutesToPeriod(value),
        value: value + "",
      });
    }
  }
}

for (let days = 1; days <= 30; days++) {
  const value = days * 1440;
  RENTAL_OPTIONS.push({
    label: minutesToPeriod(value),
    value: value + "",
  });
}

const getNewPeriodValues = () => ({
  name: "",
  period: 1,
  startTime: null,
  endAtClosing: true,
});

export function RentalPeriodFormModal({ id, action, rentalPeriod, children }: RentalPeriodFormModalType) {
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState<Omit<ApiObjects["RentalPeriodDto"], "id">>(rentalPeriod || getNewPeriodValues());

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent hideFooter>
        <DialogHeader>
          <DialogTitle>{t("settings_rentalPeriods_form_title", "Rental period")}</DialogTitle>
          <DialogDescription>
            {t(
              "settings_rentalPeriods_form_subtitle",
              "Define the a field that is related to the product you are offering"
            )}
          </DialogDescription>
        </DialogHeader>
        {action === "create" || !values ? (
          <RentalPeriodCreateForm id={id} rentalPeriod={values} onClose={onCloseAfterCreate} />
        ) : (
          <RentalPeriodUpdateForm id={id} rentalPeriod={values} onClose={onCloseAfterUpdate} />
        )}
      </DialogContent>
    </Dialog>
  );

  function onCloseAfterCreate(values: Omit<ApiObjects["RentalPeriodDto"], "id">, createNew: boolean) {
    if (!createNew) {
      onCloseAfterUpdate(getNewPeriodValues());
    } else {
      const period = values.period;
      const offset = period < 1440 ? 60 : 1440; // 1h or 1 day

      setValues({
        ...values,
        name: values.name.replace(/(\d)+/, (d) => (parseInt(d) + 1).toString()),
        period: period + offset,
      });
    }
  }

  function onCloseAfterUpdate(values: Omit<ApiObjects["RentalPeriodDto"], "id">) {
    setValues(values);
    setOpen(false);
  }
}

export function RentalPeriodCreateForm({
  id,
  rentalPeriod,
  onClose,
}: {
  id: string;
  rentalPeriod: Omit<ApiObjects["RentalPeriodDto"], "id">;
  onClose: (values: Omit<ApiObjects["RentalPeriodDto"], "id">, createNew: boolean) => void;
}) {
  const formSchema = z.object({
    name: z.string().min(2).max(50),
    period: z.number().min(1, { message: t("common_error_required", "Mandatory field") }),
    startTime: z.preprocess((n) => (typeof n === "string" && n ? parseInt(n) : null), z.number().nullable()),
    endAtClosing: z.boolean(),
  });

  const formFields = useRentalPeriodFormFields("create");
  const form = createForm(formFields, formSchema);

  const save = useRentalPeriodCreate(id);
  return (
    <PulsoFormProvider
      {...form}
      onSubmit={(values, e) =>
        save.mutate(values, {
          onSuccess: () => onClose(values, (e?.nativeEvent as SubmitEvent)?.submitter?.id === "saveAndCreateNew"),
        })
      }
      isLoading={save.isPending}
      initialValues={rentalPeriod}
      values={rentalPeriod}
      extraButtons={
        <Button variant="outline" id="saveAndCreateNew">
          {t("settings_rentalPeriods_form_saveAndCreateNew_button", "Save and create new")}
        </Button>
      }
    />
  );
}

export function RentalPeriodUpdateForm({
  id,
  rentalPeriod,
  onClose,
}: {
  id: string;
  rentalPeriod: Omit<ApiObjects["RentalPeriodDto"], "id">;
  onClose: (values: Omit<ApiObjects["RentalPeriodDto"], "id">) => void;
}) {
  const formSchema = z.object({
    name: z.string().min(2).max(50),
    endAtClosing: z.boolean(),
  });

  const formFields = useRentalPeriodFormFields("edit");
  const form = createForm(formFields, formSchema);
  const save = useRentalPeriodUpdate(id);

  return (
    <PulsoFormProvider
      {...form}
      isLoading={save.isPending}
      onSubmit={(values) => save.mutate(values, { onSuccess: (_, v) => onClose({ ...rentalPeriod, ...v }) })}
      initialValues={rentalPeriod}
    />
  );
}

function useRentalPeriodFormFields(action: "create" | "edit") {
  const { slots } = useTimeSlots();

  return {
    name: {
      label: t("settings_rentalPeriods_form_label_name", "Name"),
      type: "string" as const,
    },
    period: {
      label: t("settings_rentalPeriods_form_label_duration", "Duration"),
      type: "period" as const,
      disabled: action === "edit",
    },
    startTime: {
      label: t("settings_rentalPeriods_form_label_fixedStartTime", "Fixed start time"),
      info: t("settings_rentalPeriods_info_startTime"),
      type: "selectNative" as const,
      options: [
        { value: "", label: t("settings_rentalPeriods_form_option_noFixedStartTime", "No fixed start time") },
        ...slots,
      ],
      disabled: action === "edit",
    },
    endAtClosing: {
      label: t("settings_rentalPeriods_form_label_returnCalculation", "Return auto calculation"),
      type: "radio" as const,
      info: t("settings_rentalPeriods_info_returnCalculation"),
      options: [
        {
          value: "true",
          typedValue: true,
          label: t("settings_rentalPeriods_returnCalculation_option_endAtClosing"),
        },
        {
          value: "false",
          typedValue: false,
          label: t("settings_rentalPeriods_returnCalculation_option_endAtPeriodEnd"),
        },
      ],
    },
  };
}

import { api } from "@/lib/api-client";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

export function useMeFacilities() {
  const query = useQuery({
    queryKey: ["me", "facilities"],
    queryFn: async () => await api.getAllFacilitiesForUser(),
    placeholderData: keepPreviousData,
  });

  return {
    facilities: query.data || [],
    isLoading: query.isLoading,
  };
}

import { useRentalPeriodDelete } from "@/api/useRentalPeriodDelete";
import { useRentalPeriods } from "@/api/useRentalPeriods";
import { Period } from "@/components/specific/Period";
import {
  AlertDialog,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogCancel,
  AlertDialogAction,
  AlertDialogHeader,
  AlertDialogFooter,
} from "@/components/ui/alert-dialog";
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from "@/components/ui/table";
import { t } from "i18next";
import { EditIcon, Trash2Icon } from "lucide-react";
import { RentalPeriodFormModal } from "./RentalPeriodFormModal";
import { Button } from "@/components/ui/button";
import { useParams } from "react-router-dom";
import { Spinner } from "@/components/ui/spinner";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { minutesToLabel } from "@pulso/utils";
import { TimeGranularityForm } from "../TimeGranularityForm";
import { InfoBoxWrap } from "@/components/specific/InfoBoxWrap";
import { Seasons } from "./seasons/Seasons";

export function RentalPeriodsPage() {
  const { periods } = useRentalPeriods();
  const deletePeriod = useRentalPeriodDelete();
  const { facilityId } = useParams();

  if (!facilityId) {
    return <Spinner />;
  }

  return (
    <>
      <Card>
        <CardHeader>
          <CardTitle>{t("settings_rentalPeriods_title", "Rental periods")}</CardTitle>
          <CardDescription>
            {t(
              "settings_rentalPeriods_description",
              "Add all periods that are available in your facility (e.g. 1 hour, 1 day, 1 week, ...)"
            )}
          </CardDescription>
        </CardHeader>
        <CardContent className="space-y-6">
          <div>
            <RentalPeriodFormModal id={facilityId} action="create" rentalPeriod={null}>
              <Button variant="outline">{t("settings_rentalPeriods_addButton", "Add rental period")}</Button>
            </RentalPeriodFormModal>
          </div>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>{t("settings_rentalPeriods_tableHeader_name", "Name")}</TableHead>
                <TableHead>{t("settings_rentalPeriods_tableHeader_period", "Period")}</TableHead>
                <TableHead>
                  <InfoBoxWrap
                    tooltip={t(
                      "settings_rentalPeriods_info_startTime",
                      "If 'Fixed start time' is enabled, then bookings will be forced to start at the exact time specified."
                    )}
                  >
                    {t("settings_rentalPeriods_tableHeader_startTime", "Fixed start time")}
                  </InfoBoxWrap>
                </TableHead>
                <TableHead>
                  <InfoBoxWrap
                    tooltip={t(
                      "settings_rentalPeriods_info_returnCalculation",
                      "The return date and time of a booking are automatically calculated according to the 'Opening hours' or the 'Full period duration'. For 'Opening hours', a 1 day booking starting at 10:00 am will automatically end at the facility closing time (e.g. 5:00 pm). For 'Full period duration' the booking will last for the full duration - 10:00 am to 10:00 am on the next day."
                    )}
                  >
                    {t("settings_rentalPeriods_tableHeader_returnCalculation", "Return auto calculation")}
                  </InfoBoxWrap>
                </TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {periods.map((period) => (
                <TableRow key={period.id}>
                  <TableCell>{period.name}</TableCell>
                  <TableCell>
                    <Period minutes={period.period} />
                  </TableCell>
                  <TableCell>{period.startTime ? minutesToLabel(period.startTime) : "-"}</TableCell>
                  <TableCell>
                    {period.endAtClosing
                      ? t("settings_rentalPeriods_returnCalculation_option_endAtClosing", "Opening hours")
                      : t("settings_rentalPeriods_returnCalculation_option_endAtPeriodEnd", "Full period duration")}
                  </TableCell>
                  <TableCell align="right">
                    <RentalPeriodFormModal id={period.id} action="edit" rentalPeriod={period}>
                      <Button variant="ghost">
                        <EditIcon strokeWidth={1.2} />
                      </Button>
                    </RentalPeriodFormModal>

                    <AlertDialog>
                      <AlertDialogTrigger>
                        <Trash2Icon strokeWidth={1.2} />
                      </AlertDialogTrigger>
                      <AlertDialogContent>
                        <AlertDialogHeader>
                          <AlertDialogTitle>
                            {t("settings_rentalPeriods_delete_title", "Delete {{period}}", { period: period.name })}
                          </AlertDialogTitle>
                          <AlertDialogDescription>
                            {t("settings_rentalPeriods_delete_subtitle", "Are you sure you want to delete this field?")}
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                          <AlertDialogCancel>{t("common_button_cancel")}</AlertDialogCancel>
                          <AlertDialogAction onClick={() => deletePeriod.mutate(period.id)}>
                            {t("common_button_delete")}
                          </AlertDialogAction>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      <Card className="mt-6">
        <CardHeader>
          <CardTitle>{t("settings_timeGranularity_title", "Time granularity")}</CardTitle>
          <CardDescription>
            {t(
              "settings_timeGranularity_description",
              "Define the intervals in which to split time. For instance, if 15 minutes is chosen, then a booking could start at 9:00, 9:15, 9:30, 9:45, etc..."
            )}
          </CardDescription>
        </CardHeader>

        <CardContent>
          <TimeGranularityForm facilityId={facilityId} />
        </CardContent>
      </Card>
      <Card className="mt-6">
        <CardHeader>
          <CardTitle>{t("settings_seasons_title", "Seasons")}</CardTitle>
          <CardDescription>
            {t("settings_seasons_description", "Define seasons in which prices of your products vary.")}
          </CardDescription>
        </CardHeader>

        <CardContent>
          <Seasons facilityId={facilityId} />
        </CardContent>
      </Card>
    </>
  );
}

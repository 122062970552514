import { t } from "i18next";
import { Fragment } from "react/jsx-runtime";

export function minutesToPeriod(minutes: number) {
  return minutesToPeriodParts(minutes).join(" ");
}

export function minutesToPeriodParts(minutes: number) {
  const months = Math.floor(minutes / (30 * 1440));
  minutes %= 30 * 1440;
  const weeks = Math.floor(minutes / (7 * 1440));
  minutes %= 7 * 1440;
  const days = Math.floor(minutes / 1440);
  minutes %= 1440;
  const hours = Math.floor(minutes / 60);
  minutes %= 60;
  const mins = minutes;

  const parts: string[] = [];
  if (months > 0) {
    if (months === 1) {
      parts.push(t("common_period_month", "1 month"));
    } else {
      parts.push(t("common_period_months", "{{num}} months", { num: months }));
    }
  }
  if (weeks > 0) {
    if (weeks === 1) {
      parts.push(t("common_period_week", "1 week"));
    } else {
      parts.push(t("common_period_weeks", "{{num}} weeks", { num: weeks }));
    }
  }
  if (days > 0) {
    if (days === 1) {
      parts.push(t("common_period_day", "1 day"));
    } else {
      parts.push(t("common_period_days", "{{num}} days", { num: days }));
    }
  }
  if (hours > 0) {
    if (hours === 1) {
      parts.push(t("common_period_hour", "1 hour"));
    } else {
      parts.push(t("common_period_hours", "{{num}} hours", { num: hours }));
    }
  }
  if (mins > 0) {
    if (mins === 1) {
      parts.push(t("common_period_minute", "1 minute"));
    } else {
      parts.push(t("common_period_minutes", "{{num}} minutes", { num: mins }));
    }
  }

  return parts;
}

export function Period({ minutes }: { minutes: number }) {
  const parts = minutesToPeriodParts(minutes);

  return (
    <>
      {parts.map((p, i) => (
        <Fragment key={p}>
          <span key={p}>{p}</span>
          {i < parts.length - 1 && <> </>}
        </Fragment>
      ))}
    </>
  );
}

import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { CellClickedEvent, GridApi, IGetRowsParams } from "ag-grid-community";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { GridData } from "./GridData";
import { useBookingsFilter } from "../useBookingsFilter";
import { api } from "@/lib/api-client";
import { ApiObjects } from "@pulso/api-client";
import "./BookingGrid.css";
import { useColumnDefinitions } from "./useColumnDefinitions";
import { getDefaultStore, useAtomValue, useSetAtom } from "jotai";
import { currentGridStateAtom, currentGridStateVersionAtom, gridRowHeightAtom, hasChangesAtom } from "./useGridState";
import { useEffect, useRef } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { formatDate } from "@pulso/utils";
import { CentralSpinner } from "@/components/ui/central-spinner";

type BookingGridProps = {
  facilityId: string;
};

export function BookingGrid(props: BookingGridProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const colDefs = useColumnDefinitions(props.facilityId);
  const filter = useBookingsFilter();
  const updateCurrentGridState = useSetAtom(currentGridStateAtom);
  const setHasChanges = useSetAtom(hasChangesAtom);
  const currentGridStateVersion = useAtomValue(currentGridStateVersionAtom);
  const apiRef = useRef<GridApi | null>(null);
  const gridRowHeight = useAtomValue(gridRowHeightAtom);
  const queryClient = useQueryClient();

  // Hack to reload the grid when a booking status is changed
  useQuery({ queryKey: ["bookings", "fake"], queryFn: () => Math.random() });

  useEffect(loadState, [currentGridStateVersion]);
  useEffect(resetDatasource, [filter]);

  if (!colDefs.length) {
    return <CentralSpinner />;
  }

  return (
    <div className="ag-theme-quartz w-full h-full flex-1 relative">
      <AgGridReact
        columnDefs={colDefs}
        defaultColDef={{
          cellStyle: { lineHeight: gridRowHeight + "px" },
          minWidth: 50,
          unSortIcon: true,
          headerClass: "flex-1",
        }}
        onGridReady={(e) => {
          apiRef.current = e.api;
          loadState();
        }}
        onColumnMoved={onStateChange}
        onColumnResized={onStateChange}
        onColumnVisible={onStateChange}
        onSortChanged={onStateChange}
        rowHeight={gridRowHeight}
        cellSelection={false}
        enableCellTextSelection={true}
        onCellClicked={onCellClicked}
        suppressCellFocus={true}
        rowClass="cursor-pointer"
        rowModelType="infinite"
        cacheBlockSize={filter.pageSize}
        noRowsOverlayComponent={() => (
          <div>
            {t("bookings_grid_empty", "No bookings found for the period {{from}} - {{to}}", {
              from: formatDate(filter.date, null, undefined, "date"),
              to: formatDate(filter.dateTo, null, undefined, "date"),
            })}
          </div>
        )}
      />
    </div>
  );

  function loadState() {
    if (apiRef.current && colDefs.length > 0) {
      const defaultStore = getDefaultStore();
      const currentGridState = defaultStore.get(currentGridStateAtom);
      apiRef.current.applyColumnState({ state: currentGridState.columns, applyOrder: true });
      if (!apiRef.current.getGridOption("datasource")) {
        resetDatasource();
      }
    }
  }

  function resetDatasource() {
    apiRef.current?.setGridOption("datasource", { getRows });
  }

  function getRows(params: IGetRowsParams) {
    const sortingCol = params.sortModel[0];
    const sortColDef = colDefs.find((c) => c.field === sortingCol?.colId);

    const sorting = sortColDef
      ? {
          sort: (sortColDef.context?.sortParam ?? sortColDef.field) as ApiObjects["BookingsQuery"]["sort"],
          sortOrder: sortingCol.sort,
          ...(sortColDef.context?.sortCustomerFieldId ? { sortFieldId: sortColDef.context?.sortCustomerFieldId } : {}),
        }
      : {};

    const page = params.startRow / filter.pageSize + 1;
    const filterData = {
      ...filter.forApi(page),
      ...sorting,
    };
    queryClient
      .fetchQuery({
        queryKey: ["bookings", props.facilityId, filterData],
        queryFn: () => api.getBookings({ facilityId: props.facilityId, ...filterData }),
        staleTime: 60 * 60 * 1000,
      })
      .then((data) => {
        params.successCallback(data.bookings, data.total);
        if (data.total === 0) {
          apiRef.current?.showNoRowsOverlay();
        }
      });
  }

  function onCellClicked(e: CellClickedEvent<GridData>) {
    // Disable navigation if text is selected
    const selection = window.getSelection();
    if (selection && selection.toString().length > 0) {
      return;
    }

    if (e.data && e.colDef.context?.noClick !== true) {
      navigate(`./${e.data.id}`);
    }
  }

  function onStateChange(e: { api: GridApi; source: string }) {
    if (e.source === "api") {
      return;
    }
    if (e.source === "gridOptionsChanged") {
      loadState();
      return;
    }
    const state = e.api.getColumnState()?.map((c) => ({ colId: c.colId, width: c.width, hide: c.hide, sort: c.sort }));
    updateCurrentGridState({ columns: state });
    setHasChanges(true);
  }
}

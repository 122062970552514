import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";

export function useProductDeleteComplement(productId: string) {
  return useMutation({
    mutationFn: async (complementId: string) =>
      productId ? await api.deleteComplementForProduct({ productId, complementId }, undefined) : null,
    invalidateKeys: [["products"]],
    successMessage: "SAVE_SUCCESS",
  });
}

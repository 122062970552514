export type StringListProps = {
  list: string[];
  max: number;
};

export function StringList(props: StringListProps) {
  return (
    <span title={props.list.join(", ")}>
      {[
        ...props.list.slice(0, props.max),
        ...(props.list.length > props.max ? ["+" + (props.list.length - props.max)] : []),
      ].join(", ")}
      {}
    </span>
  );
}

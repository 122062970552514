import * as React from "react";
import { Button, ButtonProps } from "./button";
import { Spinner } from "./spinner";

type ButtonLoadableProps = ButtonProps & { isLoading?: boolean };

export const ButtonLoadable = React.forwardRef<HTMLButtonElement, ButtonLoadableProps>(
  ({ isLoading, ...props }, ref) => {
    return (
      <Button {...props} disabled={props.disabled || isLoading} ref={ref}>
        {isLoading && <Spinner size={18} className="mr-2" />} {props.children}
      </Button>
    );
  }
);

import { Spinner } from "@/components/ui/spinner";
import { api } from "@/lib/api-client";
import { useAuth } from "@/lib/useAuth";
import { useQuery } from "@tanstack/react-query";
import { Component, ComponentType } from "react";

export function useLanguages(facilityId: string) {
  const query = useQuery({
    queryKey: ["languages", facilityId],
    queryFn: async () => await api.getLanguagesForFacility({ facilityId }),
  });

  return {
    languages: (query.data || []).map((language) => language.language),
    isLoading: query.isLoading,
  };
}

export function withLanguages<P>(Component: ComponentType<P & { languages: string[] }>): ComponentType<P> {
  return function (props: P) {
    const { facilityId } = useAuth();
    const { languages, isLoading } = useLanguages(facilityId + "");

    if (isLoading) {
      return <Spinner />;
    }

    return <Component {...props} languages={languages} />;
  };
}

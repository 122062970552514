import {
  DefaultError,
  UndefinedInitialDataOptions,
  QueryFunctionContext,
  QueryClient,
  UseQueryResult,
  useQuery,
  keepPreviousData,
} from "@tanstack/react-query";
import { usePagination } from "./usePageination";

type TQueryKey = (string | number | undefined)[];
type TPagination = ReturnType<typeof usePagination>;

export function useQueryWithPagination<TQueryFnData = unknown, TError = DefaultError, TData = TQueryFnData>(
  options: Omit<UndefinedInitialDataOptions<TQueryFnData, TError, TData, TQueryKey>, "queryFn"> & {
    pageSize?: number;
    queryFn: (
      pagination: TPagination,
      context: QueryFunctionContext<TQueryKey, never>
    ) => TQueryFnData | Promise<TQueryFnData>;
  },
  queryClient?: QueryClient
): UseQueryResult<TData, TError> & { pagination: TPagination } {
  const pagination = usePagination(options.pageSize || 25);

  const query = useQuery(
    {
      ...options,
      queryKey: [...options.queryKey, pagination.page + ""],
      queryFn: (context) => options.queryFn?.(pagination, context),
      placeholderData: keepPreviousData,
    },
    queryClient
  );

  return { ...query, pagination };
}

import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";

export function useBookingAssignItem(bookingItemId: string) {
  return useMutation({
    mutationFn: async (stockItemId: string) => {
      await api.assignBookingItem({ bookingItemId, stockItemId }, undefined);
    },
    invalidateKeys: [["bookings"], ["stock-items"]],
  });
}

import { useFacilitySettings } from "@/api/useFacilitySettings";
import { ApiObjects } from "@pulso/api-client";
import { ALLOWED_BOOKING_STATUS_TRANSITIONS } from "@pulso/utils";

export function useBookingStatusAllowedTransitions(facilityId: string, status: ApiObjects["BookingDto"]["status"]) {
  const { settings } = useFacilitySettings(facilityId);

  return ALLOWED_BOOKING_STATUS_TRANSITIONS[status]
    .filter((toStatus) => toStatus !== "UNCONFIRMED" || settings?.reviewEnabled)
    .filter((toStatus) => toStatus !== "ON_HOLD" || settings?.onHoldEnabled);
}

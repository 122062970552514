import { api } from "@/lib/api-client";
import { ApiObjects } from "@pulso/api-client";
import { useMutation } from "@/lib/useMutation";

export function useProductFieldUpdate() {
  return useMutation({
    mutationFn: async (data: ApiObjects["UpdateProductFieldBody"] & { id: string }) => {
      await api.updateProductField({ fieldId: data.id }, data);
    },
    invalidateKeys: [["products"]],
  });
}

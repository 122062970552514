import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";
import { ApiObjects } from "@pulso/api-client";

export function useFacilitySettingsPatch(facilityId: string) {
  return useMutation({
    mutationFn: async (data: ApiObjects["PatchFacilitySettingsBody"]) => {
      return await api.patchFacilitySettings({ facilityId }, data);
    },
    invalidateKeys: [["facility-settings"]],
    successMessage: "SAVE_SUCCESS",
  });
}

import * as React from "react";
import * as SwitchPrimitives from "@radix-ui/react-switch";

import { cn } from "@/lib/utils";
import { Spinner } from "@pulso/components/lib/Spinner";

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof SwitchPrimitives.Root> & { isLoading?: boolean }
>(({ className, isLoading, ...props }, ref) => (
  <span className="relative flex items-center">
    <SwitchPrimitives.Root
      {...props}
      className={cn(
        "peer inline-flex h-5 w-9 shrink-0 cursor-pointer items-center rounded-full border-2 border-transparent shadow-sm transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 focus-visible:ring-offset-background disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-primary data-[state=unchecked]:bg-input",
        className
      )}
      ref={ref}
      disabled={props.disabled || isLoading}
    >
      <SwitchPrimitives.Thumb
        className={cn(
          "pointer-events-none block h-4 w-4 rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-4 data-[state=unchecked]:translate-x-0"
        )}
      >
        {isLoading && <Spinner size={16} />}
      </SwitchPrimitives.Thumb>
    </SwitchPrimitives.Root>
  </span>
));
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };

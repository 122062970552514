import { Button } from "./button";
import { t } from "i18next";
import { ChangeEvent, ForwardedRef, forwardRef, useMemo, useRef } from "react";
import { CoverImage } from "./cover-image";
import { CameraIcon } from "lucide-react";

type ImageInputProps = {
  value: File | string;
  onChange(file: File): void;
};

export const ImageInput = forwardRef((props: ImageInputProps, ref: ForwardedRef<HTMLDivElement>) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const imgUrl = useMemo(() => {
    if (typeof props.value === "string") {
      return props.value;
    }

    if (typeof props.value === "object" && props.value instanceof File) {
      return URL.createObjectURL(props.value);
    }

    return null;
  }, [props.value]);

  return (
    <div className="flex space-x-3 items-center" ref={ref}>
      {imgUrl ? (
        <CoverImage src={imgUrl} className="w-32 h-32" />
      ) : (
        <button
          className="w-32 h-32 border border-4 border-dashed rounded-lg flex justify-center items-center"
          onClick={() => inputRef.current?.click()}
          type="button"
        >
          <CameraIcon size={32} strokeWidth={1.2} />
        </button>
      )}
      <div>
        <Button onClick={() => inputRef.current?.click()} type="button" variant="outline">
          {t("common_imageUpload_button", "Upload an image")}
        </Button>
      </div>
      <input type="file" ref={inputRef} className="hidden" onChange={onChange} />
    </div>
  );

  function onChange(event: ChangeEvent) {
    const file = inputRef.current?.files?.[0];

    if (file) {
      props.onChange(file);
    }
  }
});

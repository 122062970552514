import { ApiObjects } from "@pulso/api-client";
import { NewInventoryFormModal } from "./NewInventoryFormModal";
import { useTranslation } from "react-i18next";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import InfiniteScroll from "react-infinite-scroll-component";
import { CentralSpinner } from "@/components/ui/central-spinner";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { useStockItems } from "@/api/useStockItems";
import { useStockItemDelete } from "@/api/useStockItemDelete";
import { cn } from "@/lib/utils";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { Warning } from "@/components/specific/Warning";
import { Trash2Icon } from "lucide-react";
import { ConfirmDialog } from "@/components/ui/confirm-dialog";

export type StockItemsListProps = {
  product: ApiObjects["ProductWithExtraDto"];
  onSelect: (data: ApiObjects["StockItemWithBookingDto"]) => void;
};

export function ComplementsList({ product, onSelect }: StockItemsListProps) {
  const stockItems = useStockItems(product.id);
  const { t } = useTranslation();
  const deleteMutation = useStockItemDelete();

  return (
    <>
      {stockItems.items.length ? (
        <div>
          <div className="my-3 flex justify-end">
            <NewInventoryFormModal product={product}>
              <Button>{t("complements_update_button", "Update stock")}</Button>
            </NewInventoryFormModal>
          </div>
          <Card>
            <CardContent className="overflow-clip p-0">
              <InfiniteScroll
                style={{ overflow: "hidden" }}
                scrollableTarget="main"
                loader={<CentralSpinner />}
                {...stockItems}
              >
                <Table>
                  <TableHeader>
                    <TableRow>
                      {product.fields.length === 0 && <TableHead>{product.name}</TableHead>}
                      {product.fields.map((field) => (
                        <TableHead key={field.id} className={cn(field.priceable && "font-bold")}>
                          {field.name}
                        </TableHead>
                      ))}
                      <TableHead>{t("stockItems_form_label_count")}</TableHead>
                      <TableHead></TableHead>
                    </TableRow>
                  </TableHeader>
                  <TableBody id="scrollableDiv">
                    {stockItems.items.map((item) => (
                      <TableRow key={item.id} className="cursor-pointer" onClick={() => onSelect(item)}>
                        {product.fields.length === 0 && <TableCell>{product.name}</TableCell>}
                        {product.fields.map((productField) => (
                          <TableCell key={productField.id}>
                            {item.fields.find((f) => f.fieldId === productField.id)?.value ??
                              (productField.priceable ? (
                                <Tooltip>
                                  <TooltipTrigger onClick={(e) => e.stopPropagation()}>
                                    <Warning />
                                  </TooltipTrigger>
                                  <TooltipContent>
                                    {t(
                                      "stockItems_warning_missingPrice",
                                      "{{field}} can influence the price of a product. Therefore, a value has to be defined",
                                      { field: productField.name }
                                    )}
                                  </TooltipContent>
                                </Tooltip>
                              ) : (
                                ""
                              ))}
                          </TableCell>
                        ))}
                        <TableCell>{item.count}</TableCell>
                        <TableCell align="right" onClick={(e) => e.stopPropagation()}>
                          <div className="flex items-center justify-end">
                            <ConfirmDialog
                              title={t("complements_delete_title", "Delete stock item")}
                              description={t(
                                "stockItems_delete_subtitle",
                                "Are you sure you want to delete this item?"
                              )}
                              onContinue={() => deleteMutation.mutate(item.id)}
                              okButtonText={t("common_button_delete", "Delete")}
                            >
                              <Trash2Icon strokeWidth={1.2} />
                            </ConfirmDialog>
                          </div>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </InfiniteScroll>
            </CardContent>
          </Card>
        </div>
      ) : (
        <div className="my-5">
          <div className="mb-3">
            <NewInventoryFormModal product={product}>
              <Button>{t("complements_update_button", "Update stock")}</Button>
            </NewInventoryFormModal>
          </div>
          <div className="text-sm text-muted-foreground">{t("complements_empty_label", "No stock defined yet")}</div>
        </div>
      )}
    </>
  );
}

import { api } from "@/lib/api-client";
import { useAuth } from "@/lib/useAuth";
import { useQuery } from "@tanstack/react-query";

export function useRentalPeriods() {
  const auth = useAuth();

  const query = useQuery({
    queryKey: ["rental-period", auth.facility?.id],
    queryFn: async () =>
      auth.facility ? await api.getAllRentalPeriodsForFacility({ facilityId: auth.facility?.id }) : [],
    staleTime: 3600000,
  });

  return {
    periods: query.data || [],
    isLoading: query.isLoading,
  };
}

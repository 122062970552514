import { cn } from "@/lib/utils";

export function CoverImage(props: { src: string; className?: string }) {
  return (
    <div
      className={cn(props.className, "bg-cover bg-no-repeat bg-center rounded-md")}
      style={{ backgroundImage: `url('${props.src}')` }}
    ></div>
  );
}

import { api } from "@/lib/api-client";
import { useQuery } from "@tanstack/react-query";

export function useAgents(facilityId: string | null) {
  const query = useQuery({
    queryKey: ["agents", facilityId],
    queryFn: async () => {
      if (!facilityId) {
        return [];
      }

      return await api.getAllAgentsForFacility({ facilityId });
    },
  });

  return {
    agents: query.data || [],
    isLoading: query.isLoading,
  };
}

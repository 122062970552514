import { api } from "./api-client";
import React, { PropsWithChildren, useEffect } from "react";
import { jwtDecode } from "jwt-decode";
import { analytics } from "./analytics/analytics";
import { ApiObjects } from "@pulso/api-client";
import { useQueryClient } from "@tanstack/react-query";

type Context = {
  isEvaluated: boolean;
  isAuthenticated: boolean;
  isExpired: boolean;
  facility: ApiObjects["FacilityDto"] | null;
  facilities: ApiObjects["FacilityDto"][];
  facilityId: string | null;
  token: string | null;
  login: (email: string, password: string) => Promise<void>;
  loginWithToken: (token: string) => Promise<void>;
  logout: () => void;
  reloadAuth: () => void;
};

const authContext = React.createContext<Context>({
  isEvaluated: false,
  isAuthenticated: Boolean(localStorage.getItem("token")),
  isExpired: false,
  facility: null,
  facilities: [],
  facilityId: null,
  token: null,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  login: (email: string, password: string) => new Promise<void>((r) => r()),
  loginWithToken: (token: string) => new Promise<void>((r) => r()),
  logout: () => undefined as void,
  reloadAuth: () => undefined as void,
});

export function AuthProvider(props: PropsWithChildren) {
  const [isEvaluated, setIsEvaluated] = React.useState(false);
  const [isAuthenticated, setIsAuthenticated] = React.useState(false);
  const [facility, setFacility] = React.useState<Context["facility"]>(null);
  const [facilities, setFacilities] = React.useState<Context["facilities"]>([]);
  const [expiryTimer, setExpiryTimer] = React.useState<NodeJS.Timeout | null>(null);
  const [isExpired, setIsExpired] = React.useState(false);
  const queryClient = useQueryClient();
  const [cacheVer, setCacheVer] = React.useState(1);

  useEffect(() => {
    api
      .getAllFacilitiesForUser()
      .then((facilities) => {
        onLogin(facilities);
      })
      .catch(() => {
        onLogout();
      });
  }, [cacheVer]);

  useEffect(() => {
    const userId = getUserId();
    if (isAuthenticated && userId && facility) {
      analytics.identify(userId, facility.organization.name, facility.name);
    } else {
      analytics.reset();
    }
  }, [isAuthenticated]);

  async function login(email: string, password: string) {
    const { token } = await api.getToken({ email, password });
    loginWithToken(token);
  }

  async function loginWithToken(token: string) {
    localStorage.setItem("token", token);
    const facilities = await api.getAllFacilitiesForUser();
    onLogin(facilities);
  }

  function logout() {
    queryClient.invalidateQueries();
    queryClient.clear();
    onLogout();
  }

  function onLogin(facilities: ApiObjects["FacilityDto"][]) {
    setFacilities(facilities);
    setFacility(getFacilityFromUrl(facilities));
    setIsExpired(false);
    setIsAuthenticated(true);
    setIsEvaluated(true);
    if (expiryTimer) {
      clearTimeout(expiryTimer);
    }
    const tokenExpiryTime = getTokenExpiry();
    if (tokenExpiryTime) {
      const left = tokenExpiryTime - new Date().getTime();
      setExpiryTimer(setTimeout(() => setIsExpired(true), left));
    }
  }

  function getFacilityFromUrl(facilities: ApiObjects["FacilityDto"][]): ApiObjects["FacilityDto"] {
    const destinationQueryParam = new URLSearchParams(window.location.search).get("destination");
    const urlPart = destinationQueryParam || window.location.pathname;
    const facilityIdMatch = urlPart.match(/\/facility\/([a-zA-Z0-9]+)\//);
    const selectedFacilityId = facilityIdMatch ? facilityIdMatch[1] : null;

    let selectedFacility = selectedFacilityId ? facilities.find((f) => f.id === selectedFacilityId) : null;
    if (!selectedFacility) {
      if (selectedFacilityId) {
        // Selected facility is one that the user has no access to
        window.location.href = "/";
        throw new Error("No access to facility " + selectedFacilityId);
      } else {
        selectedFacility = facilities[0];
      }
    }

    return selectedFacility;
  }

  function onLogout() {
    setIsAuthenticated(false);
    setFacility(null);
    setIsExpired(false);
    setIsEvaluated(true);
    localStorage.removeItem("token");
    if (expiryTimer) {
      clearTimeout(expiryTimer);
    }
    setExpiryTimer(null);
  }

  const auth = {
    isEvaluated,
    isExpired,
    facility,
    facilities,
    facilityId: facility?.id || null,
    isAuthenticated,
    login,
    loginWithToken,
    logout,
    token: localStorage.getItem("token"),
    reloadAuth: () => setCacheVer((v) => v + 1),
  };

  return <authContext.Provider value={auth}>{props.children}</authContext.Provider>;
}

export function useAuth() {
  return React.useContext(authContext);
}

export function isAdmin() {
  const payload = getTokenPayload();
  return "role" in payload && payload.role === "ADMIN";
}

export function isPulsoAdmin() {
  const payload = getTokenPayload();
  return "isPulsoAdmin" in payload && payload.isPulsoAdmin === true;
}

export function getUserId() {
  const payload = getTokenPayload();
  return "sub" in payload ? (payload.sub as string) : null;
}

function getTokenExpiry() {
  const payload = getTokenPayload();
  return "exp" in payload ? (payload.exp as number) * 1000 : null;
}

function getTokenPayload() {
  const token = localStorage.getItem("token");

  if (!token) {
    return {};
  }

  return jwtDecode(token);
}

import { api } from "@/lib/api-client";
import { useQuery } from "@tanstack/react-query";

export function useBookingDocuments(bookingId: string | undefined) {
  const query = useQuery({
    queryKey: ["bookings", "booking", bookingId, "documents"],
    queryFn: async () => {
      if (!bookingId) {
        return null;
      }

      return await api.getBookingDocuments({ bookingId });
    },
  });

  return {
    documents: query.data || [],
    isLoading: query.isLoading,
    reload: query.refetch,
  };
}

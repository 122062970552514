import { api } from "@/lib/api-client";
import { useQuery } from "@tanstack/react-query";

export function useMe() {
  const query = useQuery({
    queryKey: ["me"],
    queryFn: async () => {
      return await api.getMe();
    },
  });

  return {
    user: query.data || null,
    isLoading: query.isLoading,
  };
}

import { z } from "zod";
import { DialogDescription, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Dialog, DialogTrigger, DialogContent } from "@/components/ui/dialog";
import { PropsWithChildren, useState } from "react";
import { Button } from "@/components/ui/button";
import { PulsoFormProvider, createForm } from "../../../components/specific/Form";
import { useAgentCreate } from "@/api/useAgentsCreate";
import { ApiObjects } from "@pulso/api-client";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { useTranslation } from "react-i18next";
import { PriceInput } from "@/components/ui/price-input";
import { useFieldArray, useFormContext } from "react-hook-form";
import { FormField } from "@/components/ui/form";
import { Trash2Icon } from "lucide-react";

type AgentFormModalType = PropsWithChildren<{
  id: string;
  action: "create" | "edit";
  agent: ApiObjects["AgentDto"] | null;
  products: ApiObjects["ProductDto"][];
  currency: string;
}>;

export function AgentFormModal({ id, action, agent, products, currency, children }: AgentFormModalType) {
  const [open, setOpen] = useState(false);
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent hideFooter>
        <AgentForm
          id={id}
          action={action}
          agent={agent}
          products={products}
          currency={currency}
          onClose={() => setOpen(false)}
        />
      </DialogContent>
    </Dialog>
  );
}

function AgentForm({
  id,
  action,
  agent,
  products,
  currency,
  onClose,
}: Omit<AgentFormModalType, "children"> & { onClose: () => void }) {
  const { t } = useTranslation();
  const formSchema = z.object({
    name: z.string().min(2).max(150),
    productFees: z.array(
      z.object({ productId: z.string(), feeType: z.enum(["FLATFEE", "PERCENTAGE"]), fee: z.number() })
    ),
  });

  const form = createForm(
    {
      name: {
        label: t("settings_agent_form_name_label", "Name"),
        type: "string",
      },
    },
    formSchema
  );

  const save = useAgentCreate(id, action);

  return (
    <>
      <DialogHeader>
        <DialogTitle>{t("settings_agent_form_title", "Agent")}</DialogTitle>
        <DialogDescription>
          {t("settings_agent_form_subtitle", "Define agents that are helping in your day-to-day operations.")}
        </DialogDescription>
      </DialogHeader>
      <PulsoFormProvider
        {...form}
        isLoading={save.isPending}
        onSubmit={(values) => save.mutate(values, { onSuccess: () => onClose() })}
        initialValues={{
          name: agent?.name ?? "",
          productFees: agent?.productFees || [],
        }}
      >
        <ProductFeesInput products={products} currency={currency} />
      </PulsoFormProvider>
    </>
  );
}

function ProductFeesInput({ products, currency }: { products: ApiObjects["ProductDto"][]; currency: string }) {
  const { t } = useTranslation();
  const form = useFormContext<{ name: string; productFees: ApiObjects["AgentProductFeeDto"][] }>();
  const { fields, append, remove } = useFieldArray({
    control: form.control, // control props comes from useForm (optional: if you are using FormProvider)
    name: "productFees",
  });
  const productsAvailableToAdd = products.filter(
    (p) => !form.getValues().productFees.some((pf) => pf.productId === p.id)
  );

  form.watch();

  return (
    <>
      {fields.map((arrayField, i) => (
        <div key={arrayField.productId + "_fee"} className="flex items-center space-y-3 space-x-3">
          <div className="text-sm flex-1">{products.find((p) => p.id === arrayField.productId)?.name}</div>
          <FormField
            name={`productFees.${i}.feeType`}
            control={form.control}
            render={({ field }) => (
              <Select value={field.value} onValueChange={field.onChange}>
                <SelectTrigger className="w-32">
                  <SelectValue />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="FLATFEE">{t("settings_agent_form_feeType_option_flatfee", "Flat fee")}</SelectItem>
                  <SelectItem value="PERCENTAGE">
                    {t("settings_agent_form_feeType_option_percentage", "Percentage")}
                  </SelectItem>
                </SelectContent>
              </Select>
            )}
          />
          <FormField
            name={`productFees.${i}.fee`}
            control={form.control}
            render={({ field }) => (
              <PriceInput
                className="w-28"
                sign={form.getValues().productFees[i].feeType === "FLATFEE" ? currency : "%"}
                {...field}
              />
            )}
          />
          <Button variant="ghost" size="sm" onClick={() => remove(i)}>
            <Trash2Icon size={16} strokeWidth={1.2} />
          </Button>
        </div>
      ))}
      {productsAvailableToAdd?.length ? (
        <div className="grid grid-cols-3 gap-3">
          <Select value="" onValueChange={(v) => append({ productId: v, feeType: "PERCENTAGE", fee: 5 })}>
            <SelectTrigger>{t("settings_agent_form_addFee", "Add product")}</SelectTrigger>
            <SelectContent>
              {productsAvailableToAdd.map((product) => (
                <SelectItem key={product.id} value={product.id}>
                  {product.name}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      ) : null}
    </>
  );
}

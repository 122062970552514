import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";

export function useBookingReturn(bookingId: string) {
  return useMutation({
    mutationFn: async () => {
      await api.returnBooking({ bookingId }, undefined);
    },
    invalidateKeys: [["bookings"]],
  });
}

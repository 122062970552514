import { useBookingPaymentLink } from "@/api/useBookingPaymentLinkUrl";
import { Button } from "@/components/ui/button";
import { CentralSpinner } from "@/components/ui/central-spinner";
import {
  Dialog,
  DialogHeader,
  DialogTrigger,
  DialogContent,
  DialogTitle,
  DialogDescription,
} from "@/components/ui/dialog";
import { Spinner } from "@/components/ui/spinner";
import { Code } from "@pulso/components/lib/Code";
import { errorLabelResolver, isConflictingStateError } from "@pulso/utils";
import { PropsWithChildren, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

type BookingPaymentLinkModalProps = {
  bookingId: string;
  facilityId: string;
};

export function BookingPaymentLinkModal({
  children,
  facilityId,
  bookingId,
}: PropsWithChildren<BookingPaymentLinkModalProps>) {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { url, isLoading, error } = useBookingPaymentLink(bookingId, open);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("bookings_details_payment_link_modal_title", "Payment link")}</DialogTitle>
          <DialogDescription>
            {t("bookings_details_payment_link_modal_description", "Share the payment link with your client")}
          </DialogDescription>
        </DialogHeader>
        {isLoading ? (
          <CentralSpinner />
        ) : (
          <p className="mt-1 mb-3 text-sm flex gap-1 items-start">
            <span>
              {t(
                "bookings_details_payment_link_info",
                "Payment links are shareable links that you can send to your customers to receive the payment for a booking. Remember that a payment link is valid for 30 days."
              )}
            </span>
          </p>
        )}

        {url && (
          <div>
            <Code text={url} />
          </div>
        )}
        {error && <div className="text-destructive">{errorLabelResolver(t).resolve(error)}</div>}
        {error && isConflictingStateError(error) && error.code === "PAYMENT_PROVIDER_STRIPE_NOT_CONNECTED" && (
          <>
            <p className="mb-3 text-sm">
              {t(
                "bookings_details_payment_link_setupStripe_info",
                "To be able to generate payment links you need to set up Stripe. Go to the Settings of the online store and enable it. Then enable the payment options and follow the Stripe Setup wizard."
              )}
            </p>
            <Link to={`/facility/${facilityId}/settings/store`}>
              <Button>{t("bookings_details_payment_link_setupStripe_button", "Setup Stripe now")}</Button>
            </Link>
          </>
        )}
      </DialogContent>
    </Dialog>
  );
}

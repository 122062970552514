"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateSeasonalPrice = calculateSeasonalPrice;
const getDayOfYear_1 = require("date-fns/getDayOfYear");
const isLeapYear_1 = require("date-fns/isLeapYear");
function calculateSeasonalPrice(seasons, bookingStartAt, bookingPeriod) {
    var _a;
    const defaultPrice = (_a = seasons.find((p) => p.season.id === "default")) === null || _a === void 0 ? void 0 : _a.price;
    const seasonsWithoutDefault = seasons.filter((p) => p.season.id !== "default");
    if (typeof defaultPrice !== "number") {
        return null;
    }
    let price = 0;
    const isBookingInLeapYear = (0, isLeapYear_1.isLeapYear)(bookingStartAt);
    const bookingFromDay = (0, getDayOfYear_1.getDayOfYear)(bookingStartAt);
    const daysCount = Math.ceil(bookingPeriod / 1440);
    const bookingToDay = bookingFromDay + daysCount - 1;
    for (let day = bookingFromDay; day <= bookingToDay; day++) {
        const season = seasonsWithoutDefault.find((season) => {
            const seasonFrom = adaptForLeapYear(season.season.from, isBookingInLeapYear);
            const seasonTo = adaptForLeapYear(season.season.to, isBookingInLeapYear);
            if (seasonFrom <= seasonTo) {
                return seasonFrom <= day && day <= seasonTo; // summer high-season case
            }
            else {
                return day >= seasonFrom || day <= seasonTo; // winter high-season case
            }
        });
        price += (season ? season.price : defaultPrice) / daysCount;
    }
    return Math.round(price * 100) / 100;
}
function adaptForLeapYear(day, isLeapYear) {
    if (!isLeapYear && day >= 60) {
        return day - 1;
    }
    return day;
}

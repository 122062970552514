import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";

export function useBookingItemUpdate(bookingId: string, variantId: string, periodId: string) {
  return useMutation({
    mutationFn: async (newPeriodId: string) => {
      await api.updateBookingItemsForVariant({ bookingId, variantId, periodId }, { periodId: newPeriodId });
    },
    invalidateKeys: [["bookings"]],
    successMessage: "SAVE_SUCCESS",
  });
}

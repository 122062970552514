import { api } from "@/lib/api-client";
import { useQuery } from "@tanstack/react-query";

export function useDashboardViews(facilityId: string) {
  const query = useQuery({
    queryKey: ["dashboard-views", facilityId],
    queryFn: async () => await api.getAllDashboardViews({ facilityId }),
  });

  return {
    dashboardViews: query.data || [],
    isLoading: query.isLoading,
  };
}

import { DefaultError, MutationFunction, UseMutationResult } from "@tanstack/react-query";
import { useAuth } from "./useAuth";
import { useMutation } from "./useMutation";

export function useMutationWithAuth<TData = unknown, TError = DefaultError, TVariables = void, TContext = unknown>(
  options: Omit<Parameters<typeof useMutation<TData, TError, TVariables, TContext>>[0], "mutationFn"> & {
    mutationFn: (facilityId: string, variables: TVariables) => Promise<TData>;
  }
): UseMutationResult<TData, TError, TVariables, TContext> {
  const auth = useAuth();

  const newMutationFn: MutationFunction<TData, TVariables> = (vars) =>
    options.mutationFn(auth.facility?.id || "", vars);

  const opts = {
    ...options,
    mutationFn: newMutationFn,
  };

  return useMutation(opts);
}

import { api } from "@/lib/api-client";
import { useQuery } from "@tanstack/react-query";

export function useBookingPaymentLink(bookingId: string, enabled: boolean) {
  const query = useQuery({
    queryKey: ["bookings", "booking", bookingId, "payment-link"],
    queryFn: () => api.getPaymentLinkUrl({ bookingId }).catch((e) => Promise.reject(e.body)),
    enabled,
    retry: false,
  });

  return {
    url: query.data?.url || null,
    isLoading: query.isLoading,
    error: query.error,
  };
}

import { useExtraCharges } from "@/api/useExtraCharges";
import { useExtraChargeDelete } from "@/api/useExtraChargesDelete";
import { Price } from "@pulso/components/lib/Price";
import {
  AlertDialog,
  AlertDialogTrigger,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Card, CardContent } from "@/components/ui/card";
import { Table, TableHeader, TableRow, TableHead, TableBody, TableCell } from "@/components/ui/table";
import { t } from "i18next";
import { EditIcon, Trash2Icon } from "lucide-react";
import { ExtraChargeFormModal } from "./ExtraChargesFormModal";
import { withFacility } from "@/lib/withParams";

export const ExtraChargesPage = withFacility(({ facility }) => {
  const { extraCharges } = useExtraCharges(facility.id);
  const deleteExtraCharge = useExtraChargeDelete();

  return (
    <Card>
      <CardContent className="pt-6 space-y-6">
        <ExtraChargeFormModal id={facility.id} action="create" extraCharge={null} currency={facility.currency}>
          <Button variant="outline">{t("settings_extraCharges_addButton", "Add extra charge")}</Button>
        </ExtraChargeFormModal>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>{t("settings_extraCharges_tableHeader_concept", "Concept")}</TableHead>
              <TableHead>{t("settings_extraCharges_tableHeader_defaultAmount", "Default amount")}</TableHead>
              <TableHead>{t("settings_extraCharges_tableHeader_type", "Type")}</TableHead>
              <TableHead>{t("settings_extraCharges_tableHeader_vat", "VAT")}</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {extraCharges.map((extraCharge) => (
              <TableRow key={extraCharge.id}>
                <TableCell>{extraCharge.concept}</TableCell>
                <TableCell>
                  <Price price={extraCharge.defaultAmount} currency={facility.currency} />
                </TableCell>
                <TableCell>
                  {extraCharge.defaultAmount > 0
                    ? t("settings_extraCharges_form_type_option_extraCharge", "Extra charge")
                    : t("settings_extraCharges_form_type_option_discount", "Discount")}
                </TableCell>
                <TableCell>{extraCharge.vat && extraCharge.defaultAmount > 0 ? extraCharge.vat + "%" : null}</TableCell>
                <TableCell align="right">
                  <ExtraChargeFormModal
                    id={extraCharge.id}
                    action="edit"
                    extraCharge={extraCharge}
                    currency={facility.currency}
                  >
                    <Button variant="ghost">
                      <EditIcon strokeWidth={1.2} />
                    </Button>
                  </ExtraChargeFormModal>

                  <AlertDialog>
                    <AlertDialogTrigger>
                      <Trash2Icon strokeWidth={1.2} />
                    </AlertDialogTrigger>
                    <AlertDialogContent>
                      <AlertDialogHeader>
                        <AlertDialogTitle>
                          {t("settings_extraCharges_delete_title", "Delete {{extraCharge}}", {
                            extraCharge: extraCharge.concept,
                          })}
                        </AlertDialogTitle>
                        <AlertDialogDescription>
                          {t(
                            "settings_extraCharges_delete_subtitle",
                            "Are you sure you want to delete this extra charge?"
                          )}
                        </AlertDialogDescription>
                      </AlertDialogHeader>
                      <AlertDialogFooter>
                        <AlertDialogCancel>{t("common_button_cancel")}</AlertDialogCancel>
                        <AlertDialogAction onClick={() => deleteExtraCharge.mutate(extraCharge.id)}>
                          {t("common_button_delete")}
                        </AlertDialogAction>
                      </AlertDialogFooter>
                    </AlertDialogContent>
                  </AlertDialog>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
});

import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";

export function useBookingDelete() {
  return useMutation({
    mutationFn: async (bookingId: string) => {
      await api.deleteBooking({ bookingId });
    },
    invalidateKeys: [["bookings"]],
  });
}

import { api } from "@/lib/api-client";
import { useAuth } from "@/lib/useAuth";
import { useQueryWithPagination } from "@/lib/useQueryWithPagination";
import { keepPreviousData } from "@tanstack/react-query";

export function useCustomers(q: string, pageSize = 6, minSearchLength = 3) {
  const auth = useAuth();

  const query = useQueryWithPagination({
    queryKey: ["customers", auth.facility?.id, q || ""],
    queryFn: async (pagination) => {
      if (!auth.facility?.id) {
        return { customers: [], total: 0 };
      }

      if (q.length < minSearchLength) {
        return { customers: [], total: 0 };
      }

      return await api.getCustomers({
        facilityId: auth.facility.id,
        q,
        pageSize: pagination.pageSize,
        page: pagination.page,
      });
    },
    placeholderData: keepPreviousData,
    pageSize,
  });

  return {
    customers: query.data?.customers || [],
    total: query.data?.total || 0,
    pagination: { ...query.pagination, total: query.data?.total || 0 },
    isLoading: query.isFetching,
  };
}

import { api } from "@/lib/api-client";
import { ApiObjects } from "@pulso/api-client";
import { useMutation } from "@/lib/useMutation";

export function useUserCreate(facilityId: string) {
  return useMutation({
    mutationFn: async (data: ApiObjects["CreateUserBody"]) => await api.createUser({ facilityId }, data),
    invalidateKeys: [["users"]],
    successMessage: "SAVE_SUCCESS",
  });
}

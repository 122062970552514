import { Checkbox } from "@/components/ui/checkbox";
import { useTranslation } from "react-i18next";
import { useGridState } from "./useGridState";
import keyBy from "lodash/keyBy";
import { useColumnDefinitions } from "./useColumnDefinitions";

type BookGridColumnsPopoverProps = {
  facilityId: string;
};

export function BookGridColumnsPopover(props: BookGridColumnsPopoverProps) {
  const { t } = useTranslation();
  const colDefs = useColumnDefinitions(props.facilityId);
  const { currentGridState, toggleVisibility } = useGridState(props.facilityId);
  const colsWithVisibility = colDefs.map((col) => {
    const position = currentGridState.columns.findIndex((c) => c.colId === col.field);
    return {
      field: col.field,
      headerName: col.headerName,
      hide: currentGridState.columns.find((c) => c.colId === col.field)?.hide ?? col.hide,
      position: position === -1 ? 999 : position,
    };
  });
  colsWithVisibility.sort((a, b) => a.position - b.position);
  const columnsVisibilityMap = keyBy(colsWithVisibility, "field");

  return (
    <div className="flex flex-col gap-1">
      {colsWithVisibility.map((col) => (
        <div key={col.field} className="flex items-center gap-2 py-0.5">
          <Checkbox
            id={"booking-grid-col-" + col.field}
            checked={col.field ? columnsVisibilityMap[col.field] && !columnsVisibilityMap[col.field]?.hide : false}
            onCheckedChange={(isChecked) => {
              if (col.field) {
                toggleVisibility(col.field, isChecked === true);
              }
            }}
          />
          <label htmlFor={"booking-grid-col-" + col.field} className="text-sm">
            {col.headerName}
          </label>
        </div>
      ))}
    </div>
  );
}

import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";
import { useTranslation } from "react-i18next";

export function useSendPasswordResetLink() {
  const { t } = useTranslation();
  return useMutation({
    mutationFn: async (userId: string) => await api.sendPasswordResetLink({ userId }, undefined),
    invalidateKeys: [],
    successMessage: t("settings_users_resetPassword_success", "Password reset link successfully sent"),
  });
}

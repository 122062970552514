import { useSeasons } from "@/api/useSeasons";
import { Button } from "@/components/ui/button";
import { CentralSpinner } from "@/components/ui/central-spinner";
import { useTranslation } from "react-i18next";
import { useSeasonCreate } from "@/api/useSeasonCreate";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { ConfirmDialog } from "@/components/ui/confirm-dialog";
import { EditIcon, Trash2Icon } from "lucide-react";
import { useSeasonDelete } from "@/api/useSeasonDelete";
import { useSeasonUpdate } from "@/api/useSeasonUpdate";
import { formatDate } from "@pulso/utils";
import { setDayOfYear } from "date-fns/setDayOfYear";
import { SeasonFormDialog } from "./SeasonFormDialog";

export function Seasons({ facilityId }: { facilityId: string }) {
  const { t } = useTranslation();
  const { seasons, isLoading } = useSeasons(facilityId);
  const createMutation = useSeasonCreate(facilityId);
  const updateMutation = useSeasonUpdate();
  const deleteMutation = useSeasonDelete();

  if (isLoading) {
    return <CentralSpinner />;
  }

  return (
    <div>
      <div>
        <SeasonFormDialog
          title={t("settings_seasons_createModal_title", "Add a new season")}
          description={t(
            "settings_seasons_createModal_description",
            "Add a new season which can be used to modify the prices of a product for a specific period of the year"
          )}
          seasons={seasons}
          initialData={{ name: "", from: new Date(), to: new Date() }}
          isLoading={createMutation.isPending}
          onSubmit={(values) => createMutation.mutateAsync(values)}
        >
          <Button variant="outline">{t("settings_seasons_create_button", "Add season")}</Button>
        </SeasonFormDialog>
      </div>
      {seasons.length > 0 && (
        <Table className="mt-3">
          <TableHeader>
            <TableRow>
              <TableHead>{t("settings_rentalPeriods_tableHeader_name", "Name")}</TableHead>
              <TableHead>{t("settings_rentalPeriods_tableHeader_period", "Period")}</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {seasons.map((season) => (
              <TableRow key={season.id}>
                <TableCell>{season.name}</TableCell>
                <TableCell>
                  {formatDate(dateFromDay(season.from), null, undefined, "date-short")} -{" "}
                  {formatDate(dateFromDay(season.to), null, undefined, "date-short")}
                </TableCell>
                <TableCell align="right">
                  <div className="flex items-center justify-end">
                    <SeasonFormDialog
                      title={t("settings_seasons_createModal_title", "Add a new season")}
                      description={t(
                        "settings_seasons_createModal_description",
                        "Add a new season which can be used to modify the prices of a product for a specific period of the year"
                      )}
                      seasons={seasons.filter((s) => s.id !== season.id)}
                      initialData={{
                        name: season.name,
                        from: dateFromDay(season.from),
                        to: dateFromDay(season.to),
                      }}
                      isLoading={updateMutation.isPending}
                      onSubmit={(values) => updateMutation.mutateAsync({ id: season.id, ...values })}
                    >
                      <Button variant="ghost">
                        <EditIcon strokeWidth={1.2} />
                      </Button>
                    </SeasonFormDialog>
                    <ConfirmDialog
                      title={t("settings_seasons_deleteModal_title", "Delete season")}
                      description={t(
                        "settings_seasons_deleteModal_description",
                        "Are you sure you want to delete this season?"
                      )}
                      onContinue={() => deleteMutation.mutate(season.id)}
                    >
                      <Trash2Icon strokeWidth={1.2} />
                    </ConfirmDialog>
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
}

function dateFromDay(day: number): Date {
  return setDayOfYear(new Date("2024-01-01"), day); // Needs to be a leap year
}

import { api } from "@/lib/api-client";
import { useAuth } from "@/lib/useAuth";
import { useQuery } from "@tanstack/react-query";

export function useDocuments() {
  const auth = useAuth();
  const facilityId = auth.facility?.id;

  const query = useQuery({
    queryKey: ["documents", facilityId],
    queryFn: async () => (facilityId ? await api.getAllDocumentsForFacility({ facilityId }) : []),
  });

  return {
    documents: query.data || [],
    isLoading: query.isLoading,
  };
}

import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";

export function useProductAddLocation(productId: string) {
  return useMutation({
    mutationFn: async (locationId: string) =>
      productId ? await api.addLocationForProduct({ productId, locationId }, undefined) : null,
    invalidateKeys: [["products"]],
    successMessage: "SAVE_SUCCESS",
  });
}

import { useCustomerUpdate } from "@/api/useCustomerUpdate";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { ApiObjects } from "@pulso/api-client";
import { t } from "i18next";
import { PropsWithChildren, memo, useState } from "react";
import { useCustomerForm } from "./useCustomerForm";
import { transformFieldValuesToHash } from "@/lib/utils";
import { PulsoFormProvider } from "@/components/specific/Form";

type EditCustomerModalProps = PropsWithChildren<{
  customer: ApiObjects["CustomerDto"];
}>;

export const EditCustomerModal = memo(EditCustomerModalRaw, (prevProps, newProps) => {
  return (
    prevProps.customer.id === newProps.customer.id &&
    prevProps.customer.firstname === newProps.customer.firstname &&
    prevProps.customer.lastname === newProps.customer.lastname &&
    prevProps.customer.email === newProps.customer.email &&
    prevProps.customer.language === newProps.customer.language &&
    prevProps.customer.fields.length === newProps.customer.fields.length &&
    !prevProps.customer.fields.some((prevField) =>
      newProps.customer.fields.some(
        (newField) =>
          prevField.id === newField.id &&
          (prevField.value !== newField.value || (prevField.file?.id ?? "" !== newField.file?.id ?? ""))
      )
    )
  );
});

export function EditCustomerModalRaw({ customer, children }: EditCustomerModalProps) {
  const [open, setOpen] = useState(false);
  const form = useCustomerForm("full");
  const update = useCustomerUpdate(customer.id);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent hideFooter>
        <DialogHeader>
          <DialogTitle>{t("customer_edit_form_title", "Update {{name}}", { name: customer.name })}</DialogTitle>
          <DialogDescription>
            {t("customer_edit_form_subtitle", "Update customer's file with the latest data")}
          </DialogDescription>
        </DialogHeader>
        <PulsoFormProvider
          {...form}
          isLoading={update.isPending}
          className="flex flex-col flex-1 overflow-y-hidden"
          fieldsClassName="flex-1 overflow-y-auto"
          onSubmit={(values) =>
            update.mutate({ ...values, email: values.email || null }, { onSuccess: () => setOpen(false) })
          }
          initialValues={{ ...customer, ...transformFieldValuesToHash(customer.fields) }}
          horizontal
        />
      </DialogContent>
    </Dialog>
  );
}

import React, { useEffect, useState } from "react";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./select";
import { Input } from "./input";
import { useTranslation } from "react-i18next";

type PeriodInputProps = { value: number; onChange: (period: number) => void; disabled?: boolean };
const PeriodInput = React.forwardRef<HTMLDivElement, PeriodInputProps>((props, ref) => {
  const { t } = useTranslation();

  const multiplierOptions = {
    minutes: {
      value: 1,
      type: "minutes",
      label: t("common_minutes", "minutes"),
    },
    hours: {
      value: 60,
      type: "hours",
      label: t("common_hours", "hours"),
    },
    days: {
      value: 24 * 60,
      type: "days",
      label: t("common_days", "days"),
    },
    weeks: {
      value: 7 * 24 * 60,
      type: "weeks",
      label: t("common_weeks", "weeks"),
    },
    months: {
      value: 30 * 24 * 60,
      type: "months",
      label: t("common_months", "months"),
    },
  } as const;

  const [initCount, initMultiplier] = getInitialValue(props.value);
  const [count, setCount] = useState(initCount);
  const [multiplier, setMultiplier] = useState<keyof typeof multiplierOptions>(initMultiplier);

  useEffect(() => {
    const oldValue = count * multiplierOptions[multiplier].value;
    if (props.value !== oldValue) {
      const [newCount, newMultiplier] = getInitialValue(props.value);
      setCount(newCount);
      setMultiplier(newMultiplier);
    }
  }, [props.value]);

  return (
    <div className="flex space-x-3" ref={ref}>
      <Input
        type="number"
        value={count}
        onChange={(e) => {
          const newCount = parseInt(e.target.value);
          if (!Number.isNaN(newCount)) {
            setCount(newCount);
            emitValue(newCount, multiplierOptions[multiplier].value);
          } else {
            setCount(0);
          }
        }}
        disabled={props.disabled}
      />
      <Select
        value={multiplier + ""}
        disabled={props.disabled}
        onValueChange={(v) => {
          const newMultiplier = multiplierOptions[v as keyof typeof multiplierOptions];
          setMultiplier(newMultiplier.type);
          emitValue(count, newMultiplier.value);
        }}
      >
        <SelectTrigger>
          <SelectValue>{multiplier}</SelectValue>
        </SelectTrigger>
        <SelectContent>
          {Object.values(multiplierOptions).map((m) => (
            <SelectItem key={m.type} value={m.type}>
              {m.label}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );

  function emitValue(count: number, multipler: number) {
    props.onChange(count * multipler);
  }

  function getInitialValue(minutes: number): [number, keyof typeof multiplierOptions] {
    if (!minutes) {
      return [0, "hours"];
    } else if (minutes >= 1440) {
      return [Math.floor(minutes / 1440), "days"];
    } else if (minutes >= 60) {
      return [Math.floor(minutes / 60), "hours"];
    } else {
      return [minutes, "minutes"];
    }
  }
});

PeriodInput.displayName = "PeriodInput";

export { PeriodInput };

import { api } from "@/lib/api-client";
import { ApiObjects } from "@pulso/api-client";
import { useMutation } from "@/lib/useMutation";

export function useDocumentCreate(facilityId: string) {
  return useMutation({
    mutationFn: async (data: ApiObjects["CreateDocumentBody"]) => {
      return await api.createDocument({ facilityId }, data);
    },
    invalidateKeys: [["documents"]],
    successMessage: "SAVE_SUCCESS",
  });
}

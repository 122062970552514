import { ApiObjects } from "@pulso/api-client";
import { type ClassValue, clsx } from "clsx";
import { twMerge } from "tailwind-merge";
import { z } from "zod";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function transformFieldValuesToHash<T>(
  values: { id: string; value?: T; file?: ApiObjects["FileDto"] | null }[]
): Record<string, T> {
  return values.reduce((obj, v) => ({ ...obj, ["fields_" + v.id]: v.file || v.value }), {});
}

export function transformHashToFieldValues<T>(values: Record<string, T>): { id: string; value: T }[] {
  const arr: { id: string; value: T }[] = [];
  const fieldEntries = Object.entries(values).filter(([id]) => id.startsWith("fields_"));
  for (const [id, value] of fieldEntries) {
    if (typeof value === "string" || typeof value === "object") {
      arr.push({ id: id.replace("fields_", ""), value });
    }
  }
  return arr;
}

export function fieldZodTransformer<Q extends Record<string, string>, T extends { fields?: Q }>(
  data: T
): Omit<T, "fields"> & { fields: { fieldId: string; value: string }[] } {
  const { fields, ...rest } = data;
  let arrFields = [] as { fieldId: string; value: string }[];
  if (fields) {
    arrFields = Object.keys(fields || {})
      .filter((key) => typeof fields[key] === "string" || typeof fields[key] === "object")
      .reduce((arr, key) => [...arr, { fieldId: key, value: fields[key] }], [] as { fieldId: string; value: string }[]);
  }

  return {
    ...rest,
    fields: arrFields,
  };
}

export function fieldToHashZodTransformer<
  Q extends { fieldId: string; optionId: string | null; value: string | null }[],
  T extends { fields: Q },
>(data: T): Omit<T, "fields"> & { fields: Record<string, string> } {
  const { fields, ...rest } = data;
  const hashFields = fields.reduce((obj, field) => ({ ...obj, [field.fieldId]: field.optionId || field.value }), {});
  return {
    ...rest,
    fields: hashFields,
  };
}

export function zodFieldsType(fields: ApiObjects["ProductWithExtraDto"]["fields"]) {
  if (!fields.length) {
    return z.any();
  }

  const props = fields.reduce(
    (obj, field) => ({ ...obj, [field.id]: field.priceable ? z.string() : z.string().optional() }),
    {}
  );

  return z.object(props);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function groupBy<T extends Record<string, any>>(arr: T[], field: (item: T) => string): Record<string, T[]> {
  return arr.reduce(
    (obj, item) => {
      const key = field(item);

      if (obj[key]) {
        return { ...obj, [key]: [...obj[key], item] };
      }

      return { ...obj, [key]: [item] };
    },
    {} as Record<string, T[]>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function groupByArray<T extends Record<string, any>>(
  arr: T[],
  field: (item: T) => string
): { key: string; items: T[] }[] {
  const groups = groupBy(arr, field);
  const ret: { key: string; items: T[] }[] = [];

  for (const [key, items] of Object.entries(groups)) {
    ret.push({ key, items });
  }
  return ret;
}

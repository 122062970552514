import { analytics } from "@/lib/analytics/analytics";
import { api } from "@/lib/api-client";
import { useMutation } from "@/lib/useMutation";

export function useBookingDeliver(bookingId: string, itemsCount: number) {
  return useMutation({
    mutationFn: async () => {
      await api.deliverBooking({ bookingId }, undefined);
    },
    invalidateKeys: [["bookings"]],
    successMessage: "SAVE_SUCCESS",
    onSuccess: () => {
      analytics.trackEvent("booking_delivery", { bookingId, itemsCount });
    },
  });
}

import { Button } from "@/components/ui/button";
import { zonedNow } from "@pulso/utils";
import { addMonths, addWeeks, endOfDay, endOfMonth, endOfWeek, startOfDay, startOfMonth, startOfWeek } from "date-fns";
import { useTranslation } from "react-i18next";

export function DatePreset(props: { timezone: string; onSelect: (range: { from: Date; to: Date }) => void }) {
  const { t } = useTranslation();

  const today = zonedNow(props.timezone);
  const startOfDayDt = startOfDay(today);
  const endOfDayDt = endOfDay(today);
  const startOfWeekDt = startOfWeek(today, { weekStartsOn: 1 });
  const endOfWeekDt = endOfWeek(today, { weekStartsOn: 1 });
  const startOfMonthDt = startOfMonth(today);
  const endOfMonthDt = endOfMonth(today);

  const presets: Array<{ label: string; range: { from: Date; to: Date } }> = [
    {
      label: t("common_today", "Today"),
      range: { from: startOfDayDt, to: endOfDayDt },
    },
    {
      label: t("calendar_filter_date_thisWeek", "This week"),
      range: { from: startOfWeekDt, to: endOfWeekDt },
    },
    {
      label: t("calendar_filter_date_nextWeek", "Next week"),
      range: { from: addWeeks(startOfWeekDt, 1), to: addWeeks(endOfWeekDt, 1) },
    },
    {
      label: t("calendar_filter_date_lastWeek", "Last week"),
      range: { from: addWeeks(startOfWeekDt, -1), to: addWeeks(endOfWeekDt, -1) },
    },

    {
      label: t("calendar_filter_date_thisMonth", "This month"),
      range: { from: startOfMonthDt, to: endOfMonthDt },
    },
    {
      label: t("calendar_filter_date_nextMonth", "Next month"),
      range: { from: addMonths(startOfMonthDt, 1), to: addMonths(endOfMonthDt, 1) },
    },
    {
      label: t("calendar_filter_date_lastMonth", "Last month"),
      range: { from: addMonths(startOfMonthDt, -1), to: addMonths(endOfMonthDt, -1) },
    },
  ];

  return (
    <div className="flex flex-col space-y-1">
      {presets.map((preset) => (
        <Button key={preset.label} variant="outline" size="sm" onClick={() => props.onSelect(preset.range)}>
          {preset.label}
        </Button>
      ))}
    </div>
  );
}

import { Button } from "@/components/ui/button";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { Link, useParams } from "react-router-dom";
import { Spinner } from "@/components/ui/spinner";
import { EditIcon, Trash2Icon } from "lucide-react";
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { useProducts } from "@/api/useProducts";
import { Badge } from "@/components/ui/badge";
import { useDocumentDelete } from "@/api/useDocumentDelete";
import { useDocuments } from "@/api/useDocuments";
import { t } from "i18next";
import { Card, CardContent } from "@/components/ui/card";

export function DocumentsSettingsPage() {
  const { facilityId } = useParams();
  const products = useProducts();
  const { documents, isLoading } = useDocuments();
  const deleteMutation = useDocumentDelete();

  if (!facilityId || products.isLoading || isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <Card>
        <CardContent className="pt-6 space-y-6">
          <Link to={`./create/${facilityId}`}>
            <Button variant="outline">{t("documents_button_add", "Add document")}</Button>
          </Link>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>{t("documents_table_header_name", "Name")}</TableHead>
                <TableHead>{t("documents_table_header_products", "Products assigned")}</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {documents.map((document) => (
                <TableRow key={document.id}>
                  <TableCell>{document.name}</TableCell>
                  <TableCell>
                    {products.data
                      ? products.data
                          .filter((p) => document.products.includes(p.id))
                          .map((p) => (
                            <Badge key={document.id + "_" + p.id} variant="secondary" className="ml-3">
                              {p.name}
                            </Badge>
                          ))
                      : "-"}
                  </TableCell>
                  <TableCell align="right">
                    <Link to={"./" + document.id}>
                      <Button variant="ghost">
                        <EditIcon strokeWidth={1.2} />
                      </Button>
                    </Link>

                    <AlertDialog>
                      <AlertDialogTrigger>
                        <Trash2Icon strokeWidth={1.2} />
                      </AlertDialogTrigger>
                      <AlertDialogContent>
                        <AlertDialogHeader>
                          <AlertDialogTitle>
                            {t("documents_delete_title", "Delete {{document}}", { document: document.name })}
                          </AlertDialogTitle>
                          <AlertDialogDescription>
                            {t("documents_delete_subtitle", "Are you sure you want to delete this field?")}
                          </AlertDialogDescription>
                        </AlertDialogHeader>
                        <AlertDialogFooter>
                          <AlertDialogCancel>{t("common_button_cancel", "Cancel")}</AlertDialogCancel>
                          <AlertDialogAction onClick={() => deleteMutation.mutate(document.id)}>
                            {t("common_button_delete", "Delete")}
                          </AlertDialogAction>
                        </AlertDialogFooter>
                      </AlertDialogContent>
                    </AlertDialog>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </div>
  );
}

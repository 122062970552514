import { BaseAnalytics } from "./BaseAnalytics";
import { GoogleAnalytics } from "./GoogleAnalytics";
import { MixpanelAnalytics } from "./MixpanelAnalytics";

const providers: BaseAnalytics[] = [new GoogleAnalytics(), new MixpanelAnalytics()];

function identify(userId: string, organization: string, facility: string): void {
  providers.forEach((p) => p.identify(userId, organization, facility));
}

function reset(): void {
  providers.forEach((p) => p.reset());
}

function trackEvent(event: string, params: object): void {
  providers.forEach((p) => p.trackEvent(event, params));
}

export const analytics = {
  identify,
  trackEvent,
  reset,
};

import { z } from "zod";
import { createForm } from "../../components/specific/Form";
import { t } from "i18next";

export function createProductForm() {
  const formSchema = z.object({
    name: z.string().min(2).max(50),
    description: z.string().optional(),
    productType: z.enum(["unitary", "countable", "package"]),
  });

  return createForm(
    {
      name: {
        label: t("products_create_form_label_name", "Name"),
        type: "string",
      },
      productType: {
        label: t("products_create_form_label_identifiable", "Identification"),
        type: "radio",
        options: [
          {
            value: "unitary",
            label: t(
              "products_create_form_option_unitary",
              "Track each stock item individually with a uniquely assigned code"
            ),
          },
          {
            value: "countable",
            label: t(
              "products_create_form_option_complement",
              "Similar products that don't need to be uniquely tracked (complements, insurance, etc.)"
            ),
          },
          {
            value: "package",
            label: t("products_create_form_option_package", "A package that includes one or more products"),
          },
        ],
      },
      description: {
        label: t("products_create_form_label_description", "Description"),
        type: "text",
      },
    },
    formSchema
  );
}

import { api } from "@/lib/api-client";
import { keepPreviousData, useQuery } from "@tanstack/react-query";

export function useUsers(facilityId: string) {
  const query = useQuery({
    queryKey: ["users", facilityId],
    queryFn: async () => await api.getUsers({ facilityId }),
    placeholderData: keepPreviousData,
  });

  return {
    users: query.data || [],
    isLoading: query.isLoading,
  };
}

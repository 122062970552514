import { BaseAnalytics } from "./BaseAnalytics";
import mixpanel from "mixpanel-browser";

export class MixpanelAnalytics extends BaseAnalytics {
  private key =
    typeof process.env.REACT_APP_MIXPANEL_KEY === "string" && process.env.REACT_APP_MIXPANEL_KEY
      ? process.env.REACT_APP_MIXPANEL_KEY
      : null;

  constructor() {
    super();

    if (this.key) {
      mixpanel.init(this.key);
    }
  }

  identify(userId: string, organization: string, facility: string): void {
    if (this.key) {
      mixpanel.identify(userId);
      mixpanel.people.set({ organization, facility });
    }
  }
  reset(): void {
    if (this.key) {
      mixpanel.reset();
    }
  }

  trackEvent(event: string, params: object): void {
    if (this.key) {
      mixpanel.track(event, params);
    }
  }
}

import { api } from "@/lib/api-client";
import { ApiObjects } from "@pulso/api-client";
import { useMutation } from "@/lib/useMutation";

type MutationFnData = {
  name: string;
  type?: ApiObjects["CreateProductFieldBody"]["type"];
  options?: { id: string; value: string }[];
  priceable: boolean;
  showInDocuments: boolean;
};

export function useProductFieldSave(id: string, action: "create" | "edit") {
  return useMutation({
    mutationFn: async (fieldData: MutationFnData) => {
      if (action === "create") {
        if (isTypePresent(fieldData)) {
          await api.createProductField({ productId: id }, fieldData);
        }
      } else {
        await api.updateProductField({ fieldId: id }, fieldData);
      }
    },
    invalidateKeys: [["products"]],
  });
}

function isTypePresent(
  data: MutationFnData
): data is Omit<MutationFnData, "type"> & Required<Pick<MutationFnData, "type">> {
  return !!data.type;
}
